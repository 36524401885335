import { Box, Container, Typography, Divider } from "@mui/material";
import { useState } from "react";
import { env } from "env";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";
import { Logo } from "components/HBHeader/Nav/Logo";
import HBHouseIcon from "../../assets/Icons/HBHouseLender.svg";
import { LinkList } from "components/HBHeader/Nav/LinkList";
import { FooterLinkList } from "./LinkList";

export const HBFooter = () => {
  const [enviromentSelected, setEnviromentSelected] = useState(
    localStorage.getItem("currentEviroment") ?? "DEV",
  );

  const { primaryBackground, primaryText, tertiaryText } =
    useHomeBridgeColors();

  const currentYear = new Date().getFullYear();
  return (
    <Box
      className="hb-footer"
      sx={{
        backgroundColor: primaryBackground,
        paddingTop: "20px",
        paddingBottom: "15px",
        minHeight: "70vh",
      }}
    >
      <Container
        className="hb-footer__container"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "4em",
          width: "100%",
          height: "100%",
          position: "relative",
          padding: "60px 0",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <Box
            className="hb-footer_logo"
            display="flex"
            justifyContent="center"
            sx={{
              padding: "10px 0",
            }}
          >
            <Logo isFooter={true} />
          </Box>
          <LinkList isFooter className="hb-footer__footer" />
        </Box>
        <Divider
          sx={{
            width: "92%",
            height: "1px",
            backgroundColor: tertiaryText,
            display: { xs: "none", md: "block" },
          }}
        />
        <Divider
          sx={{
            width: "85%",
            height: "1px",
            backgroundColor: tertiaryText,
            display: { xs: "block", md: "none" },
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "40px",
            textAlign: "center",
            width: "90%",
          }}
        >
          <FooterLinkList />
          <Typography color={primaryText} width={"90%"}>
            Eligibility is subject to completion of an application and
            verification of homeownership, occupancy, title, income, employment,
            credit, home value, collateral, and underwriting requirements. Loan
            program terms and conditions will depend on underwriting and
            consumer credit characteristics. This is not a commitment to lend or
            offer of credit.
          </Typography>
          <Typography color={primaryText}>
            © {currentYear} Homebridge, a division of Homebridge Financial
            Services, Inc. | NMLS ID #6521 | nmlsconsumeraccess.org
          </Typography>
          <img src={HBHouseIcon} alt="HBHouseIcon" />
        </Box>
        {process.env.REACT_APP_ENV === "prod" ? null : (
          <Box position="absolute" bottom={0} right={0}>
            <Box display="flex" justifyContent="end">
              <Typography color={primaryText}>
                Current version: {process.env.REACT_APP_CURRENT_VERSION}{" "}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="end">
              <select
                value={enviromentSelected}
                onChange={(e) => {
                  localStorage.setItem("currentEviroment", e.target.value);
                  setEnviromentSelected(e.target.value);
                  window.location.reload();
                }}
                onSelect={(e) => {
                  console.log(e);
                }}
              >
                {Object.keys(env).map((items, index) => (
                  <option key={index} value={items}>
                    {items}
                  </option>
                ))}
              </select>
            </Box>
          </Box>
        )}
      </Container>
    </Box>
  );
};
