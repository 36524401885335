import { Typography, Box, Grid } from "@mui/material";
import { CHBButton } from "components/CHBButton";
import { HBSeparate } from "components/HBSeparate";
import dollarFormatter from "utils/FormatterDollar";
import { OfferProps, useOffer } from "./useOffer";
import CLoading from "components/CLoading";
import { useHomebridgeSizes } from "utils/useHomebridgeSizes";
import { OfferDetail } from "./OfferDetail";
import { useEffect, useRef } from "react";
import { useUpdateLoanStatus } from "../../useUpdateLoanStatus";

export const Offer = ({
  onNext,
  formData,
  setFormData,
  setGlobalError,
  setLoadingPage,
}: OfferProps) => {
  const { sizes } = useHomebridgeSizes();
  const {
    handleSelect,
    currentActive,
    errorMessage,
    error,
    animation,
    handleNext,
    offer,
    loadingOffer,
    refreshOffer,
    editRequestLoanAmount,
    setEditRequestLoanAmount,
    getOffer,
  } = useOffer({
    formData,
    setFormData,
    onNext,
    setGlobalError,
    setLoadingPage,
  });

  const { updateLoanStatus } = useUpdateLoanStatus();
  const loadedStatus = useRef(false);

  useEffect(() => {
    if (!loadedStatus.current && formData?.loanId) {
      const statusCode = "DTC004";
      updateLoanStatus({
        loanId: formData.loanId,
        status: statusCode,
      });
      loadedStatus.current = true;
    }
  }, [updateLoanStatus, formData?.loanId]);

  if (loadingOffer) {
    return <CLoading />;
  }

  return (
    <Box>
      <div className="get-started__wrapper get-started__offer">
        <Box width={"100%"}>
          <Box
            sx={{
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
              className="get-started__offer-grid"
            >
              {offer?.items?.map((item, index) => (
                <Grid item xs={12} key={`${item.maxAmount}--${index}`}>
                  <Box
                    onClick={() => handleSelect(index)}
                    className={`animated-button ${
                      currentActive?.index === index
                        ? "animated-button--active"
                        : ""
                    }`}
                    maxWidth={{ xs: "350px", sm: "75%" }}
                    margin={{
                      xs: "0 auto",
                    }}
                    sx={{
                      height: "100%",
                      border: error ? "2px solid red" : "2px solid #20A2D8",
                      animation: animation ? "shake 0.3s ease-in-out" : "none",
                      padding: "10px",
                      cursor: "pointer",
                      borderRadius: "8px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems:
                          currentActive?.index === index ? "start" : "center",
                      }}
                    >
                      {offer?.items?.length > 1 && (
                        <Box
                          className="offer-number"
                          fontFamily="NunitoSans"
                          sx={{
                            color: "#20A2D8",
                            borderRadius: "50%",
                            border: "2px solid #20A2D8",
                            padding: "2px",
                            backgroundColor: "#102939",
                            width: "42px",
                            textAlign: "center",
                            fontSize: "22px",
                          }}
                          fontWeight={700}
                        >
                          {index + 1}
                        </Box>
                      )}
                      {/* <Typography
                        variant="body2"
                        fontFamily="NunitoSans"
                        textAlign="center"
                        fontWeight={400}
                        sx={{ textTransform: "none" }}
                      >
                        Home Equity Line of Credit:
                      </Typography> */}
                      <Box
                        sx={{
                          width: "100%",
                        }}
                      >
                        {currentActive?.index === index ? (
                          <OfferDetail
                            loading={refreshOffer}
                            item={item}
                            formData={formData}
                            setFormData={setFormData}
                            edit={editRequestLoanAmount}
                            setEdit={setEditRequestLoanAmount}
                            getOffer={getOffer}
                          />
                        ) : (
                          <Typography
                            variant="h4"
                            fontFamily="NunitoSans"
                            fontSize={sizes.typographyLarge}
                            sx={{
                              textAlign: "center",
                            }}
                            fontWeight={900}
                          >
                            {dollarFormatter.format(item.amount)}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
          {!errorMessage && (
            <p className="get-started__note">
              <strong className="strong-700">Looking for less?</strong> You can
              choose a different HELOC amount once your application is finished.
            </p>
          )}

          {errorMessage && (
            <p style={{ color: "red", textAlign: "center" }}>{errorMessage}</p>
          )}
        </Box>
      </div>
      {!errorMessage && (
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "row",
              },
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
              marginTop: "40px",
            }}
          >
            <CHBButton onClick={handleNext}>Next</CHBButton>
          </Box>
        </Box>
      )}
      <HBSeparate className="step-separate" type="vertical" />
    </Box>
  );
};
