export const CircleNumberTwo = () => {
  return (
    <span style={{ width: "50px", height: "50px" }}>
      <svg
        width="50"
        height="50"
        viewBox="0 0 50 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="25" cy="27.5505" r="25" fill="#102939" />
        <path
          d="M18.4059 36.5505V33.7005L25.2459 26.4105C25.8659 25.7505 26.3559 25.1405 26.7159 24.5805C27.0959 24.0205 27.3759 23.4805 27.5559 22.9605C27.7359 22.4405 27.8259 21.9205 27.8259 21.4005C27.8259 20.4005 27.5059 19.6405 26.8659 19.1205C26.2459 18.6005 25.3359 18.3405 24.1359 18.3405C23.1959 18.3405 22.2759 18.5205 21.3759 18.8805C20.4959 19.2205 19.6459 19.7605 18.8259 20.5005L17.5359 17.6205C18.3759 16.8605 19.4259 16.2505 20.6859 15.7905C21.9459 15.3305 23.2559 15.1005 24.6159 15.1005C26.1359 15.1005 27.4159 15.3305 28.4559 15.7905C29.4959 16.2505 30.2859 16.9205 30.8259 17.8005C31.3659 18.6805 31.6359 19.7505 31.6359 21.0105C31.6359 21.6505 31.5559 22.2805 31.3959 22.9005C31.2559 23.5005 31.0259 24.1005 30.7059 24.7005C30.4059 25.3005 30.0159 25.9205 29.5359 26.5605C29.0759 27.1805 28.5159 27.8305 27.8559 28.5105L22.2159 34.3905V33.3705H32.4459V36.5505H18.4059Z"
          fill="white"
        />
      </svg>
    </span>
  );
};
