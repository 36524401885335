import { Box, Typography } from "@mui/material";
import { CHBButton } from "components/CHBButton";
import { PropertyProps } from "../Property";
import { HBSeparate } from "components/HBSeparate";
import { useEffect, useRef, useState } from "react";
import dollarFormatter from "utils/FormatterDollar";
import { CustomInput } from "../LoanRequest/CustomInput";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";
import NumberFormat from "react-number-format";
import parseMoney from "utils/parseMoney";
import { FormData } from "../../useGetStarted";
import { useHomebridgeSizes } from "utils/useHomebridgeSizes";
import { useUpdateLoanStatus } from "../../useUpdateLoanStatus";

const minAnnualIncome = 10000;

export const Incomes = ({
  onNext,
  onPrev,
  formData,
  setFormData,
}: PropertyProps) => {
  const { fourthText, tertiaryText } = useHomeBridgeColors();
  const { sizes } = useHomebridgeSizes();
  const { updateLoanStatus } = useUpdateLoanStatus();

  const loadedStatus = useRef(false);
  const [error, setError] = useState(false);
  const [animation, setAnimation] = useState(false);
  const salaryIncome = !isNaN(
    parseMoney(String(formData?.incomeSources?.salaryIncome ?? 0)),
  )
    ? parseMoney(String(formData?.incomeSources?.salaryIncome ?? 0))
    : 0;
  const selfEmploymentIncome = !isNaN(
    parseMoney(String(formData?.incomeSources?.selfEmploymentIncome ?? 0)),
  )
    ? parseMoney(String(formData?.incomeSources?.selfEmploymentIncome ?? 0))
    : 0;
  const socialIncome = !isNaN(
    parseMoney(String(formData?.incomeSources?.socialIncome ?? 0)),
  )
    ? parseMoney(String(formData?.incomeSources?.socialIncome ?? 0))
    : 0;
  const otherIncome = !isNaN(
    parseMoney(String(formData?.incomeSources?.otherIncome ?? 0)),
  )
    ? parseMoney(String(formData?.incomeSources?.otherIncome ?? 0))
    : 0;

  const totalIncome =
    salaryIncome + selfEmploymentIncome + socialIncome + otherIncome;

  const handleNext = (totalIncomeValue: number) => {
    if (totalIncomeValue < minAnnualIncome) {
      setAnimation(true);
      setError(true);
      setTimeout(() => {
        setAnimation(false);
      }, 300);
    } else {
      setError(false);
      onNext();
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    const field = id as keyof FormData["incomeSources"];
    const newFormData = { ...formData };
    newFormData["incomeSources"][field] = value as unknown as number;
    setFormData(newFormData);
  };

  useEffect(() => {
    if (!loadedStatus.current && formData?.loanId) {
      const statusCode = "DTC009";
      updateLoanStatus({
        loanId: formData.loanId,
        status: statusCode,
        body: {
          middleName: formData.middleName,
          suffix: formData.suffix,
          maritalStatus: formData.maritalStatus,
          phoneNumber: formData.phoneNumber,
        },
      });
      loadedStatus.current = true;
    }
  }, [updateLoanStatus, formData]);

  return (
    <Box>
      <div className="get-started__wrapper get-started__Incomes">
        <Box>
          <Box
            sx={{
              justifyContent: "space-between",
              position: "relative",
              marginTop: "20px",
              alignItems: "center",
            }}
            display={{
              sm: "flex",
            }}
          >
            <Box
              width={{
                sm: "48%",
                md: "48%",
                lg: "48%",
                xl: "48%",
              }}
              marginBottom={"20px"}
            >
              <Typography
                variant="body2"
                fontFamily={"NunitoSans"}
                fontSize={sizes.typography}
                fontWeight={"bold"}
                marginBottom={"10px"}
                className="custom-typography text-black"
              >
                Salary or Hourly Wages:
              </Typography>

              <NumberFormat
                id={"salaryIncome"}
                name={"salaryIncome"}
                className="no-spinner"
                thousandSeparator
                allowNegative={false}
                allowEmptyFormatting={false}
                prefix="$"
                value={formData.incomeSources.salaryIncome}
                onChange={onChange}
                style={{
                  border: error ? "2px solid red" : `2px solid ${fourthText}`,
                  animation: animation ? "shake 0.3s ease-in-out" : "none",
                }}
                customInput={CustomInput}
              />
            </Box>
            <Box
              width={{
                sm: "48%",
              }}
              marginBottom={"20px"}
            >
              <Typography
                variant="body2"
                fontFamily={"NunitoSans"}
                fontSize={sizes.typography}
                fontWeight={"bold"}
                marginBottom={"10px"}
                className="custom-typography text-black"
              >
                Self-Employment:
              </Typography>
              <NumberFormat
                id={"selfEmploymentIncome"}
                name={"selfEmploymentIncome"}
                className="no-spinner"
                thousandSeparator
                allowNegative={false}
                allowEmptyFormatting={false}
                prefix="$"
                value={formData.incomeSources.selfEmploymentIncome}
                onChange={onChange}
                style={{
                  border: error ? "2px solid red" : `2px solid ${fourthText}`,
                  animation: animation ? "shake 0.3s ease-in-out" : "none",
                }}
                customInput={CustomInput}
              />
            </Box>
          </Box>
          <Box
            sx={{
              justifyContent: "space-between",
              position: "relative",
              alignItems: "center",
            }}
            display={{
              sm: "flex",
            }}
          >
            <Box
              width={{
                sm: "48%",
              }}
              marginBottom={"20px"}
            >
              <Typography
                variant="body2"
                fontFamily={"NunitoSans"}
                fontSize={sizes.typography}
                fontWeight={"bold"}
                marginBottom={"10px"}
                className="custom-typography text-black"
              >
                Social Security, Pension, Disability:
              </Typography>
              <NumberFormat
                id={"socialIncome"}
                name={"socialIncome"}
                className="no-spinner"
                thousandSeparator
                allowNegative={false}
                allowEmptyFormatting={false}
                prefix="$"
                value={formData.incomeSources.socialIncome}
                onChange={onChange}
                style={{
                  border: error ? "2px solid red" : `2px solid ${fourthText}`,
                  animation: animation ? "shake 0.3s ease-in-out" : "none",
                }}
                customInput={CustomInput}
              />
            </Box>
            <Box
              width={{
                sm: "48%",
              }}
            >
              <Typography
                variant="body2"
                fontFamily={"NunitoSans"}
                fontSize={sizes.typography}
                fontWeight={"bold"}
                marginBottom={"10px"}
                className="custom-typography text-black"
              >
                Other:
              </Typography>
              <NumberFormat
                id={"otherIncome"}
                name={"otherIncome"}
                className="no-spinner"
                thousandSeparator
                allowNegative={false}
                allowEmptyFormatting={false}
                prefix="$"
                value={formData.incomeSources.otherIncome}
                onChange={onChange}
                style={{
                  border: error ? "2px solid red" : `2px solid ${fourthText}`,
                  animation: animation ? "shake 0.3s ease-in-out" : "none",
                }}
                customInput={CustomInput}
              />
            </Box>
          </Box>
          {error && (
            <p style={{ color: "red", marginTop: "10px", textAlign: "center" }}>
              Salary income must be greater than $10000.
            </p>
          )}
          <Typography
            variant="h4"
            fontFamily={"NunitoSans"}
            fontSize={{
              xs: "18px",
              sm: "20px",
              md: "20px",
            }}
            textAlign={"center"}
            fontWeight={700}
          >
            Total Annual Income:{" "}
            <span
              style={{
                color: tertiaryText,
              }}
            >
              {dollarFormatter.format(totalIncome)}
            </span>
          </Typography>

          <p
            className="get-started__note"
            style={{
              textAlign: "center",
            }}
          >
            <strong className="strong-700">Enter your annual salary.</strong> If
            you are paid hourly, are self-employed, or have a fixed income like
            Social Security, a pension, or disability, estimate your annual
            income for this year.
          </p>
        </Box>
      </div>

      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column-reverse",
            sm: "row",
          },
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
          marginTop: "40px",
        }}
      >
        <CHBButton onClick={onPrev} secondaryArrow={true}>
          Go Back
        </CHBButton>
        <CHBButton onClick={() => handleNext(totalIncome)}>Next</CHBButton>
      </Box>
      <HBSeparate className="step-separate" type="vertical" />
    </Box>
  );
};
