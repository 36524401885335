import { ButtonProps } from "@mui/material";
import "./styles.scss";
export interface CHBButtonProps extends ButtonProps {
  children: React.ReactNode;
  styles?: React.CSSProperties;
  styleContainer?: React.CSSProperties;
  classNameContainer?: string;
  loading?: boolean;
  secondaryArrow?: boolean;
}

export const CHBButton = ({
  children,
  styles,
  classNameContainer,
  styleContainer,
  loading,
  secondaryArrow,
  ...props
}: CHBButtonProps) => {
  const buttonClassName = secondaryArrow
    ? "secondary-animated-arrow-button"
    : "animated-arrow-button";
  return (
    <div
      className={`animated-arrow-button-container ${classNameContainer ?? ""}`}
      style={styleContainer}
    >
      <button
        className={`${
          loading ? "animated-button animated-button--loading" : buttonClassName
        }`}
        {...(!loading && { ...props })}
      >
        {loading ? (
          <div className="spinner"></div>
        ) : (
          <>
            <span></span>
            <p className="animated-button-description text-black">{children}</p>
          </>
        )}
      </button>
    </div>
  );
};
