import { Box, Skeleton, Typography, TypographyProps } from "@mui/material";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";
import { useHomebridgeSizes } from "utils/useHomebridgeSizes";

interface CustomTextProps extends TypographyProps {
  label: string;
  value: string;
  loading?: boolean;
}

export const CustomText = ({
  label,
  value,
  loading,
  ...props
}: CustomTextProps) => {
  const { sizes } = useHomebridgeSizes();
  const { primaryBackground } = useHomeBridgeColors();

  const subtitleSmall = {
    xs: "14px",
    sm: "14px",
    md: "16px",
  };

  return loading ? (
    <Box>
      <Skeleton
        animation="pulse"
        variant="text"
        sx={{
          maxWidth: "70%",
          fontSize: subtitleSmall,
          bgcolor: primaryBackground,
        }}
      />
      <Skeleton
        animation="pulse"
        variant="text"
        sx={{
          maxWidth: "70%",
          fontSize: sizes.typographyLarge,
          bgcolor: primaryBackground,
        }}
      />
    </Box>
  ) : (
    <Typography
      alignItems={"center"}
      {...props}
      sx={{
        fontWeight: 700,
        textAlign: "left",
        position: "relative",
        color: primaryBackground,
        zIndex: 1,
      }}
      fontSize={subtitleSmall}
    >
      {label} {""}
      <Typography
        variant="body1"
        sx={{ fontWeight: 900, color: "black" }}
        fontSize={sizes.typographyLarge}
      >
        {value}
      </Typography>
    </Typography>
  );
};
