export const useHomebridgeSizes = () => {
  const sizes = {
    title: {
      xs: "32px",
      sm: "34px",
      md: "38px",
    },
    subtitle: {
      xs: "18px",
      sm: "20px",
      md: "20px",
    },
    typography: {
      xs: "16px",
      sm: "16px",
      md: "18px",
    },
    typography2: {
      xs: "14px",
      sm: "14px",
      md: "16px",
    },
    typographyLarge: {
      xs: "20px",
      sm: "22px",
      md: "24px",
    },
  };

  return { sizes };
};
