import { Box, useMediaQuery, useTheme } from "@mui/material";
import { CHBLink } from "components/CHBLink";
import { Theme } from "@mui/system";

export const FooterLinkList = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: { xs: "row" },
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        gap: {
          xs: "10px",
          md: "0",
        },
      }}
    >
      <CHBLink
        to="https://myloan.homebridge.com/?_gl=1%2A1nx2yfy%2A_ga%2ANzcwODgyMTIxLjE3MzI1Mzg5ODk.%2A_ga_0Q94YM4N7L%2AMTczNjM1OTE0OC40LjEuMTczNjM1OTM5NC4wLjAuMA.."
        target="_blank"
        separate={!isMobile}
        isFooter
        style={{
          color: theme.palette.common.white,
          textUnderlineOffset: "2px",
          textDecorationColor: theme.palette.background.default,
          whiteSpace: "nowrap",
          margin: "0 10px",
        }}
      >
        Make a Payment
      </CHBLink>
      <CHBLink
        to="https://www.homebridge.com/who-we-are/"
        target="_blank"
        separate={!isMobile}
        isFooter
        style={{
          color: theme.palette.common.white,
          textUnderlineOffset: "2px",
          textDecorationColor: theme.palette.background.default,
          whiteSpace: "nowrap",
          margin: "0 10px",
        }}
      >
        About Us
      </CHBLink>
      <CHBLink
        to="https://www.homebridge.com/careers/"
        target="_blank"
        separate={!isMobile}
        isFooter
        style={{
          color: theme.palette.common.white,
          textUnderlineOffset: "2px",
          textDecorationColor: theme.palette.background.default,
          whiteSpace: "nowrap",
          margin: "0 10px",
        }}
      >
        Careers
      </CHBLink>
      <CHBLink
        to="https://homebridgeheloc.com/terms/"
        target="_blank"
        separate={!isMobile}
        isFooter
        style={{
          color: theme.palette.common.white,
          textUnderlineOffset: "2px",
          textDecorationColor: theme.palette.background.default,
          whiteSpace: "nowrap",
          margin: "0 10px",
        }}
      >
        Terms of Use
      </CHBLink>
      <CHBLink
        to="https://www.homebridge.com/privacy-policy/"
        target="_blank"
        separate={!isMobile}
        isFooter
        style={{
          color: theme.palette.common.white,
          textUnderlineOffset: "2px",
          textDecorationColor: theme.palette.background.default,
          whiteSpace: "nowrap",
          margin: "0 10px",
        }}
      >
        Privacy Policy
      </CHBLink>
      <CHBLink
        to="https://nftydoor.com/licenses"
        target="_blank"
        separate={!isMobile}
        isFooter
        style={{
          color: theme.palette.common.white,
          textUnderlineOffset: "2px",
          textDecorationColor: theme.palette.background.default,
          whiteSpace: "nowrap",
          margin: "0 10px",
        }}
      >
        Licenses
      </CHBLink>
      <CHBLink
        to="https://www.homebridge.com/contact-us/"
        target="_blank"
        isFooter
        style={{
          color: theme.palette.common.white,
          textUnderlineOffset: "2px",
          textDecorationColor: theme.palette.background.default,
          whiteSpace: "nowrap",
          margin: "0 10px",
        }}
      >
        Contact Us
      </CHBLink>
    </Box>
  );
};
