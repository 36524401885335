import { ReactNode, useEffect } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import { useIsHomebridge } from "utils/useIsHomebridge";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";
import { privateLabelTheme } from "./privateLabelTheme";
import { PrivateLabel } from "typedef/PrivateLabel";
import { homebridgeTheme } from "./homebridgeTheme";

export const MainThemeUI: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { privateLabel } = usePrivateLabel();
  const { isHomeBridge } = useIsHomebridge();
  const {
    primaryText,
    primaryBackground,
    secondaryBackground,
    secondaryText,
    tertiaryText,
    fourthText,
  } = useHomeBridgeColors();

  const homebridgeThemeObject = homebridgeTheme({
    primaryText,
    primaryBackground,
    secondaryText,
    secondaryBackground,
    tertiaryText,
    fourthText,
    privateLabel,
  });
  const privateLabelThemeObject = privateLabelTheme(
    privateLabel as PrivateLabel,
  );

  const theme = createTheme(
    isHomeBridge ? homebridgeThemeObject : privateLabelThemeObject,
  );
  useEffect(() => {
    const root = document.documentElement;

    root.style.setProperty(
      "--palette-text-primary",
      `${isHomeBridge ? primaryText : privateLabel?.pallete?.text?.secondary}`,
    );
    root.style.setProperty(
      "--palette-background-paper",
      `${privateLabel?.pallete?.background?.paper}`,
    );
    root.style.setProperty(
      "--palette-text-secondary",
      `${
        isHomeBridge ? secondaryText : privateLabel?.pallete?.text?.secondary
      }`,
    );
    root.style.setProperty(
      "--palette-background-default",
      `${
        isHomeBridge
          ? primaryBackground
          : privateLabel?.pallete?.background?.default
      }`,
    );
  }, [
    privateLabel?.pallete,
    isHomeBridge,
    primaryText,
    secondaryText,
    primaryBackground,
  ]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
