import API from "utils/API";
import Loan from "typedef/Loan";
import { useCallback, useEffect, useState } from "react";
import useUser from "context/UserCustomer/useUserCustomerContext";
import { useLocation, useParams } from "react-router-dom";

const useLoan = () => {
  const [loan, setLoan] = useState<Loan>();
  const { user, loan: loanAdmin, selectedLoanId } = useUser();
  const params = useParams();
  const currentAdminLoanId = params?.loanId;
  const location = useLocation();
  const isAdmin = location.pathname.includes("impersonate");

  const getAndSetCoborrowerLoan = useCallback(async () => {
    if (user) {
      const response =
        isAdmin && currentAdminLoanId
          ? await API.get<Loan>(
              `/admin-impersonate/get/loan/${currentAdminLoanId}`,
            )
          : await API.get<Loan>(`/get/my-loan?id=${selectedLoanId}`);

      if ("error" in response) {
        console.error(response.error);
      } else {
        setLoan(response.data);
      }
    }
  }, [user, isAdmin, currentAdminLoanId, selectedLoanId]);

  useEffect(() => {
    getAndSetCoborrowerLoan();
    const timeoutId = setInterval(getAndSetCoborrowerLoan, 2000);
    return () => {
      clearInterval(timeoutId);
    };
  }, [getAndSetCoborrowerLoan]);

  useEffect(() => {
    if (loanAdmin) {
      setLoan(loanAdmin);
    }
  }, [loanAdmin]);

  return loan;
};

export default useLoan;
