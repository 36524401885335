import { Ref, forwardRef } from "react";
import {
  Box,
  InputAdornment,
  TextField,
  TextFieldProps,
  useTheme,
} from "@mui/material";
import { useIsHomebridge } from "utils/useIsHomebridge";

export interface CTextFieldProps extends Omit<TextFieldProps, "error"> {
  error?: string;
  format?: string;
  tooltip?: React.ReactElement<unknown>;
  newRequired?: string;
}

const CTextField: React.FC<Omit<CTextFieldProps, "ref">> = forwardRef(
  ({ error, tooltip, ...props }, ref: Ref<HTMLInputElement>) => {
    const { isHomeBridge } = useIsHomebridge();
    const theme = useTheme();
    return (
      <TextField
        error={Boolean(error)}
        focused={Boolean(error)}
        helperText={error || props.helperText}
        InputLabelProps={{
          shrink: true,
          sx: {
            textAlign: "left",
          },
        }}
        ref={ref}
        label={
          <Box display={"flex"} alignItems="center">
            {props.label}
            <span
              style={{
                fontSize: "0.7rem",
                alignItems: "center",
                marginLeft: 5,
              }}
            >
              {props.newRequired}
            </span>
            {tooltip}
          </Box>
        }
        InputProps={{
          ...props.InputProps,
          startAdornment: props.type === "number" && (
            <InputAdornment
              position="start"
              sx={{
                "& p": {
                  color: theme.palette.text.secondary,
                  marginLeft: "8px",
                },
              }}
            >
              $
            </InputAdornment>
          ),
          sx: {
            color: theme.palette.text.primary,
            borderRadius: isHomeBridge ? undefined : "9px",
            padding: "18px, 14px",
          },
        }}
        {...props}
      />
    );
  },
);

export default CTextField;
