import { Box, Checkbox, Typography, useTheme } from "@mui/material";
import CButton from "components/CButton";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import { FC, useState } from "react";
import { FieldErrors } from "react-hook-form";
import Address from "typedef/Address";
import Loan from "typedef/Loan";
interface Components {
  zipcode: string;
  city_name: string;
  plus4_code: string;
  street_name: string;
  street_suffix: string;
  delivery_point: string;
  primary_number: string;
  default_city_name: string;
  state_abbreviation: string;
  delivery_point_check_digit: string;
}
interface Props {
  functionHandler: (boConsentedHardpull?: boolean) => void;
  isLoading: boolean;
  isAccepting: boolean;
  primaryHomeAddress: Address | undefined | { components: Components };
  primaryHomeAddressError: FieldErrors | undefined;
  occupancy: string;
  isDemographicsSuccess: boolean;
  loan?: Loan;
}

const AcceptOfferButton: FC<Props> = ({
  functionHandler,
  isLoading,
  isAccepting,
  primaryHomeAddress,
  primaryHomeAddressError,
  occupancy,
  isDemographicsSuccess,
  loan,
}) => {
  const theme = useTheme();
  const { privateLabel } = usePrivateLabel();

  const isOrganicOrLOLinkFromPrivateLabel =
    (loan?.invite?.body?.PLMasterLoanOfficer === undefined &&
      loan?.PLMasterLoanOfficer === undefined &&
      !loan?.loanOfficerId) ||
    (!privateLabel?.isNFTYDoor &&
      loan?.invite?.invite?.body?.completedByBorrower &&
      loan?.submittedByLO === undefined);

  const [isChecked, setIsChecked] = useState(false);

  const validationConsent = isOrganicOrLOLinkFromPrivateLabel
    ? true
    : isChecked;

  const lenderName = privateLabel?.isNFTYDoor
    ? "Homebridge Financial Services, Inc."
    : privateLabel?.legalLenderName;

  const addressComponents =
    primaryHomeAddress && "components" in primaryHomeAddress
      ? primaryHomeAddress.components
      : undefined;

  const validateManualAddress = () => {
    if (addressComponents) {
      return (
        addressComponents?.city_name === "" ||
        addressComponents?.primary_number === "" ||
        addressComponents?.state_abbreviation === "" ||
        addressComponents?.street_name === "" ||
        addressComponents?.street_suffix === "" ||
        addressComponents?.zipcode === ""
      );
    }
    return false;
  };

  const isNotPrimaryResidence = occupancy !== "Primary Residence";
  const disabledButton =
    isNotPrimaryResidence && (!primaryHomeAddress || validateManualAddress());
  const hasErrors =
    primaryHomeAddressError && Object.keys(primaryHomeAddressError).length > 0;
  const disabledPrimaryHomeAddressError = hasErrors;

  return (
    <Box>
      {!isOrganicOrLOLinkFromPrivateLabel && (
        <Box
          display={"flex"}
          flexDirection="row"
          justifyContent={{ xs: "center", md: "left" }}
          alignItems="flex-start"
          mt={{ xs: "20px", md: "40px" }}
        >
          <Box fontFamily="Pro Display Semibold" ml="-6px">
            <Checkbox
              name="hardCredit"
              checked={isChecked}
              onClick={() => {
                setIsChecked(!isChecked);
              }}
            />
          </Box>
          <Typography
            mt={0.8}
            variant="subtitle1"
            color={theme.palette.text.primary}
            textAlign={"justify"}
          >
            By clicking{" "}
            <strong style={{ fontWeight: "500" }}>"Submit Application"</strong>,
            I am providing ‘written instructions’ to{" "}
            <strong style={{ fontWeight: "500" }}>{lenderName}</strong> under
            the Fair Credit Reporting Act authorizing{" "}
            <strong style={{ fontWeight: "500" }}>{lenderName}</strong> to
            obtain information from my personal credit profile or other
            information related to my employment and income information.
          </Typography>
        </Box>
      )}
      <Box mt={"40px"}>
        <CButton
          fullWidth
          type="submit"
          variant="contained"
          loading={isAccepting}
          onClick={() => functionHandler(isChecked)}
          disabled={
            !validationConsent ||
            isLoading ||
            disabledButton ||
            disabledPrimaryHomeAddressError ||
            !isDemographicsSuccess
          }
          name="acceptAndCompleteHELOCApplication"
          sx={{ textWrap: "nowrap" }}
        >
          Submit Application
        </CButton>
      </Box>
      <Box mt={4}>
        <Typography
          variant="subtitle2"
          textAlign={"justify"}
          color={theme.palette.text.secondary}
        >
          This letter is solely a pre-qualification for terms of a loan with{" "}
          {privateLabel?.legalLenderName}. NMLS #{privateLabel?.NMLScompany},
          that you may be eligible for based on your stated information. This
          pre-qualification is not a commitment to lend or an approval of an
          application for credit with {privateLabel?.legalLenderName}. A written
          commitment to make a mortgage loan has not yet been issued. This is
          not a rate lock, and the APR, interest rate, and other related terms
          and conditions shown on this pre-qualification are sample terms only
          and are subject to change until you are approved for a loan and a
          commitment or loan offer is issued. In order for{" "}
          {privateLabel?.legalLenderName} to review your application for credit,
          you will need to submit a complete loan application and provide the
          information detailed above. Please note that the final terms and
          conditions of any loan offered to you will be based on{" "}
          {privateLabel?.legalLenderName} underwriting of and verification of
          the provided information. You are not required to obtain a loan from{" "}
          {privateLabel?.legalLenderName} just because you have received this
          pre-qualification.
        </Typography>
      </Box>
    </Box>
  );
};

export default AcceptOfferButton;
