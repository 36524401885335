import { Box, Typography } from "@mui/material";
import { CHBButton } from "components/CHBButton";
import { HBSeparate } from "components/HBSeparate";
import { Ocuppancy } from "context/Invite/useInviteContext";
import { CSSProperties, useState } from "react";
import { FormData } from "../../useGetStarted";
import { PrimaryResidence } from "components/icons/PrimaryResidence";
import { InvestmentProperty } from "components/icons/InvestmentProperty";
import { SecondHome } from "components/icons/SecondHome";
import { useHomebridgeSizes } from "utils/useHomebridgeSizes";

export interface PropertyProps {
  onNext: () => void;
  onPrev: () => void;
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}

export const Property = ({
  onNext,
  onPrev,
  formData,
  setFormData,
}: PropertyProps) => {
  const { sizes } = useHomebridgeSizes();
  const [error, setError] = useState(false);
  const [animation, setAnimation] = useState(false);
  const [currentActive, setCurrentActive] = useState(formData.occupancyType);
  const handleNext = () => {
    if (!formData.occupancyType) {
      setAnimation(true);
      setError(true);
      setTimeout(() => {
        setAnimation(false);
      }, 300);
    } else {
      setError(false);
      onNext();
    }
  };

  const handleSelect = (type: Ocuppancy) => {
    if (type === currentActive) {
      setFormData({ ...formData, occupancyType: undefined });
      setCurrentActive(undefined);
      return;
    }
    setFormData({ ...formData, occupancyType: type });
    setCurrentActive(type);
    setError(false);
  };

  const descriptionStyles: CSSProperties = {
    textTransform: "capitalize",
    marginTop: "0.45rem",
  };
  return (
    <Box>
      <Box marginTop={"60px"}>
        <Box
          sx={{
            display: "grid",
            justifyItems: "center",
            gridTemplateColumns: {
              xs: "",
              md: "repeat(3, 1fr)",
            },
            justifyContent: {
              xs: "",
              md: "center",
            },
            gap: "20px",
          }}
        >
          <CHBButton
            onClick={() => handleSelect("Primary Residence")}
            classNameContainer="full-width"
            className={`animated-button ${
              currentActive === "Primary Residence"
                ? "animated-button--active"
                : ""
            }`}
            styleContainer={{
              height: "100%",
              width: "100%",
            }}
            style={{
              border: error ? "2px solid red" : "2px solid #20A2D8",
              animation: animation ? "shake 0.3s ease-in-out" : "none",
              height: "100%",
              padding: "20px",
            }}
          >
            <PrimaryResidence />
            <Typography
              variant="body2"
              fontFamily={"NunitoSans"}
              fontSize={sizes.typography}
              fontWeight={"bold"}
              marginBottom={"10px"}
              className="custom-typography text-black"
              sx={descriptionStyles}
            >
              Full-Time
              <br /> Residence
            </Typography>
          </CHBButton>
          <CHBButton
            onClick={() => handleSelect("Second Home")}
            classNameContainer="full-width"
            className={`animated-button ${
              currentActive === "Second Home" ? "animated-button--active" : ""
            }`}
            styleContainer={{
              height: "100%",
              width: "100%",
            }}
            style={{
              border: error ? "2px solid red" : "2px solid #20A2D8",
              animation: animation ? "shake 0.3s ease-in-out" : "none",
              height: "100%",
              padding: "20px",
            }}
          >
            <SecondHome />
            <Typography
              variant="body2"
              fontFamily={"NunitoSans"}
              fontSize={sizes.typography}
              fontWeight={"bold"}
              marginBottom={"10px"}
              className="custom-typography text-black"
              sx={descriptionStyles}
            >
              Second <br />
              Home
            </Typography>
          </CHBButton>
          <CHBButton
            onClick={() => handleSelect("Investment Property")}
            classNameContainer="full-width"
            className={`animated-button ${
              currentActive === "Investment Property"
                ? "animated-button--active"
                : ""
            }`}
            styleContainer={{
              height: "100%",
              width: "100%",
            }}
            style={{
              border: error ? "2px solid red" : "2px solid #20A2D8",
              animation: animation ? "shake 0.3s ease-in-out" : "none",
              height: "100%",
              padding: "20px",
            }}
          >
            <InvestmentProperty />
            <Typography
              variant="body2"
              fontFamily={"NunitoSans"}
              fontSize={sizes.typography}
              fontWeight={"bold"}
              marginBottom={"10px"}
              className="custom-typography text-black"
              sx={descriptionStyles}
            >
              Investment <br />
              Property
            </Typography>
          </CHBButton>
        </Box>

        <p
          className="get-started__note"
          style={{ marginTop: "40px", marginBottom: "30px" }}
        >
          Knowing this will help us determine your available line of credit.
        </p>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column-reverse",
              sm: "row",
            },
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            marginTop: "40px",
          }}
        >
          <CHBButton onClick={onPrev} secondaryArrow={true}>
            Go Back
          </CHBButton>
          <CHBButton onClick={handleNext}>Next</CHBButton>
        </Box>
      </Box>
      <HBSeparate className="step-separate" type="vertical" />
    </Box>
  );
};
