import Typography from "@mui/material/Typography";
import getDisclosuresData from "./getDisclosuresData";
import useTracker from "components/CTracker/useTrackerContext";
import { Status } from "typedef/Loan";
import CTrackerTimelineItem from "components/CTimelineItem";
import { TrackerStatusColor } from "typedef/TrackerStatusColor";
import { useState, useEffect } from "react";
import moment from "moment";
import CButton from "components/CButton";

const ClosingDisclosuresVerification: React.FC<{
  trackerStatusColor: TrackerStatusColor;
}> = ({ trackerStatusColor }) => {
  const { loan, type } = useTracker();
  const [borrowerSigningUrl, setBorrowerSigningUrl] = useState<string | null>(
    null,
  );
  const [coborrowerSigningUrl, setCoborrowerSigningUrl] = useState<
    string | null
  >(null);
  const isBorrower = type === "borrower";
  const step = isBorrower ? loan?.borrowerSteps : loan?.coborrowerSteps;
  const prevStep =
    loan?.borrowerSteps?.scheduleClosingVerification.status === "success";
  const { status, message } = prevStep
    ? getDisclosuresData(step)
    : { status: "pending" as Status, message: "" };
  const closingDate = moment(
    loan?.borrowerSteps?.scheduleClosingVerification?.date,
  )
    .utc()
    .startOf("day")
    .format("MM-DD-YYYY");
  const currentDate = moment().utc().startOf("day").format("MM-DD-YYYY");
  const isClosingDate = closingDate === currentDate;
  useEffect(() => {
    if (loan?.borrowerSteps?.finalDisclosuresVerification?.borrowerSigningUrl) {
      setBorrowerSigningUrl(
        loan?.borrowerSteps?.finalDisclosuresVerification?.borrowerSigningUrl ||
          null,
      );
      if (loan?.coborrowerId) {
        setCoborrowerSigningUrl(
          loan?.borrowerSteps?.finalDisclosuresVerification
            ?.coborrowerSigningUrl || null,
        );
      }
    }
  }, [loan]);
  const isRON =
    loan?.property?.isUnsupportedCountyFips === false ? true : false;
  const borrowerValidations =
    isBorrower &&
    loan?.borrowerSteps?.finalDisclosuresVerification?.status === "analyzing" &&
    loan?.borrowerSteps?.finalDisclosuresVerification?.borrowerSigningUrl;
  const coborrowerValidations =
    !isBorrower &&
    loan?.coborrowerSteps?.finalDisclosuresVerification?.status ===
      "analyzing" &&
    loan?.borrowerSteps?.finalDisclosuresVerification?.coborrowerSigningUrl;

  const disabledForDisaster =
    !loan?.isInDisasterDeclarationArea ||
    (loan?.isInDisasterDeclarationArea &&
      (loan?.borrowerSteps?.homeValuationVerification?.PCRStatus ===
        "COMPLETED" ||
        loan?.borrowerSteps?.homeValuationVerification?.PCRDStatus ===
          "COMPLETED"));

  return (
    <CTrackerTimelineItem
      last={!isBorrower}
      status={status}
      title="Closing Disclosures"
      tooltip="Your Closing Disclosures contain your full closing package. These documents will be signed on your closing date."
      {...(status === "analyzing" &&
        message &&
        loan &&
        prevStep && {
          message: message,
        })}
    >
      {!disabledForDisaster && (
        <Typography variant="h6" color={[trackerStatusColor["analyzing"]]}>
          We are waiting for the property report to come back. After this you
          will be able to close
        </Typography>
      )}

      <Typography variant="h6" color={[trackerStatusColor[status]]}>
        {status === "analyzing" && message}
        {status === "failed" && message}
        {status === "success" && message}
      </Typography>
      {(borrowerValidations || coborrowerValidations) && isRON && (
        <CButton
          variant="contained"
          onClick={() => {
            const url = isBorrower ? borrowerSigningUrl : coborrowerSigningUrl;
            if (url) {
              window.open(url, "_blank");
            } else {
              alert("Signing URL is not available.");
            }
          }}
          disabled={!isClosingDate}
          sx={{
            textTransform: "inherit",
            fontSize: 18,
            fontWeight: 500,
            marginBottom: 3,
            marginTop: 3,
          }}
          name="closingDisclosuresVerification-reviewAndSign"
          aria-label="closingDisclosuresVerification-reviewAndSign"
        >
          Review and Sign
        </CButton>
      )}
    </CTrackerTimelineItem>
  );
};

export default ClosingDisclosuresVerification;
