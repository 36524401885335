import {
  Box,
  Typography,
  IconButton,
  MobileStepper,
  useMediaQuery,
  Theme,
} from "@mui/material";
import {
  HBEthnicityOptions,
  validateEthnicity,
} from "./DemographicCheckboxesSteps/HBethnicityOptions";
import {
  HBRaceOptions,
  validateRace,
} from "./DemographicCheckboxesSteps/HBRaceOptions";
import {
  HBSexOptions,
  validateSexAndCitizenship,
} from "./DemographicCheckboxesSteps/HBSexOptions";
import { HBCitizenship } from "./DemographicCheckboxesSteps/HBCitizenship";
import { useState, useEffect } from "react";
import { FormData } from "../../useGetStarted";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

type DemographicStepsProps = {
  formData: FormData;
  setFormData: (data: FormData) => void;
};

export const DemographicSteps = ({
  formData,
  setFormData,
}: DemographicStepsProps) => {
  const MiniStepsArray = [
    {
      component: (
        <HBEthnicityOptions formData={formData} setFormData={setFormData} />
      ),
      label: "Ethnicity",
    },
    {
      component: (
        <HBRaceOptions formData={formData} setFormData={setFormData} />
      ),
      label: "Race",
    },
    {
      component: (
        <Box display={"flex"} flexDirection={"column"} gap={3}>
          <HBSexOptions formData={formData} setFormData={setFormData} />
          <HBCitizenship formData={formData} setFormData={setFormData} />
        </Box>
      ),
      label: "SexAndCitizenship",
    },
  ];

  const [activeMiniStep, setActiveMiniStep] = useState(0);
  const [error, setError] = useState(false);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );

  useEffect(() => {
    switch (activeMiniStep) {
      case 0:
        if (validateEthnicity(formData)) {
          setError(false);
        }
        break;
      case 1:
        if (validateRace(formData)) {
          setError(false);
        }
        break;
      case 2:
        if (validateSexAndCitizenship(formData)) {
          setError(false);
        }
        break;
    }
  }, [formData, activeMiniStep]);

  const validationsByStep = {
    0: validateEthnicity,
    1: validateRace,
  };

  const handleNextMiniStep = () => {
    const isValid =
      validationsByStep[activeMiniStep as keyof typeof validationsByStep](
        formData,
      );

    if (!isValid) {
      setError(true);
      return;
    } else {
      setError(false);
    }

    setActiveMiniStep((prevStep) => {
      if (prevStep === MiniStepsArray.length - 1) {
        return prevStep;
      }

      return prevStep + 1;
    });
  };

  const handlePrevMiniStep = () => {
    setActiveMiniStep((prevStep) => {
      if (prevStep <= 0) {
        return prevStep;
      }

      return prevStep - 1;
    });
  };

  return (
    <Box sx={{ maxWidth: "100%", flexGrow: 1 }}>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ gap: 4 }}
        minWidth={{ xs: "100%", md: "650px" }}
      >
        {!isMobile && (
          <IconButton
            onClick={handlePrevMiniStep}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ArrowBackIosNewIcon
              className="get-started__arrows"
              sx={{ display: activeMiniStep === 0 ? "none" : "block" }}
            />
          </IconButton>
        )}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "start",
            justifyContent: "start",
            gap: 4,
          }}
        >
          {MiniStepsArray[activeMiniStep].component}
        </Box>
        {!isMobile && (
          <IconButton
            onClick={handleNextMiniStep}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ArrowForwardIosIcon
              className="get-started__arrows"
              sx={{
                display:
                  activeMiniStep === MiniStepsArray.length - 1
                    ? "none"
                    : "block",
              }}
            />
          </IconButton>
        )}
      </Box>
      <MobileStepper
        variant="text"
        steps={MiniStepsArray.length}
        position="static"
        activeStep={activeMiniStep}
        sx={{
          maxWidth: "100%",
          flexGrow: 1,
          justifyContent: {
            xs: "space-between",
            md: "center",
          },
          gap: 2,
          backgroundColor: "transparent",
          color: "white",
          marginTop: {
            xs: "15px",
            md: "20px",
          },
        }}
        nextButton={
          isMobile && (
            <IconButton
              onClick={handleNextMiniStep}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ArrowForwardIosIcon
                className="get-started__arrows"
                sx={{
                  display:
                    activeMiniStep === MiniStepsArray.length - 1
                      ? "none"
                      : "block",
                }}
              />
            </IconButton>
          )
        }
        backButton={
          isMobile && (
            <IconButton
              onClick={handlePrevMiniStep}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ArrowBackIosNewIcon
                className="get-started__arrows"
                sx={{ display: activeMiniStep === 0 ? "none" : "block" }}
              />
            </IconButton>
          )
        }
      />
      {activeMiniStep === MiniStepsArray.length - 1 && (
        <Typography
          align="center"
          className="get-started__note"
          marginTop={2}
          sx={{
            fontSize: {
              xs: "16px !important",
              md: "18px !important",
            },
          }}
        >
          Please select an option for each question.
        </Typography>
      )}
      {error && (
        <Typography
          color="error"
          align="center"
          sx={{
            fontSize: { xs: "16px", md: "18px" },
            mt: 1,
            fontFamily: "NunitoSans",
            animation: "shake 0.3s ease-in-out",
          }}
        >
          Please select {activeMiniStep === 0 ? "" : "at least"} one option to
          continue
        </Typography>
      )}
    </Box>
  );
};
