import { Box, Typography } from "@mui/material";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";
import { FormData } from "screens/GetStarted/useGetStarted";
import { useHomebridgeSizes } from "utils/useHomebridgeSizes";

type HBCitizenshipProps = {
  formData: FormData;
  setFormData: (data: FormData) => void;
};

export const validateCitizenship = (formData: FormData) => {
  const citizenshipOptions = formData.demographicsData?.citizenship;
  return (
    citizenshipOptions === "usCitizen" ||
    citizenshipOptions === "permanentResidentAlien" ||
    citizenshipOptions === "nonPermanentResidentAlien"
  );
};

export const HBCitizenship = ({
  formData,
  setFormData,
}: HBCitizenshipProps) => {
  const { primaryText } = useHomeBridgeColors();
  const { sizes } = useHomebridgeSizes();

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    setFormData({
      ...formData,
      demographicsData: {
        ...formData.demographicsData,
        citizenship: checked
          ? (name as
              | "usCitizen"
              | "permanentResidentAlien"
              | "nonPermanentResidentAlien")
          : "",
      },
    });
  };

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"start"}
        justifyContent={"flex-start"}
        width={"100%"}
        textAlign={"left"}
        sx={{ gap: "10px" }}
      >
        <Typography
          variant="h6"
          color={primaryText}
          sx={{
            fontSize: sizes.typography,
          }}
        >
          What's your citizenship status?
        </Typography>
        <Box
          display="flex"
          alignItems={{ xs: "start", md: "center" }}
          sx={{ gap: "10px" }}
        >
          <input
            type="checkbox"
            name="usCitizen"
            id="usCitizen"
            className="get-started__checkbox"
            checked={formData.demographicsData?.citizenship === "usCitizen"}
            onChange={handleCheckboxChange}
          />
          <Typography
            component="label"
            htmlFor="usCitizen"
            sx={{ cursor: "pointer", fontSize: sizes.typography }}
          >
            U.S. Citizen
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems={{ xs: "start", md: "center" }}
          sx={{ gap: "10px" }}
        >
          <input
            type="checkbox"
            name="permanentResidentAlien"
            id="permanentResidentAlien"
            className="get-started__checkbox"
            checked={
              formData.demographicsData?.citizenship ===
              "permanentResidentAlien"
            }
            onChange={handleCheckboxChange}
          />
          <Typography
            component="label"
            htmlFor="permanentResidentAlien"
            sx={{ cursor: "pointer", fontSize: sizes.typography }}
          >
            Permanent Resident Alien
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems={{ xs: "start", md: "center" }}
          sx={{ gap: "10px" }}
        >
          <input
            type="checkbox"
            id="nonPermanentResidentAlien"
            name="nonPermanentResidentAlien"
            className="get-started__checkbox"
            checked={
              formData.demographicsData?.citizenship ===
              "nonPermanentResidentAlien"
            }
            onChange={handleCheckboxChange}
          />

          <Typography
            component="label"
            htmlFor="nonPermanentResidentAlien"
            sx={{ cursor: "pointer", fontSize: sizes.typography }}
          >
            Non-Permanent Resident Alien
          </Typography>
        </Box>
      </Box>
    </>
  );
};
