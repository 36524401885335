type MetaTags = { [key: string]: { title: string; description: string } };

export const useIsHomebridge = () => {
  const isHomeBridge = window.location.hostname.includes("test.hb");

  // const isHomeBridge = true;

  const metaTags: MetaTags = {
    "/": {
      title: "Home Equity Line of Credit (HELOC) - Homebridge",
      description:
        "Learn how to get a HELOC fast with Homebridge. No paperwork, no delays, no kidding!",
    },
    "/faq": {
      title: "HELOC FAQs - Homebridge",
      description:
        "Learn how Homebridge works, how much equity you can access, and how fast you can close on a HELOC.",
    },
    "/resources": {
      title: "HELOC Resources - Homebridge",
      description:
        "Learn more about the benefits of getting a HELOC with Homebridge. Apply today.",
    },
    "/borrower-tracker": {
      title: "HELOC Application - Homebridge",
      description:
        "Apply for a HELOC online with Homebridge today! Get started in just two minutes.",
    },
    "/get-started": {
      title: "HELOC Application - Homebridge",
      description:
        "Apply for a HELOC online with Homebridge today! Get started in just two minutes.",
    },
  };

  return {
    isHomeBridge,
    metaTags,
  };
};
