export const CountryPhoneCode = {
  UnitedStates: { id: 1, name: "United States", code: "+1" },
  /*  Canada: { id: 2, name: "Canada", code: "+1" },
  Mexico: { id: 3, name: "Mexico", code: "+52" },
  Brazil: { id: 4, name: "Brazil", code: "+55" },
  Argentina: { id: 5, name: "Argentina", code: "+54" },
  Colombia: { id: 6, name: "Colombia", code: "+57" },
  Chile: { id: 7, name: "Chile", code: "+56" },
  Venezuela: { id: 8, name: "Venezuela", code: "+58" },
  Peru: { id: 9, name: "Peru", code: "+51" },
  Ecuador: { id: 10, name: "Ecuador", code: "+593" },
  Cuba: { id: 11, name: "Cuba", code: "+53" },
  Bolivia: { id: 12, name: "Bolivia", code: "+591" },
  CostaRica: { id: 13, name: "Costa Rica", code: "+506" },
  Panama: { id: 14, name: "Panama", code: "+507" },
  Uruguay: { id: 15, name: "Uruguay", code: "+598" },

  Spain: { id: 16, name: "Spain", code: "+34" },
  Germany: { id: 17, name: "Germany", code: "+49" },
  France: { id: 18, name: "France", code: "+33" },
  Italy: { id: 19, name: "Italy", code: "+39" },
  UnitedKingdom: { id: 20, name: "United Kingdom", code: "+44" },
  Russia: { id: 21, name: "Russia", code: "+7" },
  Ukraine: { id: 22, name: "Ukraine", code: "+380" },
  Poland: { id: 23, name: "Poland", code: "+48" },
  Romania: { id: 24, name: "Romania", code: "+40" },
  Netherlands: { id: 25, name: "Netherlands", code: "+31" },
  Belgium: { id: 26, name: "Belgium", code: "+32" },
  Greece: { id: 27, name: "Greece", code: "+30" },
  Portugal: { id: 28, name: "Portugal", code: "+351" },
  Sweden: { id: 29, name: "Sweden", code: "+46" },
  Norway: { id: 30, name: "Norway", code: "+47" },

  China: { id: 31, name: "China", code: "+86" },
  India: { id: 32, name: "India", code: "+91" },
  Japan: { id: 33, name: "Japan", code: "+81" },
  SouthKorea: { id: 34, name: "South Korea", code: "+82" },
  Indonesia: { id: 35, name: "Indonesia", code: "+62" },
  Turkey: { id: 36, name: "Turkey", code: "+90" },
  Philippines: { id: 37, name: "Philippines", code: "+63" },
  Thailand: { id: 38, name: "Thailand", code: "+66" },
  Vietnam: { id: 39, name: "Vietnam", code: "+84" },
  Israel: { id: 40, name: "Israel", code: "+972" },
  Malaysia: { id: 41, name: "Malaysia", code: "+60" },
  Singapore: { id: 42, name: "Singapore", code: "+65" },
  Pakistan: { id: 43, name: "Pakistan", code: "+92" },
  Bangladesh: { id: 44, name: "Bangladesh", code: "+880" },
  SaudiArabia: { id: 45, name: "Saudi Arabia", code: "+966" },

  Egypt: { id: 46, name: "Egypt", code: "+20" },
  SouthAfrica: { id: 47, name: "South Africa", code: "+27" },
  Nigeria: { id: 48, name: "Nigeria", code: "+234" },
  Kenya: { id: 49, name: "Kenya", code: "+254" },
  Morocco: { id: 50, name: "Morocco", code: "+212" },
  Algeria: { id: 51, name: "Algeria", code: "+213" },
  Uganda: { id: 52, name: "Uganda", code: "+256" },
  Ghana: { id: 53, name: "Ghana", code: "+233" },
  Cameroon: { id: 54, name: "Cameroon", code: "+237" },
  IvoryCoast: { id: 55, name: "Ivory Coast", code: "+225" },
  Senegal: { id: 56, name: "Senegal", code: "+221" },
  Tanzania: { id: 57, name: "Tanzania", code: "+255" },
  Sudan: { id: 58, name: "Sudan", code: "+249" },
  Libya: { id: 59, name: "Libya", code: "+218" },
  Tunisia: { id: 60, name: "Tunisia", code: "+216" },

  Australia: { id: 61, name: "Australia", code: "+61" },
  NewZealand: { id: 62, name: "New Zealand", code: "+64" },
  Fiji: { id: 63, name: "Fiji", code: "+679" },
  PapuaNewGuinea: { id: 64, name: "Papua New Guinea", code: "+675" },
  Tonga: { id: 65, name: "Tonga", code: "+676" },

  Iran: { id: 66, name: "Iran", code: "+98" },
  Iraq: { id: 67, name: "Iraq", code: "+964" },
  Jordan: { id: 68, name: "Jordan", code: "+962" },
  Lebanon: { id: 69, name: "Lebanon", code: "+961" },
  Kuwait: { id: 70, name: "Kuwait", code: "+965" },
  UnitedArabEmirates: { id: 71, name: "United Arab Emirates", code: "+971" },
  Oman: { id: 72, name: "Oman", code: "+968" },
  Qatar: { id: 73, name: "Qatar", code: "+974" },
  Bahrain: { id: 74, name: "Bahrain", code: "+973" },
  Yemen: { id: 75, name: "Yemen", code: "+967" }, */
} as const;

export type CountryPhoneCodeKey = keyof typeof CountryPhoneCode;
export type CountryPhoneCodeValue =
  (typeof CountryPhoneCode)[CountryPhoneCodeKey];
