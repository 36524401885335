import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import LoanCompleted from "screens/404";
import { HomeEquity } from "./HBResourcesItems/homeEquity";
import HowToGetHELOC from "./HowToGetHELOC";
import IsHELOCGoodIdea from "./IsHELOCGoodIdea";
import WhatIsHELOC from "./WhatIsHELOC";

export const HBResourcesDetails = () => {
  const { id } = useParams<{ id: string }>();

  const renderContent = (resourceId: string) => {
    switch (resourceId) {
      case "what-is-home-equity":
        return <HomeEquity />;
      case "how-to-get-a-heloc":
        return <HowToGetHELOC />;
      case "is-a-heloc-a-good-idea":
        return <IsHELOCGoodIdea />;
      case "what-is-a-heloc":
        return <WhatIsHELOC />;
      default:
        return <LoanCompleted />;
    }
  };

  return <Box>{id ? renderContent(id) : null}</Box>;
};
