import Loan from "typedef/Loan";
import API from "utils/API";

export enum StepName {
  "borrowerSteps" = "borrowerSteps",
  "coborrowerSteps" = "coborrowerSteps",
}

export const getAnualIncomeByTypeSelection = async ({
  loan,
  selection,
  stepName,
  truvIncomeSource,
}: {
  loan: Loan;
  selection: string;
  stepName: StepName;
  truvIncomeSource?: string;
}) => {
  const params = new URLSearchParams();
  params.append("selection", selection);
  params.append("stepName", stepName);
  params.append("loanId", loan.id);
  if (truvIncomeSource) {
    params.append("truvIncomeSource", truvIncomeSource);
  }
  const isAdmin = window.location.pathname.includes("impersonate");
  const url = isAdmin
    ? `/admin-impersonate/get/get-annual-income/${loan.id}?${params.toString()}`
    : `/get/get-annual-income?${params.toString()}`;

  try {
    const response = await API.get(url);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
