import { Theme, useMediaQuery } from "@mui/system";
import { Box, Grid, Typography } from "@mui/material";
import { CHBButton } from "components/CHBButton";
import { FormData } from "../../useGetStarted";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";
import { HBSeparate } from "components/HBSeparate";
import dayjs from "dayjs";
import useIdentityValidation from "./useIdentityValidation";
import CHBDatePicker from "components/CHBDatePicker";
import CHBSsnInput from "components/CHBSsnInput";
import { useHomebridgeSizes } from "utils/useHomebridgeSizes";
import { useEffect, useRef } from "react";
import { useUpdateLoanStatus } from "screens/GetStarted/useUpdateLoanStatus";
/* import { useUpdateLoanStatus } from "../../useUpdateLoanStatus";
import { useEffect, useRef } from "react"; */

export interface BorrowerIdentityDataProps {
  onPrev: () => void;
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}

export const BorrowerIdentityData = ({
  onPrev,
  formData,
  setFormData,
}: BorrowerIdentityDataProps) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );
  const { updateLoanStatus } = useUpdateLoanStatus();

  const loadedStatus = useRef(false);

  const { primaryText, fourthText } = useHomeBridgeColors();
  const { sizes } = useHomebridgeSizes();
  const {
    errors,
    animation,
    error,
    errorMessage,
    handleNext,
    onValueChange,
    handleCheckboxChange,
    checkboxState,
    loading,
  } = useIdentityValidation(formData, setFormData);

  useEffect(() => {
    if (!loadedStatus.current && formData?.loanId) {
      const statusCode = "DTC011";
      updateLoanStatus({
        loanId: formData.loanId,
        status: statusCode,
      });
      loadedStatus.current = true;
    }
  }, [updateLoanStatus, formData?.loanId]);

  return (
    <Box className="get-started__borrower-identity-data">
      <div className="get-started__wrapper">
        <Box
          width={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
          display={"flex"}
          flexDirection={"column"}
        >
          <Typography
            variant="h6"
            fontFamily={"NunitoSans"}
            fontSize={sizes.subtitle}
            textAlign={"center"}
            marginBottom={"20px"}
            fontWeight={600}
            width={{
              xl: "80%",
              lg: "75%",
            }}
          >
            To complete and submit your application, we just need the following:
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <CHBDatePicker
                id="dateOfBirth"
                label="Date of Birth:"
                value={formData.dateOfBirth ? dayjs(formData.dateOfBirth) : ""}
                onChange={onValueChange}
                minDate={dayjs("1908-02-05")}
                maxDate={dayjs().subtract(18, "years")}
                format="MM-DD-YYYY"
                className="get-started__dobInput"
                isMobile={isMobile}
                error={
                  errors.find((err) => err.field === "dateOfBirth")?.message ||
                  ""
                }
                hasError={
                  errors.find((err) => err.field === "dateOfBirth")
                    ? true
                    : false
                }
                animation={animation}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CHBSsnInput
                id="ssn"
                label="Social Security Number:"
                value={formData.ssn || undefined}
                className="get-started__ssnInput"
                error={errors.find((err) => err.field === "ssn")?.message || ""}
                hasError={
                  errors.find((err) => err.field === "ssn") ? true : false
                }
                animation={animation}
                format="###-##-####"
                placeholder="xxx-xx-xxxx"
                onchange={onValueChange}
              />
            </Grid>
          </Grid>
        </Box>
        <p className="get-started__note">
          <strong className="strong-700">Your information is safe. </strong>We
          will only use it to approve you for this HELOC.
        </p>
        <Box>
          <Box
            display="flex"
            flexDirection={"column"}
            justifyContent="space-between"
            gap="15px"
            mt={2}
            maxWidth={"100%"}
          >
            <Box display="flex" alignItems="start">
              <input
                type="checkbox"
                name="PrivacyPolicyCheck"
                className="get-started__checkbox"
                checked={checkboxState.PrivacyPolicyCheck}
                onChange={handleCheckboxChange}
                style={{
                  border:
                    error &&
                    errors.find((err) => err.field === "PrivacyPolicyCheck")
                      ? "2px solid red"
                      : `0px solid ${fourthText}`,
                  animation:
                    animation &&
                    errors.find((err) => err.field === "PrivacyPolicyCheck")
                      ? "shake 0.3s ease-in-out"
                      : "none",
                }}
              />
              <Typography
                sx={{ paddingLeft: "20px", margin: "0px !important" }}
              >
                I agree to the{" "}
                <a
                  href="https://homebridgeheloc.com/terms/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: primaryText }}
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  href="https://www.homebridge.com/privacy-policy/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: primaryText }}
                >
                  Privacy Policy
                </a>
              </Typography>
            </Box>
            <Box display="flex" alignItems="start">
              <input
                type="checkbox"
                className="get-started__checkbox"
                name="BorrowerConsentCheck"
                checked={checkboxState.BorrowerConsentCheck}
                onChange={handleCheckboxChange}
                style={{
                  border:
                    error &&
                    errors.find((err) => err.field === "BorrowerConsentCheck")
                      ? "2px solid red"
                      : `0px solid ${fourthText}`,
                  animation:
                    animation &&
                    errors.find((err) => err.field === "BorrowerConsentCheck")
                      ? "shake 0.3s ease-in-out"
                      : "none",
                }}
              />
              <Typography
                sx={{
                  paddingLeft: "20px !important",
                  margin: "0px !important",
                }}
              >
                I certify that I have read Homebridge’s Electronic
                Communications Policy and consent to receive all legally
                required notices and disclosures and other communications
                (“Communications”) from Homebridge Financial Services
                (“HomeBridge”) electronically and not on paper. I also confirm
                I’m able to access, view, retain, and print all of the
                Communications Homebridge provides me in electronic form.
              </Typography>
            </Box>
          </Box>
        </Box>
        <p
          style={{
            marginLeft: "0px",
            marginRight: "0px",
            margin: "0px",
            maxWidth: "100%",
            width: "100%",
            padding: "30px 5px",
          }}
        >
          By pressing the “SUBMIT” button below, I am providing “written
          instructions” to Homebridge under the Fair Credit Reporting Act
          authorizing Homebridge to obtain information from my personal credit
          profile and other information from Experian. I authorize Homebridge to
          obtain such information solely to conduct a pre-qualification for
          credit and acknowledge this will not affect my credit score.
        </p>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column-reverse",
              sm: "row",
            },
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            marginTop: "40px",
          }}
        >
          <CHBButton onClick={onPrev} secondaryArrow={true} disabled={loading}>
            Go Back
          </CHBButton>
          <CHBButton
            onClick={handleNext}
            className="animated-arrow-button"
            loading={loading}
            disabled={loading}
          >
            Submit
          </CHBButton>
        </Box>
        {errorMessage && (
          <Typography
            variant="body2"
            fontFamily={"NunitoSans"}
            fontSize={sizes.typography}
            textAlign={"center"}
            marginTop={"20px"}
            color={"red"}
          >
            {errorMessage}
          </Typography>
        )}
        <HBSeparate className="step-separate" type="vertical" />
      </div>
    </Box>
  );
};
