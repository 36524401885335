import { Box } from "@mui/material";
import { CircleCheck } from "components/icons/CircleCheck";
import { useEffect, useState } from "react";

import "./progressBar.scss";
interface ProgressBarProps {
  progress: number;
  show?: boolean;
}

export const ProgressBar = ({ progress, show = false }: ProgressBarProps) => {
  const [showCircle, setShowCircle] = useState(false);
  const [showFireworks, setShowFireworks] = useState(false);

  useEffect(() => {
    if (progress === 100 || progress === 70) {
      setTimeout(() => {
        setShowFireworks(true);
        setShowCircle(true);
      }, 375);
      const timeout = setTimeout(() => setShowFireworks(false), 1000);
      return () => clearTimeout(timeout);
    } else {
      setShowCircle(false);
    }
  }, [progress]);

  if (!show) {
    return (
      <Box
        display={"flex"}
        className="progress-bar__container progress-bar__container--hide"
        sx={{
          width: "100%",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <CircleCheck height={54} width={54} />
        </Box>
        <Box
          className="progress-bar"
          sx={{
            width: "80%",
            marginLeft: "15px",
          }}
        >
          <Box
            className="progress"
            style={{
              width: `${progress}%`,
              borderTopRightRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
          ></Box>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box
        display={"flex"}
        className="progress-bar__container progress-bar__container--show"
        sx={{
          width: "100%",
          position: "relative",
        }}
      >
        <Box
          className="progress-bar"
          sx={{
            width: "80%",
          }}
        >
          <Box className="progress" style={{ width: `${progress}%` }}></Box>
        </Box>
        {showCircle && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <CircleCheck height={54} width={54} className="animated" />
            {showFireworks && (
              <div className="fireworks-container">
                {Array.from({ length: 10 }).map((_, i) => (
                  <div key={i} className="firework"></div>
                ))}
              </div>
            )}
          </Box>
        )}

        <Box
          className="progress-bar"
          sx={{
            width: "15%",
            marginLeft: "8px",
          }}
        >
          <Box className="progress" style={{ width: 0 }}></Box>
        </Box>
      </Box>
    );
  }
};
