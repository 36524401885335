import CButton from "components/CButton";
import CTextField from "components/CTextField";
import useValidateAccount from "./useValidateAccount";
import {
  Box,
  Container,
  FormHelperText,
  Typography,
  useTheme,
} from "@mui/material";

const ValidateAccount = () => {
  const theme = useTheme();
  const validateAccount = useValidateAccount();

  return (
    <Container maxWidth="md">
      <Box>
        <Typography
          mt={4}
          mb={2}
          variant="h2"
          lineHeight={1}
          color={theme.palette.text.primary}
          letterSpacing={-0.8}
          pl={{ xs: 2, md: 0 }}
          fontSize={{ xs: "3rem", md: "5rem" }}
        >
          Verify your account
        </Typography>
      </Box>
      <Box mb={4}>
        <Typography variant="h6" color={theme.palette.text.secondary}>
          Enter the code that we sent to your email
        </Typography>
      </Box>
      <form onSubmit={validateAccount.handleSubmit}>
        <Box
          py={7}
          px={6}
          bgcolor={theme.palette.background.paper}
          borderRadius={8}
        >
          <Box display={"flex"} flexDirection={"column"} gap={4}>
            <CTextField
              fullWidth
              disabled={true}
              label={"Email address"}
              error={validateAccount.formState?.errors?.email?.message}
              {...validateAccount.register("email")}
            />
            <CTextField
              fullWidth
              label={"Code"}
              error={validateAccount.formState?.errors?.code?.message}
              {...validateAccount.register("code")}
            />
            <CButton
              disabled={validateAccount.loading}
              loading={validateAccount.loading}
              type="submit"
              sx={{ height: "fit-content", mt: 2, px: 4, py: 1.8 }}
              variant="contained"
            >
              Validate
            </CButton>
          </Box>
          {validateAccount.error && (
            <FormHelperText error={Boolean(validateAccount.error)}>
              <Typography sx={{ ml: 1, mt: 1 }}>
                {validateAccount.error}
              </Typography>
            </FormHelperText>
          )}
        </Box>
      </form>
    </Container>
  );
};

export default ValidateAccount;
