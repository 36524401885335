import React, { useCallback, useMemo, useState } from "react";
import { useBorrowerPersonalData } from "../BorrowerPersonalData/useBorrowerPersonalData";
import { FormData } from "../../useGetStarted";
import { PropertyProps } from "../Property";
import API from "utils/API";
import { LStoken, LStokenExp } from "CONST";
import useUser from "context/UserCustomer/useUserCustomerContext";

export const useVerifyEmail = ({
  formData,
  onNext,
  setFormData,
}: PropertyProps) => {
  const { setUser, setSelectedLoanId } = useUser();
  const [loading, setLoading] = useState(false);
  const { errors, validateInputs } = useBorrowerPersonalData();

  const [code, setCode] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [animation, setAnimation] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmUser, setShowConfirmUser] = useState(false);
  const [isRegister, setIsRegister] = useState(false);

  const inputsToValidate: { field: keyof FormData; value: string }[] =
    useMemo(() => {
      return [
        { field: "firstName", value: formData.firstName ?? "" },
        { field: "email", value: formData.email ?? "" },
        { field: "password", value: formData.password ?? "" },
        { field: "lastName", value: formData.lastName ?? "" },
      ];
    }, [formData]);

  const onValueChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { id, value } = event.target;
    const field = id as keyof FormData;
    const newFormData = { ...formData };
    (newFormData[field as unknown as keyof FormData] as unknown) = value;
    setFormData(newFormData);
    validateInputs({ field, value });
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmUser = useCallback(() => {
    setError(false);
    setErrorMessage("");
    setShowConfirmUser(!showConfirmUser);
  }, [showConfirmUser]);

  const registerUser = useCallback(async (params: { formData: FormData }) => {
    setError(false);
    setLoading(true);
    setErrorMessage("");
    try {
      const response = await API.post({
        url: `/register-user/user`,
        data: {
          ...params.formData,
        },
      });

      if ("error" in response) {
        // @ts-ignore
        if (response?.errorId?.message) {
          // @ts-ignore
          setErrorMessage(response?.errorId?.message ?? response.error);
          setError(true);
          setLoading(false);
          return;
        } else {
          setErrorMessage(response.error);
          setError(true);
          setLoading(false);
          return;
        }
      } else {
        setLoading(false);
        setShowConfirmUser(true);
        setIsRegister(true);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const resendCode = useCallback(async () => {
    setError(false);
    setLoading(true);
    setErrorMessage("");
    try {
      const response = await API.post({
        url: "/resend-code",
        data: {
          username: formData.email?.trim(),
          type: "email",
        },
      });
      if ("error" in response) {
        // @ts-ignore
        if (response?.errorId?.message) {
          // @ts-ignore
          setErrorMessage(response?.errorId?.message ?? response.error);
          setError(true);
          setLoading(false);
          return;
        } else {
          setErrorMessage(response.error);
          setError(true);
          setLoading(false);
          return;
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  }, [formData.email, setError]);

  const signIn = useCallback(async () => {
    setError(false);
    setLoading(true);
    setErrorMessage("");
    try {
      const data = {
        email: formData.email,
        password: formData.password,
        DTCApplication: true,
      };

      const response = await API.post<{
        idToken: {
          jwtToken: string;
          payload: {
            exp: number;
          };
        };
        accessToken: {
          payload: {
            sub: string;
          };
        };
      }>({
        url: `/login`,
        data,
      });
      if ("error" in response) {
        // @ts-ignore
        if (response?.errorId?.message) {
          // @ts-ignore
          setErrorMessage(response?.errorId?.message ?? response.error);
          setError(true);
          setLoading(false);
          return;
        } else {
          setErrorMessage(response.error);
          setError(true);
          setLoading(false);
          return;
        }
      } else {
        localStorage.setItem(
          LStokenExp,
          String(response.data.idToken.payload.exp * 1000),
        );
        localStorage.setItem(LStoken, response.data.idToken.jwtToken);
        const userIdLogged = response.data.accessToken.payload.sub;
        setUser((prev) => ({
          ...prev,
          exp: response.data.idToken.payload.exp * 1000,
          token: response.data.idToken.jwtToken,
          id: userIdLogged,
        }));

        const multipleLoanResponse = await API.get<{
          isUserWithMultipleLoans: boolean;
          uniqueLoanId?: string;
        }>("/get/multiple-loans");
        let uniqueLoanId;
        if ("data" in multipleLoanResponse) {
          uniqueLoanId = multipleLoanResponse?.data?.uniqueLoanId;
        }

        if (uniqueLoanId) {
          setSelectedLoanId(uniqueLoanId);
          setLoading(false);
          onNext();
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [formData.email, formData.password, setUser, setSelectedLoanId, onNext]);

  const confirmUser = useCallback(
    async (email: string, code: string) => {
      setError(false);
      setLoading(true);
      setErrorMessage("");
      try {
        const data = {
          username: email,
          code: code,
        };

        const response = await API.post({
          url: `/confirm-user`,
          data,
        });
        if ("error" in response) {
          // @ts-ignore
          if (response?.errorId?.message) {
            // @ts-ignore
            setErrorMessage(response?.errorId?.message ?? response.error);
            setError(true);
            setLoading(false);
            return;
          } else {
            setErrorMessage(response.error);
            setError(true);
            setLoading(false);
            return;
          }
        } else {
          signIn();
        }
      } catch (error) {
        console.error(error);
      }
    },
    [signIn],
  );

  const handleNext = useCallback(() => {
    setError(false);
    setErrorMessage("");
    const inputErrors = validateInputs(inputsToValidate);
    if (isRegister && !showConfirmUser) {
      toggleShowConfirmUser();
    } else if (
      inputErrors?.length > 0 ||
      formData.password !== confirmPassword
    ) {
      setAnimation(true);
      setError(true);
      setTimeout(() => {
        setAnimation(false);
      }, 300);
    } else if (showConfirmUser) {
      confirmUser(formData.email, code);
    } else {
      registerUser({ formData });
    }
  }, [
    confirmPassword,
    formData,
    inputsToValidate,
    showConfirmUser,
    validateInputs,
    registerUser,
    code,
    confirmUser,
    isRegister,
    toggleShowConfirmUser,
  ]);

  return {
    animation,
    onValueChange,
    error,
    errors,
    errorMessage,
    showPassword,
    toggleShowPassword,
    confirmPassword,
    handleNext,
    setConfirmPassword,
    loading,
    showConfirmUser,
    setShowConfirmUser,
    isRegister,
    setIsRegister,
    registerUser,
    confirmUser,
    code,
    setCode,
    validateInputs,
    setError,
    resendCode,
    toggleShowConfirmUser,
    signIn,
  };
};
