import { Box, Typography } from "@mui/material";
import styles from "./styles";
import CDialog from "components/CDialog";
import CButton from "components/CButton";
import { useCallback, useEffect, useState } from "react";
import API from "utils/API";
import Loan from "../../../../../typedef/Loan";
import CDocUploadDocumentDialog, {
  NeededDoc,
} from "../../AddMissingDocsDialog";
import useTracker from "components/CTracker/useTrackerContext";

interface ConfirmFirstLienMortgageModalProps {
  open: boolean;
  message: string;
  isBorrower: boolean;
  loan?: Loan;
  docsToUpload: NeededDoc[];
}

export const ConfirmFirstLienMortgageModal = ({
  open,
  message,
  isBorrower,
  loan,
  docsToUpload,
}: ConfirmFirstLienMortgageModalProps) => {
  const [loading, setLoading] = useState(false);

  const { isModalOpen, setIsModalOpen } = useTracker();
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setOpenModal(open);
  }, [open]);

  const needDoc = docsToUpload?.find(
    (doc) => doc?.docName === "Recent Mortgage Statement",
  );
  const [openUploadDoc, setOpenUploadDoc] = useState<boolean | null>(null);
  const [docToUpload, setDocToUpload] = useState<NeededDoc | undefined>(
    needDoc,
  );

  const entity = isBorrower ? "borrower" : "coborrower";
  const flag = isBorrower ? loan?.borrowerFlags : loan?.coborrowerFlags;

  const handleSubmittedDocs = useCallback(async () => {
    await API.post({
      url: `/save-to-loan/flags?entity=${entity}&loanId=${loan?.id}`,
      data: {
        creditVerification: {
          thereFirstLien: true,
        },
      },
    });
  }, [entity, loan]);

  const handleYes = async () => {
    try {
      if (flag?.creditVerification?.thereFirstLien === false) {
        setDocToUpload(needDoc);
        setOpenUploadDoc(true);
        return;
      }
      setLoading(true);
      await API.post({
        url: `/save-to-loan/flags?entity=${entity}&loanId=${loan?.id}`,
        data: {
          creditVerification: {
            thereFirstLien: false,
          },
        },
      });
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleNo = async () => {
    try {
      setLoading(true);
      await API.post({
        url: `/save-to-loan/flags?entity=${entity}&loanId=${loan?.id}`,
        data: {
          creditVerification: {
            ownedFreeAndClear: true,
          },
        },
      });
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open && !isModalOpen) {
      setIsModalOpen(true);
    }
  }, [open, isModalOpen, setIsModalOpen]);

  useEffect(() => {
    if (
      flag?.creditVerification?.thereFirstLien === false &&
      needDoc?.submitted === false &&
      openUploadDoc === null
    ) {
      setDocToUpload(needDoc);
      setOpenUploadDoc(true);
    } else if (needDoc?.submitted === true && open) {
      handleSubmittedDocs();
      setOpenUploadDoc(false);
      setIsModalOpen(false);
      setOpenModal(false);
    }
  }, [
    open,
    flag?.creditVerification?.thereFirstLien,
    needDoc,
    openUploadDoc,
    setIsModalOpen,
    handleSubmittedDocs,
  ]);

  return (
    <CDialog open={open && openModal} className="denied-loan-modal">
      <Box>
        <Typography variant="h3">Confirm</Typography>

        <Typography variant="body2" sx={styles.description}>
          {message}
        </Typography>
        <Box sx={styles.containerButton}>
          <CButton
            loading={loading}
            variant="contained"
            sx={styles.buttonStyles}
            onClick={handleYes}
            name={"yes-confirm-first-lien-mortgage"}
            aria-label={"yes-confirm-first-lien-mortgage"}
          >
            Yes
          </CButton>

          <CButton
            loading={loading}
            variant="contained"
            sx={styles.buttonStyles}
            onClick={handleNo}
            name={"no-confirm-first-lien-mortgage"}
            aria-label={"no-confirm-first-lien-mortgage"}
          >
            No
          </CButton>
        </Box>
      </Box>
      {docToUpload && openUploadDoc && (
        <CDocUploadDocumentDialog
          open={Boolean(docToUpload && openUploadDoc)}
          handleClose={() => {
            setDocToUpload(undefined);
            setOpenUploadDoc(false);
          }}
          neededDocs={[docToUpload]}
          singleDocument={true}
        />
      )}
    </CDialog>
  );
};
