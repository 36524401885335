import moment from "moment";
import React, { useState } from "react";
import Loan from "typedef/Loan";
import API from "utils/API";

type User = {
  socialSecurityNumber: string;
  dateOfBirth: string;
};
export const useConfirmSSNDOB = (loan: Loan, onClose: () => void) => {
  const styles = {
    icon: {
      fontSize: "1.5rem",
    },
    containerButton: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: "2rem",
    },
    buttonStyles: {
      mt: 2,
      width: { md: "auto", xs: "100%" },
      whiteSpace: "nowrap",
    },
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [errorsInputs, setErrorsInputs] = useState<{ [key: string]: string }>(
    {},
  );
  const [showSocialSecurityNumber, setShowSocialSecurityNumber] =
    useState(true);

  const [user, setUser] = useState<User>({
    socialSecurityNumber: "",
    dateOfBirth: "",
  });

  const validateUser = (socialSecurityNumber: string, dateOfBirth: string) => {
    const trimmedSSN = socialSecurityNumber.trim();
    const trimmedDOB = dateOfBirth.trim();

    const checks = [
      {
        input: "socialSecurityNumber",
        value: trimmedSSN,
        validations: [
          {
            regex: /^\d{3}-\d{2}-\d{4}$/,
            message: "Invalid Social Security Number.",
          },
          {
            regex: /\S/,
            message: "This field is required.",
          },
        ],
      },
      {
        input: "dateOfBirth",
        value: trimmedDOB,
        validations: [
          {
            regex: /^\d{4}-\d{2}-\d{2}$/,
            message: "Invalid Date of Birth.",
          },
          {
            regex: /\S/,
            message: "This field is required.",
          },
        ],
      },
    ];

    const errors = checks.reduce(
      (acc, { input, value, validations }) => {
        for (const { regex, message } of validations) {
          if (!regex.test(value)) {
            acc[input] = message;
            break;
          } else {
            delete acc[input];
          }
        }
        return acc;
      },
      {} as { [key: string]: string },
    );

    setErrorsInputs(errors);
    return Object.keys(errors).length === 0;
  };

  const toggleShowSocialSecurityNumber = () => {
    setShowSocialSecurityNumber(!showSocialSecurityNumber);
  };

  const handleUserChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    const updatedUser = {
      ...user,
      [id]: value,
    };
    validateUser(updatedUser.socialSecurityNumber, updatedUser.dateOfBirth);
    setUser(updatedUser);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const isValid = validateUser(user.socialSecurityNumber, user.dateOfBirth);

    if (!isValid) {
      setLoading(false);
      return;
    }

    const response = await API.post<{ message: string }>({
      url: "/request-identity-verification",
      data: {
        loanId: loan.id,
        birthdate: moment(user.dateOfBirth).format("yyyy/MM/DD"),
        "identification-number": user.socialSecurityNumber.replace(/-/g, ""),
      },
    });

    if ("error" in response) {
      setError(response.error);
    } else {
      onClose();
    }
    setLoading(false);
  };

  const resetForm = () => {
    setUser({
      socialSecurityNumber: "",
      dateOfBirth: "",
    });
    setErrorsInputs({});
    setError(null);
    setShowSocialSecurityNumber(true);
  };

  return {
    styles,
    loading,
    error,
    errorsInputs,
    user,
    showSocialSecurityNumber,
    toggleShowSocialSecurityNumber,
    handleUserChange,
    handleSubmit,
    setErrorsInputs,
    resetForm,
  };
};
