import { BlogTemplate } from "components/BlogTemplate";

const WhatIsHELOC = () => {
  return (
    <BlogTemplate
      title="Is a HELOC a good idea?"
      subtitle="That’s a good question! But before we answer it, let’s take a quick look at what a HELOC is and how it works.
                Simply stated, a HELOC is a home equity line of credit — a borrowing tool that lets you access the equity in your home and turn it into cash. Home equity is the difference between what your home is worth and how much you owe on your mortgage.
                The interest you pay on a HELOC is typically lower than what you’d pay on a personal loan or credit card. This is because the line of credit is secured by your home.
                You can use a HELOC to pay for anything, but should you? That’s another good question!"
    >
      <h2>How does a HELOC work?</h2>
      <p>
        Now that we’ve covered the basics of a HELOC, you might be wondering
        exactly how a HELOC works. The short answer: different HELOCs work
        different ways.
      </p>
      <p>
        For example, when you apply and qualify for a Homebridge HELOC, you are
        given a range of how much you can borrow. Once you decide on the amount
        you want, a line of credit is opened, and funds are disbursed.
      </p>
      <p>
        You can elect to have payments made directly to your credit card company
        and other lenders, have the funds deposited directly into your bank
        account, or both!
      </p>
      <p>
        For the first five years after you receive your funds, you’ll make
        “interest-only” payments. These are lower than traditional
        “principal-and-interest” payments, which can make it a little easier on
        your monthly budget.
      </p>
      <p>
        After the first five years, you enter the repayment period. During this
        time, you won’t be able to borrow money from your HELOC. Instead, you’ll
        be required to start paying back your loan. This includes the loan
        itself (the principal) plus interest.
      </p>
      <p>
        One of the common closing costs is the lender fee, which they charge for
        setting you up with a loan. This fee is typically 1 to 5% of your credit
        line.
      </p>

      <h2>Are there requirements for a HELOC?</h2>
      <p>
        To get a HELOC, you must have a certain amount of equity in your home.
        The amount can differ among lenders, but you’re usually expected to have
        at least 20% equity.
      </p>
      <p>
        Do you know your credit score? Generally, you’ll have an easier time
        qualifying for a HELOC if your credit score is 640 or higher.
      </p>
      <p>
        You also don’t want to carry too much debt, such as high credit card
        bills. If a large portion of your salary goes toward debt payments, a
        lender might consider a HELOC too big of an obligation. (That said, you
        may also be able to use your new HELOC to pay down your high-interest
        bills — improving your debt-to-income ratio.)
      </p>
      <p>
        Your home will need an appraisal to establish its worth. Many lenders
        use an automated valuation model (AVM), which means an appraiser does
        not need to visit your home to determine its value. Rather, this model
        compares the values of properties similar to yours.
      </p>
      <p>
        Lenders will want you to have a reliable source of income and a good job
        record. They’ll also examine your history of repaying loans. For
        example, they’ll want to see if you made your car loan payments on time.
      </p>

      <h2>How to use a HELOC</h2>
      <p>
        Although you can use a HELOC however you like, it’s often recommended
        you only use it for major or necessary expenses. A new roof for your
        home is a good example. A luxury vacation, on the other hand, is
        probably not.
      </p>
      <p>
        Some homeowners use their HELOC to pay off debts with high interest
        rates, such as credit cards. HELOCs can also come in handy when
        emergency medical bills appear.
      </p>
      <p>
        Families sometimes use HELOCs to pay for the ever-increasing cost of a
        college education. Another option is to use a HELOC as an investment or
        financial opportunity.
      </p>
      <p>
        Many older people have discovered that retiring too soon can rapidly
        drain savings, forcing them to find a job, whether part time or full. A
        HELOC can support retirement, allowing people to enjoy their freedom
        rather than look for employment.
      </p>

      <h2>How much HELOC can I get?</h2>

      <p>
        When applying for a loan such as a HELOC, it’s natural to wonder how
        much money you can borrow from your home. Let’s look at the basic
        calculation:
      </p>
      <p>
        Most lenders offer up to 80% of your home’s value minus your mortgage
        balance. So, let’s start by multiplying your home’s value by 0.8.
      </p>
      <p>
        Using the previous example: If your home is worth $300,000, multiply
        that by 0.8 to get $240,000.
      </p>
      <p>
        Now subtract your mortgage balance, the amount you have left to pay on
        the house. Let’s say that’s $100,000.
      </p>
      <p>
        $240,000 minus $100,000 equals $140,000, the HELOC amount a lender might
        offer.
      </p>

      <h2>Access your home equity</h2>
      <p>
        You’ve spent years building equity in your home. If you need the money,
        why not use it? Take advantage of your hard work. But make sure your
        plans involve life-changing improvements, such as eliminating debt,
        rather than temporary pleasures like a holiday cruise.
      </p>
      <p>
        Before you apply for a loan, take time to review your finances. For
        example, get a free credit report to see what your credit score is.
      </p>
      <p>
        After reviewing your credit report, you might decide to pay down some of
        your debt. Plus, you may need to correct errors on your report before
        applying.
      </p>
    </BlogTemplate>
  );
};

export default WhatIsHELOC;
