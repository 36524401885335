import { Box, Typography, useTheme } from "@mui/material";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";

const ArchivedBox: React.FC = () => {
  const theme = useTheme();
  const { privateLabel } = usePrivateLabel();
  return (
    <Box
      borderRadius={10}
      bgcolor={theme.palette.background.paper}
      px={{ xs: 2, md: 6 }}
      py={{ xs: 4, md: 5 }}
      mt={1}
    >
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={{ xs: "start", md: "center" }}
        flexDirection={{ xs: "column", md: "row" }}
        mt={2}
      >
        <Box>
          <Typography variant="h5" sx={{ fontFamily: "Pro Text Semibold" }}>
            Your loan is currently archived.
          </Typography>
          <Typography
            mt={2}
            variant="subtitle1"
            fontWeight={600}
            color={theme.palette.text.secondary}
            sx={{ fontFamily: "Pro Text Light" }}
          >
            Your account is currently in an archived status. Should you wish to
            change this, please reach out to{" "}
            <a
              style={{
                cursor: "pointer",
                color: theme.palette.primary.main,
                textDecoration: "none",
              }}
              href={`mailto:${
                privateLabel?.contactEmail ?? "support@nftydoor.com"
              }`}
            >
              {privateLabel?.contactEmail ?? "support@nftydoor.com"}
            </a>{" "}
            or call us at{" "}
            <a
              style={{
                cursor: "pointer",
                color: theme.palette.primary.main,
                textDecoration: "none",
              }}
              href={`tel:+18449755150`}
            >
              844-975-5150.
            </a>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ArchivedBox;
