import { Box } from "@mui/material";
import { HBSeparate } from "components/HBSeparate";
import { Link, LinkProps, useLocation } from "react-router-dom";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";

export interface CHBLinkProps extends LinkProps {
  children: React.ReactNode;
  separate?: boolean;
  isFooter?: boolean;
}

export const CHBLink = ({
  children,
  separate,
  isFooter = false,
  ...props
}: CHBLinkProps) => {
  const location = useLocation();
  const pathName = location.pathname;
  const isFaqOrResources =
    pathName.includes("/faq") || pathName.includes("/resources");
  const isActive =
    props.to === "/" ? pathName === "/" : pathName.includes(props.to as string);
  const { primaryText, secondaryText } = useHomeBridgeColors();

  return (
    <Box display={"flex"} alignItems={"center"} className="chb-link">
      <Link
        className="chb-link__link"
        style={{
          fontWeight: isActive ? "bold" : "normal",
          color: isFaqOrResources && !isFooter ? secondaryText : primaryText,
          fontSize: "18px",
          textDecoration: "none",
          textTransform: "uppercase",
          borderRadius: "5px",
        }}
        {...props}
      >
        {children}
      </Link>
      {separate && <HBSeparate />}
    </Box>
  );
};
