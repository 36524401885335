import moment from "moment";
const MONTHS_TO_ADD_MATURITY = 10;

const checkTenMonthsMaturity = ({
  isInstallment,
  isOpen,
  terms,
  openDate,
  isSubjectProperty,
}: {
  isInstallment: boolean;
  isOpen: boolean;
  terms: string;
  openDate: string;
  isSubjectProperty: boolean;
}) => {
  const unknownTerm = terms === "UNK";

  if (
    isInstallment &&
    isOpen &&
    openDate &&
    !unknownTerm &&
    !isSubjectProperty
  ) {
    const termsParsed = Number(terms);
    const openDatePlusTerms = moment(openDate, "MMDDYYYY")
      .clone()
      .add(termsParsed, "months")
      .startOf("month");

    const currentMonthPlus10 = moment()
      .clone()
      .add(MONTHS_TO_ADD_MATURITY, "months")
      .startOf("month");

    return moment(openDatePlusTerms).isBefore(currentMonthPlus10);
  } else return false;
};
export default checkTenMonthsMaturity;
