import { Typography } from "@mui/material";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";
import { useHomebridgeSizes } from "utils/useHomebridgeSizes";

interface CHBTextFieldProps {
  id: string;
  label: string;
  value: string | undefined;
  placeholder?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  hasError?: boolean;
  animation?: boolean;
  customWidth?: string | number;
  type?: string;
  disabledInput?: boolean;
}

const CHBTextField = ({
  id,
  label,
  value,
  placeholder,
  onChange,
  error,
  hasError,
  animation,
  customWidth = "100%",
  type = "text",
  disabledInput,
}: CHBTextFieldProps) => {
  const { primaryText } = useHomeBridgeColors();
  const { sizes } = useHomebridgeSizes();
  return (
    <div style={{ width: customWidth }}>
      <Typography
        variant="body2"
        fontFamily={"NunitoSans"}
        fontSize={sizes.typography}
        marginBottom={"10px"}
        className="custom-typography"
        fontWeight={"bold"}
        color={primaryText}
      >
        {label}
      </Typography>
      <input
        disabled={disabledInput}
        value={value}
        id={id}
        type={type}
        onChange={onChange}
        placeholder={placeholder}
        style={{
          border: hasError ? "2px solid red" : `0px`,
          animation: animation && hasError ? "shake 0.3s ease-in-out" : "none",
        }}
      />
      {hasError && (
        <Typography
          variant="body2"
          fontFamily={"NunitoSans"}
          fontSize={"16px"}
          color={"error"}
          textAlign={"left"}
          marginTop={"5px"}
          className="custom-typography"
          sx={{
            animation: animation ? "shake 0.3s ease-in-out" : "none",
          }}
        >
          {error}
        </Typography>
      )}
    </div>
  );
};

export default CHBTextField;
