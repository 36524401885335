import { Box, Grid, Typography } from "@mui/material";
import { FormData } from "../../useGetStarted";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";
import { CHBButton } from "components/CHBButton";
import { HBSeparate } from "components/HBSeparate";
import { CustomInput } from "../LoanRequest/CustomInput";
import NumberFormat from "react-number-format";
import usePersonalDataValidation from "./usePersonalDataValidation";
import CHBTextField from "components/CHBTextField";
import { useHomebridgeSizes } from "utils/useHomebridgeSizes";
import { useUpdateLoanStatus } from "../../useUpdateLoanStatus";
import { useEffect, useRef, useState } from "react";
import CheckboxInput from "components/CheckboxInput";
import { CountryPhoneCode } from "utils/enumCountryPhoneCode";

export interface BorrowerPersonalDataProps {
  onNext: () => void;
  onPrev: () => void;
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}

type CountryCodeType =
  (typeof CountryPhoneCode)[keyof typeof CountryPhoneCode]["code"];

export const BorrowerPersonalData = ({
  onNext,
  onPrev,
  formData,
  setFormData,
}: BorrowerPersonalDataProps) => {
  const { primaryText, secondaryText, fourthText } = useHomeBridgeColors();
  const { sizes } = useHomebridgeSizes();
  const {
    error,
    errors,
    animation,
    handleNext,
    onValueChange,
    validateInputs,
  } = usePersonalDataValidation(formData, setFormData, onNext);
  const { updateLoanStatus } = useUpdateLoanStatus();
  const [countryCode, setCountryCode] = useState<CountryCodeType>(
    Object.values(CountryPhoneCode)[0].code,
  );

  useEffect(() => {
    if (!loadedPhoneNumber.current) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        phoneNumber: `${prevFormData.phoneNumber.replace(
          countryCode || "",
          "",
        )}`,
      }));
      loadedPhoneNumber.current = true;
    }
  }, [countryCode, setFormData]);

  const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newCountryCode = event.target.value as CountryCodeType;
    setCountryCode(newCountryCode);
    setFormData((prevFormData) => ({
      ...prevFormData,
      phoneNumber: `${newCountryCode}${prevFormData.phoneNumber.replace(
        countryCode || "",
        "",
      )}`,
    }));
  };

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newPhoneNumber = event.target.value;
    const field = "phoneNumber";
    const value = `${countryCode}${newPhoneNumber.trim()}`;
    setFormData((prevFormData) => ({
      ...prevFormData,
      phoneNumber: value,
    }));
    validateInputs({ field, value });
  };

  const handleCanSendSMSChange = () => {
    setFormData({
      ...formData,
      canSendSMS: !formData.canSendSMS,
    });
  };

  const loadedStatus = useRef(false);
  const loadedPhoneNumber = useRef(false);

  useEffect(() => {
    if (
      !loadedStatus.current &&
      formData?.loanId &&
      formData?.typeOfOwnershipType
    ) {
      const statusCode = "DTC008";
      updateLoanStatus({
        loanId: formData.loanId,
        status: statusCode,
        body: {
          typeOfOwnershipType: formData.typeOfOwnershipType,
        },
      });
      loadedStatus.current = true;
    }
  }, [updateLoanStatus, formData?.loanId, formData?.typeOfOwnershipType]);

  return (
    <Box className="get-started__borrower-personal-data">
      <div className="get-started__wrapper">
        <Box
          width={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
          display={"flex"}
          flexDirection={"column"}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "100%",
              margin: "0 auto",
              padding: 2,
              color: secondaryText,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <CHBTextField
                  id="firstName"
                  label="First Name:"
                  disabledInput={true}
                  value={formData.firstName}
                  onChange={onValueChange}
                  error={
                    errors.find((err) => err.field === "firstName")?.message
                  }
                  hasError={Boolean(
                    errors.find((err) => err.field === "firstName"),
                  )}
                  animation={animation}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CHBTextField
                  id="lastName"
                  label="Last Name:"
                  value={formData.lastName}
                  onChange={onValueChange}
                  error={
                    errors.find((err) => err.field === "lastName")?.message
                  }
                  hasError={Boolean(
                    errors.find((err) => err.field === "lastName"),
                  )}
                  disabledInput={true}
                  animation={animation}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CHBTextField
                  id="middleInitial"
                  label="Middle Initial:"
                  value={formData.middleName}
                  onChange={onValueChange}
                  error={
                    errors.find((err) => err.field === "middleName")?.message
                  }
                  hasError={Boolean(
                    errors.find((err) => err.field === "middleName"),
                  )}
                  animation={animation}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CHBTextField
                  id="suffix"
                  label="Suffix:"
                  value={formData.suffix}
                  onChange={onValueChange}
                  error={errors.find((err) => err.field === "suffix")?.message}
                  hasError={Boolean(
                    errors.find((err) => err.field === "suffix"),
                  )}
                  animation={animation}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography
                  variant="body2"
                  fontFamily={"NunitoSans"}
                  fontSize={sizes.typography}
                  marginBottom={"10px"}
                  className="custom-typography"
                  fontWeight={"bold"}
                  color={primaryText}
                >
                  Marital Status:
                </Typography>
                <select
                  id={"maritalStatus"}
                  onChange={onValueChange}
                  value={formData.maritalStatus}
                  style={{
                    border: errors.find((err) => err.field === "maritalStatus")
                      ? "2px solid red"
                      : `2px solid ${fourthText}`,
                    animation:
                      animation &&
                      errors.find((err) => err.field === "maritalStatus")
                        ? "shake 0.3s ease-in-out"
                        : "none",
                  }}
                >
                  <option value="Unmarried">Unmarried</option>
                  <option value="Married">Married</option>
                  <option value="Separated">Separated</option>
                </select>
                <Typography
                  variant="body2"
                  fontFamily={"NunitoSans"}
                  fontSize={"16px"}
                  color={"error"}
                  textAlign={"left"}
                  marginTop={"5px"}
                  className="custom-typography"
                  sx={{
                    animation:
                      animation &&
                      errors.find((err) => err.field === "maritalStatus")
                        ? "shake 0.3s ease-in-out"
                        : "none",
                  }}
                >
                  {error &&
                    errors.find((err) => err.field === "maritalStatus")
                      ?.message}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <CHBTextField
                  id="email"
                  label="Email Address:"
                  value={formData.email}
                  disabledInput={true}
                  onChange={onValueChange}
                  error={errors.find((err) => err.field === "email")?.message}
                  hasError={Boolean(
                    errors.find((err) => err.field === "email"),
                  )}
                  animation={animation}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body2"
                  fontFamily={"NunitoSans"}
                  fontSize={sizes.typography}
                  marginBottom={"10px"}
                  className="custom-typography"
                  fontWeight={"bold"}
                  color={primaryText}
                >
                  Select Country Code:
                </Typography>
                <select
                  id="country-code"
                  onChange={handleCountryChange}
                  value={countryCode}
                  style={{
                    height: "44.8px",
                    width: "100%",
                  }}
                >
                  <option value="" disabled style={{ display: "none" }}>
                    Select a country
                  </option>
                  {Object.entries(CountryPhoneCode).map(
                    ([country, { id, name, code }]) => (
                      <option key={id} value={`${code}`}>
                        {name} ({code})
                      </option>
                    ),
                  )}
                </select>
                <Typography
                  variant="body2"
                  fontFamily={"NunitoSans"}
                  fontSize={"16px"}
                  color={"error"}
                  textAlign={"left"}
                  marginTop={"5px"}
                  className="custom-typography"
                ></Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body2"
                  fontFamily={"NunitoSans"}
                  fontSize={sizes.typography}
                  fontWeight={"bold"}
                  color={primaryText}
                  marginBottom={"10px"}
                  className="custom-typography"
                >
                  Mobile Phone:
                </Typography>
                <NumberFormat
                  className="get-started__phoneInput"
                  min={1}
                  id={"phoneNumber"}
                  name={"phoneNumber"}
                  onChange={handlePhoneNumberChange}
                  value={formData.phoneNumber.replace(countryCode || "", "")}
                  format={
                    process.env.REACT_APP_ENV === "prod"
                      ? "1###############"
                      : "###############"
                  }
                  allowEmptyFormatting
                  style={{
                    borderRadius: "5px",
                    border: errors.find((err) => err.field === "phoneNumber")
                      ? "2px solid red"
                      : `0px solid ${fourthText}`,
                    animation:
                      animation &&
                      errors.find((err) => err.field === "phoneNumber")
                        ? "shake 0.3s ease-in-out"
                        : "none",
                  }}
                  customInput={CustomInput}
                />
                <Typography
                  variant="body2"
                  fontFamily={"NunitoSans"}
                  fontSize={"16px"}
                  color={"error"}
                  textAlign={"left"}
                  marginTop={"5px"}
                  className="custom-typography"
                  sx={{
                    animation:
                      animation &&
                      errors.find((err) => err.field === "phoneNumber")
                        ? "shake 0.3s ease-in-out"
                        : "none",
                  }}
                >
                  {errors.find((err) => err.field === "phoneNumber")?.message}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12}>
                <CheckboxInput
                  name="canSendSMS"
                  label="By clicking Next, you are providing express written consent for Homebridge, its agents, affiliates, and
                      service providers to contact you for telemarketing and informational purposes at the telephone number(s)
                      provided including via text messages, and/or emails, automatic telephone dialing system, artificial voice, or
                      pre-recorded messages, even if your telephone number is registered on an internal, corporate, state, or
                      national Do Not Call registry. Homebriege does not share your opt-in to receive these communications with
                      any third parties for their own marketing purposes. You understand the frequency of these communications
                      may vary. You also agree that you are the subscriber/owner of the device's account or have been granted
                      the authority by the subscriber/owner of said device to consent to such contact. Consent is not required as
                      a condition of purchasing or obtaining products or services."
                  state={formData.canSendSMS}
                  sizesLabel={sizes.typography.md}
                  alignStart={true}
                  onChange={handleCanSendSMSChange}
                  hasError={!formData.canSendSMS && error}
                  animation={true}
                />
              </Grid>
            </Grid>
          </Box>
          <p className="get-started__note">
            <strong className="strong-700">Why do we need your number? </strong>
            So we can send you SMS text alerts and follow up if we need
            additional information.
          </p>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column-reverse",
                sm: "row",
              },
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
              marginTop: "40px",
            }}
          >
            <CHBButton onClick={onPrev} secondaryArrow={true}>
              Go Back
            </CHBButton>
            <CHBButton onClick={handleNext} className="animated-arrow-button">
              Next
            </CHBButton>
          </Box>
        </Box>
        <HBSeparate className="step-separate" type="vertical" />
      </div>
    </Box>
  );
};
