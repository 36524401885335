export const TuitionAndStudentLoans = () => {
  return (
    <svg
      width="108"
      height="76"
      viewBox="0 0 108 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M106.335 23.6988L55.158 0.251159C54.4239 -0.0850217 53.5799 -0.0850217 52.8457 0.251159L1.66893 23.6988C0.654706 24.1625 0 25.1942 0 26.3303C0 27.4663 0.654706 28.4981 1.66893 28.9618L4.54888 30.2794V48.9239C1.95276 50.0523 0.128671 52.6799 0.128671 55.7364C0.128671 57.8346 0.99152 59.7281 2.37284 61.0767L0.177868 72.5648C0.0151377 73.411 0.23085 74.2804 0.772023 74.9489C1.30941 75.6174 2.10414 76 2.95185 76H11.7923C12.64 76 13.4347 75.6174 13.9721 74.9489C14.5095 74.2804 14.7252 73.411 14.5625 72.5648L12.3713 61.0767C13.7488 59.7281 14.6155 57.8346 14.6155 55.7364C14.6155 52.6799 12.7914 50.0523 10.1952 48.9239V32.8684L21.1209 37.8725V54.5578C21.1209 55.3848 21.4691 56.1731 22.0784 56.7218C30.8809 64.6471 42.2191 69.0098 54 69.0098C65.7809 69.0098 77.1191 64.6471 85.9216 56.7218C86.5272 56.1731 86.8791 55.3848 86.8791 54.5578V37.8725L106.335 28.9579C107.349 28.4942 108.004 27.4586 108.004 26.3264C108.004 25.1942 107.349 24.1586 106.335 23.6949V23.6988ZM81.229 53.2324C73.6071 59.6933 63.9682 63.2444 54 63.2444C44.0318 63.2444 34.3929 59.6933 26.771 53.2324V40.4653L52.842 52.4094C53.2091 52.5755 53.5989 52.6606 54 52.6606C54.4011 52.6606 54.7909 52.5755 55.158 52.4094L81.229 40.4653V53.2324ZM54 46.6132L9.72598 26.3303L54 6.04738L98.274 26.3303L54 46.6132ZM6.38055 70.2308L7.37207 65.0297L8.36359 70.2308H6.38055ZM8.96909 55.7403C8.96909 56.6368 8.25384 57.3671 7.37585 57.3671C6.49786 57.3671 5.78261 56.6368 5.78261 55.7403C5.78261 54.8438 6.49786 54.1135 7.37585 54.1135C8.25384 54.1135 8.96909 54.8438 8.96909 55.7403Z"
        fill="#1E9FDA"
      />
    </svg>
  );
};
