export const HBLogo = () => {
  return (
    <svg
      width="56"
      height="67"
      viewBox="0 0 56 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12003_837)">
        <path
          d="M55.6465 36.6392V67H41.2057V41.7L28 31.392L14.7943 41.7V67H0V37.1634L27.9389 15.3992L55.6465 36.6392Z"
          fill="#0F2939"
        />
        <path
          d="M56 0V29.3122L51.1034 25.9629L40.8566 17.973V0.524284L41.3846 0H56Z"
          fill="#0F2939"
        />
        <path
          d="M14.7943 0V18.4929C14.4539 18.4929 14.192 18.6402 13.9258 18.8396C9.66646 22.0676 5.39401 25.7376 1.05175 28.7836C0.706983 29.0263 0.637157 29.4812 0 29.3122V0H14.7943Z"
          fill="#0F2939"
        />
      </g>
      <defs>
        <clipPath id="clip0_12003_837">
          <rect width="56" height="67" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
