import { Dayjs } from "dayjs";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { PickersDay, DatePicker } from "@mui/x-date-pickers";

interface CHBDatePickerProps {
  id: string;
  label: string;
  value: Dayjs | "";
  onChange: (value: unknown) => void;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  format?: string;
  error?: string;
  hasError?: boolean;
  animation?: boolean;
  className?: string;
  isMobile?: boolean;
}

const CHBDatePicker = ({
  id,
  label,
  value,
  onChange,
  minDate,
  maxDate,
  format = "MM-DD-YYYY",
  error,
  hasError,
  animation,
  className,
  isMobile,
  ...rest
}: CHBDatePickerProps) => {
  const { primaryText, secondaryText, tertiaryText, fourthText } =
    useHomeBridgeColors();
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        {...rest}
        value={value}
        label={label}
        onChange={(value) => onChange(value)}
        minDate={minDate}
        maxDate={maxDate}
        format={format}
        className={className}
        slots={{
          day: PickersDay,
        }}
        sx={{
          gap: "0px",
          "& .MuiInputBase-root .MuiOutlinedInput-root": {
            width: "100%",
          },
          "& .MuiOutlinedInput-root": {
            backgroundColor: fourthText,
            color: secondaryText,
            borderRadius: "5px",
            border: hasError ? "2px solid red" : `0px`,
            animation:
              animation && hasError ? "shake 0.3s ease-in-out" : "none",
            "& fieldset": {
              border: "none",
            },
            "&:hover fieldset": {
              border: "none",
            },
            "&.Mui-focused fieldset": {
              border: "none",
            },
          },
          "& .MuiPickersCalendarHeader-labelContainer": {
            color: "black",
          },
        }}
        slotProps={{
          textField: {
            id: "dateOfBirth",
            fullWidth: true,
            InputLabelProps: {
              sx: {
                color: `${primaryText} !important`,
                marginBottom: "10px",
              },
            },
            FormHelperTextProps: {
              sx: {
                color: "error.main",
                margin: "5px 0px 0px 0px !important",
                textAlign: "left",
                fontFamily: "NunitoSans",
                paddingLeft: "3.2px",
                fontSize: "16px",
                animation:
                  animation && hasError ? "shake 0.3s ease-in-out" : "none",
              },
            },
            helperText: hasError ? error : "",
          },
          popper: {
            sx: {
              "& .MuiPaper-root": {
                backgroundColor: fourthText,
                color: secondaryText,
                borderRadius: "8px",
              },
              "& .MuiPickersDay-root": {
                color: secondaryText,
                ...(isMobile
                  ? {}
                  : {
                      "&:hover": {
                        backgroundColor: secondaryText,
                        color: primaryText,
                      },
                    }),
                "&.Mui-selected": {
                  backgroundColor: tertiaryText,
                  color: primaryText,
                },
              },
              "& .MuiYearCalendar-root .MuiPickersYear-root button": {
                color: secondaryText,
                ...(isMobile
                  ? {}
                  : {
                      "&:hover": {
                        backgroundColor: secondaryText,
                        color: primaryText,
                      },
                    }),
                "&.Mui-selected": {
                  backgroundColor: tertiaryText,
                  color: primaryText,
                },
              },
              "& .MuiDayCalendar-weekDayLabel": {
                color: secondaryText,
              },
              "& .MuiPickersCalendarHeader-root": {
                color: secondaryText,
              },
              "& .MuiIconButton-root": {
                color: secondaryText,
              },
              "& .MuiPickersCalendarHeader-labelContainer": {
                color: "black",
              },
            },
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default CHBDatePicker;
