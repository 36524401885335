import { useHomeBridgeColors } from "utils/useHomeBridgeColors";
import CNumberField from "components/CNumberField";

interface CHBSsnInputProps {
  id: string;
  label: string;
  className?: string;
  value: string | undefined;
  error?: string;
  hasError?: boolean;
  animation?: boolean;
  onchange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  format?: string;
  placeholder?: string;
}

const CHBSsnInput = ({
  id,
  label,
  className,
  value,
  error,
  hasError,
  animation,
  format,
  placeholder,
  onchange,
}: CHBSsnInputProps) => {
  const { primaryText, secondaryText, fourthText } = useHomeBridgeColors();

  return (
    <CNumberField
      label={label}
      className={className}
      fullWidth
      id={id}
      name={id}
      InputLabelProps={{
        sx: {
          color: `${primaryText} !important`,
          marginBottom: "10px",
          transition: "none",
        },
      }}
      FormHelperTextProps={{
        sx: {
          color: "error.main",
          marginTop: "5px",
          fontFamily: "NunitoSans",
          fontSize: "16px",
          paddingLeft: "3.2px",
          animation: animation && hasError ? "shake 0.3s ease-in-out" : "none",
        },
      }}
      sx={{
        width: "100%",
        display: "block",
        color: secondaryText,
        "& .MuiOutlinedInput-root": {
          transition: "none",
          backgroundColor: fourthText,
          color: secondaryText,
          borderRadius: "5px",
          "& fieldset": {
            border: "none",
          },
          "&:hover fieldset": {
            border: "none",
          },
          "&.Mui-focused fieldset": {
            border: "none",
          },
          border:
            error && hasError ? "2px solid red" : `0px solid ${fourthText}`,
          animation: animation && hasError ? "shake 0.3s ease-in-out" : "none",
        },
      }}
      format={format}
      placeholder={placeholder}
      onChange={onchange}
      value={value}
      error={error}
    />
  );
};

export default CHBSsnInput;
