import { useCallback } from "react";
import API from "utils/API";
interface Body {
  requestedLoanAmount?: number;
  initialDrawAmount?: number;
  incomeSources?: {
    salaryIncome?: number;
    selfEmploymentIncome?: number;
    socialIncome?: number;
    otherIncome?: number;
  };
  loanPurpose?: string;
  typeOfOwnershipType?: string;
  middleName?: string;
  suffix?: string;
  maritalStatus?: string;
  phoneNumber?: string;
}

export const useUpdateLoanStatus = () => {
  const updateLoanStatus = useCallback(
    async (params: { loanId: string; status: string; body?: Body }) => {
      const { loanId, status, body } = params;
      await API.post({
        url: `/save-to-loan/update-loan-status?loanId=${loanId}`,
        data: {
          status: status,
          ...body,
        },
      });
    },
    [],
  );

  return { updateLoanStatus };
};
