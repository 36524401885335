export const CircleNumberThree = () => {
  return (
    <span style={{ width: "50px", height: "50px" }}>
      <svg
        width="50"
        height="50"
        viewBox="0 0 50 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="25" cy="27.5505" r="25" fill="#102939" />
        <path
          d="M24.5859 36.8505C23.6259 36.8505 22.6859 36.7505 21.7659 36.5505C20.8659 36.3705 20.0259 36.1005 19.2459 35.7405C18.4659 35.3805 17.7859 34.9505 17.2059 34.4505L18.4659 31.5105C19.4059 32.2305 20.3659 32.7605 21.3459 33.1005C22.3259 33.4405 23.3659 33.6105 24.4659 33.6105C25.3459 33.6105 26.0859 33.4905 26.6859 33.2505C27.2859 33.0105 27.7359 32.6505 28.0359 32.1705C28.3559 31.6905 28.5159 31.1105 28.5159 30.4305C28.5159 29.4305 28.1759 28.6905 27.4959 28.2105C26.8359 27.7105 25.8659 27.4605 24.5859 27.4605H21.1959V24.2805H24.2259C24.9859 24.2805 25.6359 24.1605 26.1759 23.9205C26.7159 23.6605 27.1359 23.3005 27.4359 22.8405C27.7359 22.3805 27.8859 21.8205 27.8859 21.1605C27.8859 20.2405 27.5759 19.5405 26.9559 19.0605C26.3559 18.5805 25.4759 18.3405 24.3159 18.3405C23.2759 18.3405 22.3059 18.5205 21.4059 18.8805C20.5059 19.2205 19.6459 19.7605 18.8259 20.5005L17.5659 17.6205C18.4059 16.8205 19.4559 16.2005 20.7159 15.7605C21.9759 15.3205 23.2859 15.1005 24.6459 15.1005C26.1059 15.1005 27.3559 15.3305 28.3959 15.7905C29.4359 16.2305 30.2259 16.8705 30.7659 17.7105C31.3259 18.5305 31.6059 19.5305 31.6059 20.7105C31.6059 21.9705 31.2559 23.0605 30.5559 23.9805C29.8559 24.8805 28.8959 25.4805 27.6759 25.7805V25.4205C28.6359 25.5805 29.4559 25.9005 30.1359 26.3805C30.8159 26.8605 31.3359 27.4705 31.6959 28.2105C32.0559 28.9505 32.2359 29.8005 32.2359 30.7605C32.2359 32.0005 31.9259 33.0805 31.3059 34.0005C30.6859 34.9205 29.8059 35.6305 28.6659 36.1305C27.5259 36.6105 26.1659 36.8505 24.5859 36.8505Z"
          fill="white"
        />
      </svg>
    </span>
  );
};
