export const Other = () => {
  return (
    <svg
      width="48"
      height="57"
      viewBox="0 0 48 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="svg-icon other-icon"
    >
      <g clipPath="url(#clip0_12428_376)">
        <path
          d="M23.9042 31.5344C25.4668 31.5344 26.7868 32.4319 26.7868 33.4934C26.7868 34.3382 27.4839 35.025 28.3413 35.025C29.1987 35.025 29.8957 34.3382 29.8957 33.4934C29.8957 31.1738 28.0102 29.2189 25.4586 28.6435V28.1492C25.4586 27.3044 24.7616 26.6177 23.9042 26.6177C23.0468 26.6177 22.3497 27.3044 22.3497 28.1492V28.6435C19.7981 29.2189 17.9126 31.1738 17.9126 33.4934C17.9126 36.2628 20.6 38.5155 23.9042 38.5155C25.4668 38.5155 26.7868 39.413 26.7868 40.4745C26.7868 41.5361 25.4668 42.4336 23.9042 42.4336C22.3415 42.4336 21.0215 41.5361 21.0215 40.4745C21.0215 39.6298 20.3244 38.943 19.467 38.943C18.6096 38.943 17.9126 39.6298 17.9126 40.4745C17.9126 42.7942 19.7981 44.7491 22.3497 45.3244V45.8188C22.3497 46.6635 23.0468 47.3503 23.9042 47.3503C24.7616 47.3503 25.4586 46.6635 25.4586 45.8188V45.3244C28.0102 44.7491 29.8957 42.7942 29.8957 40.4745C29.8957 37.7052 27.2084 35.4524 23.9042 35.4524C22.3415 35.4524 21.0215 34.555 21.0215 33.4934C21.0215 32.4319 22.3415 31.5344 23.9042 31.5344Z"
          fill="#1E9FDA"
        />
        <path
          d="M34.3003 18.1033C33.5704 17.6596 32.6123 17.8845 32.162 18.5996C31.944 18.9481 31.8741 19.3593 31.9707 19.7564C32.0653 20.1534 32.3121 20.4918 32.6657 20.7065C38.6552 24.3571 44.6982 32.1789 44.6982 41.425C44.6982 44.2774 43.7791 46.6274 41.9635 48.4061C37.1933 53.0878 27.5151 53.0453 24.3343 53.0291H23.8984H23.4686C20.2878 53.0433 10.6096 53.0838 5.84146 48.4061C4.02796 46.6253 3.10681 44.2774 3.10681 41.425C3.10681 32.1789 9.14977 24.3571 15.1413 20.7065C15.8692 20.2628 16.0954 19.3168 15.6451 18.5996C15.1948 17.8804 14.2346 17.6576 13.5047 18.1013C7.27664 21.9018 0 30.2888 0 41.427C0 45.1242 1.22751 48.2035 3.64963 50.5798C9.32454 56.1489 19.9773 56.1084 23.483 56.0942H23.9025H24.3302C24.5214 56.0942 24.7352 56.0942 24.9696 56.0942C33.9734 56.0942 40.4297 54.2385 44.1595 50.5778C46.5816 48.2015 47.8091 45.1221 47.8091 41.425C47.8091 30.2868 40.5325 21.8997 34.3003 18.1013V18.1033Z"
          fill="#1E9FDA"
        />
        <path
          d="M17.9356 13.5853C18.2049 14.0917 18.7334 14.4057 19.3132 14.4057C19.564 14.4057 19.8128 14.345 20.0349 14.2315C20.7936 13.8405 21.0876 12.9127 20.6908 12.1651L16.7554 4.76469C16.6094 4.45675 16.5827 4.12654 16.6814 3.87736C16.7739 3.64439 16.9384 3.38102 17.7053 3.19262C19.0089 2.87051 20.4358 3.17236 21.6202 4.02727C22.9711 4.99158 24.8524 4.98956 26.191 4.02322C27.3547 3.18452 28.8187 2.87456 30.1079 3.19464C30.8707 3.38102 31.0331 3.64439 31.1236 3.87128C31.2244 4.12451 31.2017 4.45068 31.064 4.74443L27.1183 12.1672C26.7214 12.9147 27.0155 13.8426 27.7742 14.2335C28.0003 14.351 28.2471 14.4098 28.4938 14.4098C28.6501 14.4098 28.8063 14.3855 28.9585 14.3389C29.3553 14.2173 29.6802 13.9499 29.8714 13.5873L33.8377 6.12606L33.8541 6.09567C34.3723 5.02197 34.434 3.80645 34.0186 2.75706C33.5025 1.46254 32.4395 0.611675 30.8625 0.224736C28.6891 -0.316168 26.2567 0.182193 24.3548 1.5537C24.0937 1.7421 23.7113 1.74008 23.4481 1.5537C21.5215 0.157883 19.1508 -0.326297 16.9507 0.218659C15.3695 0.605598 14.3065 1.45848 13.7884 2.75098C13.371 3.7943 13.4347 5.01792 13.9652 6.11188L17.9356 13.5832V13.5853Z"
          fill="#1E9FDA"
        />
        <path
          d="M31.4096 16.2615C31.4096 15.4167 30.7126 14.73 29.8552 14.73C29.2445 14.73 28.7079 15.0784 28.455 15.595C27.9821 15.7936 26.5304 16.2028 23.9048 16.2028C21.2791 16.2028 19.8254 15.7936 19.3525 15.595C19.0996 15.0805 18.5629 14.73 17.9543 14.73C17.0969 14.73 16.3999 15.4167 16.3999 16.2615C16.3999 17.6229 17.6788 18.5325 20.2037 18.9681C21.8466 19.2517 23.5758 19.2659 23.9048 19.2659C24.2338 19.2659 25.965 19.2517 27.6058 18.9681C30.1287 18.5325 31.4096 17.6229 31.4096 16.2615Z"
          fill="#1E9FDA"
        />
      </g>
      <defs>
        <clipPath id="clip0_12428_376">
          <rect width="47.8091" height="56.096" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
