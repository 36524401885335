export const useHomeBridgeColors = () => {
  return {
    primaryText: "#FFFFFF",
    secondaryText: "#102939", // main
    tertiaryText: "#20A2D8", // secondary
    fourthText: "#D3E1EA",
    primaryBackground: "#102939",
    secondaryBackground: "#D9D9D9",
    tertiaryBackground: "#ECECEC",
  };
};
