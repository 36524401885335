import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import IconSelected from "./icons";
import SpringModal from "./Modal";
import IconButtons from "./IconsTooltip";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loan from "typedef/Loan";
import useUserCustomerContext from "context/UserCustomer/useUserCustomerContext";
import API from "utils/API";
import moment from "moment";
import dollarFormatter from "utils/FormatterDollar";
import getRedirectMessage from "./getRedirectMessage";

const getLastLoanCreated = (loans: LoansListProps[]): LoansListProps | null => {
  if (loans.length === 0) {
    return null;
  }

  const now = moment();

  return loans.reduce((closestLoan, currentLoan) => {
    const closestDiff = Math.abs(now.diff(moment(closestLoan.createdAt)));
    const currentDiff = Math.abs(now.diff(moment(currentLoan.createdAt)));

    return currentDiff < closestDiff ? currentLoan : closestLoan;
  }, loans[0]);
};

export interface LoansListProps {
  address: string;
  finalOffer: Loan["finalOffer"];
  id: string;
  coborrowerInviteCode: string;
  borrowerSteps: Loan["borrowerSteps"];
  coborrowerSteps: Loan["coborrowerSteps"];
  initialOffer: Loan["initialOffer"];
  loanOfficerId: string | null;
  loanOfficerName: string;
  newLoanStatusCode: string;
  stepColor: string;
  stepName: string;
  isArchived: boolean;
  isFunded: boolean;
  borrowerFlags: Loan["borrowerFlags"];
  borrowerId: string;
  coborrowerId?: string;
  status: string;
  createdAt: number;
  submittedByLO?: boolean;
}

const MIN_TABLE_WIDTH = 300;
const INITIAL_TABLE_WIDTH = 1000;

const UserTable: React.FC<{
  isAdmin?: boolean;
  setLoanOfficerIdLastLoan: (value: string) => void;
}> = ({ isAdmin, setLoanOfficerIdLastLoan }) => {
  const [tableWidth, setTableWidth] = useState(INITIAL_TABLE_WIDTH);
  const params = useParams();
  const resizeRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const isBorrowerType = params?.borrowerType === "borrower";
  const { setSelectedLoanId, handleSelectLoan, user } =
    useUserCustomerContext();
  const currentAdminLoanId = params?.loanId;
  const [loansList, setLoansList] = useState<LoansListProps[]>();
  const location = useLocation();
  const navigate = useNavigate();
  const [loanDetailsSelected, setLoanDetailsSelected] =
    useState<LoansListProps>();

  useEffect(() => {
    const resizeButton = resizeRef.current;
    if (!resizeButton) return;

    let startX: number;
    let startWidth: number;

    const onMouseMove = (e: MouseEvent) => {
      const dx = e.clientX - startX;
      setTableWidth(Math.max(MIN_TABLE_WIDTH, startWidth + dx));
    };

    const onMouseUp = () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    const onMouseDown = (e: MouseEvent) => {
      startX = e.clientX;
      startWidth = tableWidth;
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    };

    resizeButton.addEventListener("mousedown", onMouseDown);

    return () => {
      resizeButton.removeEventListener("mousedown", onMouseDown);
    };
  }, [tableWidth]);

  useEffect(() => {
    setSelectedLoanId("");
    const getAllMyLoans = async () => {
      const getMyLoanResponse =
        isAdmin && currentAdminLoanId
          ? await API.post<LoansListProps[]>({
              url: `/admin-impersonate/paginate/all-user-loans`,
              data: {
                loanId: currentAdminLoanId,
                isBorrower: isBorrowerType,
              },
            })
          : await API.get<LoansListProps[]>(`/get/my-loans`);
      if ("error" in getMyLoanResponse) {
        alert(getMyLoanResponse.error);
      } else if (getMyLoanResponse?.data) {
        const myLoans = getMyLoanResponse.data;
        setLoansList(myLoans);

        const lastLoanCreated = getLastLoanCreated(myLoans);

        if (lastLoanCreated?.loanOfficerId) {
          setLoanOfficerIdLastLoan(lastLoanCreated?.loanOfficerId);
        }
      }
    };
    getAllMyLoans();
  }, [
    setSelectedLoanId,
    isAdmin,
    currentAdminLoanId,
    isBorrowerType,
    setLoanOfficerIdLastLoan,
  ]);

  const handleNavigateToLoan = async (loanUser: LoansListProps) => {
    if (isAdmin) {
      const userOnSelectedLoan = loansList?.find(
        (loan) => loan.id === currentAdminLoanId,
      );

      await handleSelectLoan(loanUser.id, true);

      const userId = isBorrowerType
        ? userOnSelectedLoan?.borrowerId
        : userOnSelectedLoan?.coborrowerId;

      const isBorrower = userId === loanUser?.borrowerId;

      const searchParams = new URLSearchParams(location.search);
      searchParams.delete("allLoans");

      navigate(
        `/impersonate/${isBorrower ? "borrower" : "coborrower"}/${
          loanUser.id
        }?${searchParams.toString()}`,
      );
    } else {
      if (loanUser?.submittedByLO === false) {
        navigate(`/submitted?mloid=${loanUser?.loanOfficerId}`);
      } else {
        await handleSelectLoan(loanUser.id);
        const isCoborrowerLogged = user?.id === loanUser?.coborrowerId;
        const homeMonitorValidations = loanUser.isArchived || loanUser.isFunded;

        if (
          !loanUser.borrowerFlags?.initialOfferAccepted &&
          !isCoborrowerLogged &&
          !homeMonitorValidations
        ) {
          navigate(`/type-of-credit-line?loanId=${loanUser.id}`);
        } else if (homeMonitorValidations) {
          navigate(`/home-monitor?loanId=${loanUser.id}`);
        } else if (
          isCoborrowerLogged &&
          loanUser?.coborrowerInviteCode &&
          !loanUser?.coborrowerSteps
        ) {
          navigate(`/coborrower-signup?code=${loanUser?.coborrowerInviteCode}`);
        } else {
          navigate(
            `/${
              isCoborrowerLogged ? "coborrower" : "borrower"
            }-tracker?loanId=${loanUser.id}`,
          );
        }
      }
    }
  };

  const handleOpenModal = (loan: LoansListProps) => {
    setLoanDetailsSelected(loan);
    setIsOpen(true);
  };

  return (
    <>
      <div
        id="right_panel"
        style={{ width: `${tableWidth}px`, maxWidth: "100%" }}
      >
        <div className="wrap-table">
          <table>
            <thead>
              <tr>
                <th className="sticky-col">Address</th>
                <th>Status</th>
                <th>Loan amount</th>
                <th>Loan officer</th>
                <th>Date</th>
                <th className="sticky-col sticky-col-right">Actions</th>
              </tr>
            </thead>
            <tbody>
              {loansList?.map((loan, index) => (
                <motion.tr
                  data-cy="yourLoan"
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  onClick={() => handleOpenModal(loan)}
                >
                  <td className="sticky-col" data-label="Address">
                    <div className="user__info">
                      <div>
                        <div className="user__name">{loan.address}</div>
                      </div>
                    </div>
                  </td>
                  <td data-label="Status">
                    <div
                      data-cy="status"
                      className={`user__badge user__badge--${loan.status
                        ?.toLowerCase()
                        .replace(" ", "-")}`}
                    >
                      <IconSelected
                        type={
                          loan.status?.toLowerCase() as
                            | "pending"
                            | "funded"
                            | "archived"
                        }
                      />{" "}
                      {loan.status?.charAt(0).toUpperCase() +
                        loan.status?.slice(1)}
                    </div>
                  </td>
                  <td data-label="Amount">
                    {dollarFormatter.format(
                      loan?.finalOffer?.amount ?? loan?.initialOffer?.amount,
                    )}
                  </td>
                  <td data-label="Loan officer">
                    <span>
                      {loan?.loanOfficerName === "DTC"
                        ? "-"
                        : loan?.loanOfficerName ?? "-"}
                    </span>
                  </td>
                  <td data-label="Date">
                    {loan?.createdAt
                      ? moment.unix(Number(loan.createdAt)).format("MM/DD/YYYY")
                      : "-"}
                  </td>
                  <td
                    className="sticky-col sticky-col-right"
                    data-label="Actions"
                  >
                    <IconButtons
                      handleOpen={() => handleOpenModal(loan)}
                      handleNavigate={() => handleNavigateToLoan(loan)}
                      redirectText={getRedirectMessage({
                        loan,
                        isBorrower: loan.borrowerId === user?.id,
                      })}
                    />
                  </td>
                </motion.tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <SpringModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        loan={loanDetailsSelected}
        handleNavigate={() =>
          loanDetailsSelected && handleNavigateToLoan(loanDetailsSelected)
        }
        user={user}
      />
    </>
  );
};

export default UserTable;
