import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import CBadge from "components/CAvatar/CBadge";
import { useState } from "react";
import { UserType } from "typedef/ApiUserResponses";

interface ProfileProps {
  user?: UserType;
  logout?: () => void;
}

export const Profile = ({ user, logout }: ProfileProps) => {
  const [openMenu, setOpenMenu] = useState<null | HTMLElement>(null);
  return (
    <Box className="profile chb-link">
      <IconButton
        sx={{ ml: 1, mr: 1 }}
        onClick={(event) => setOpenMenu(event.currentTarget)}
        data-cy="userBotton"
      >
        <CBadge
          variant="dot"
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          {!user && <CircularProgress color="primary" />}
          {user && (
            <Avatar
              sx={{
                width: { xs: 45, md: 60 },
                height: { xs: 45, md: 60 },
                bgcolor: "#102939",
                border: "2px solid #20A2D8",
                textTransform: "uppercase",
              }}
            >
              {user.firstName?.[0]} {user.lastName?.[0]}
            </Avatar>
          )}
        </CBadge>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={openMenu}
        open={Boolean(openMenu)}
        onClose={() => setOpenMenu(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem data-cy="logout" onClick={logout}>
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
};
