import { Box } from "@mui/material";
import HBLogo from "../../../../assets/Icons/homebridge_logo.svg";
import HBLogoDark from "../../../../assets/Icons/homebridge_logo_dark.svg";
import { Link, useLocation } from "react-router-dom";

type LogoProps = {
  isFooter?: boolean;
};

export const Logo = ({ isFooter = false }: LogoProps) => {
  const location = useLocation();
  const isFaqOrResources =
    location.pathname.includes("/faq") ||
    location.pathname.includes("/resources");

  return (
    <Box>
      <Link to="/">
        <img
          src={isFaqOrResources && !isFooter ? HBLogoDark : HBLogo}
          alt="Homebridge LOGO"
          width={"100%"}
          height={"100%"}
          data-acsb-force-visible={true}
          aria-hidden={false}
          data-acsb-hidden={false}
        />
      </Link>
    </Box>
  );
};
