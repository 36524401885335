import { Box, Grid, Typography } from "@mui/material";
import { CHBButton } from "components/CHBButton";
import { HBSeparate } from "components/HBSeparate";
import { CSSProperties, useEffect, useRef, useState } from "react";
import { FormData, TypeOfOwnershipType } from "../../useGetStarted";
import { useHomebridgeSizes } from "utils/useHomebridgeSizes";
import { useUpdateLoanStatus } from "../../useUpdateLoanStatus";

export interface TypeOfOwnershipProps {
  onNext: () => void;
  onPrev: () => void;
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}

export const TypeOfOwnership = ({
  onNext,
  onPrev,
  formData,
  setFormData,
}: TypeOfOwnershipProps) => {
  const { sizes } = useHomebridgeSizes();
  const { updateLoanStatus } = useUpdateLoanStatus();

  const loadedStatus = useRef(false);
  const [error, setError] = useState(false);
  const [animation, setAnimation] = useState(false);
  const handleNext = () => {
    if (!formData.typeOfOwnershipType) {
      setAnimation(true);
      setError(true);
      setTimeout(() => {
        setAnimation(false);
      }, 300);
    } else {
      setError(false);
      onNext();
    }
  };

  const handleSelect = (type: TypeOfOwnershipType) => {
    setFormData({ ...formData, typeOfOwnershipType: type });
    setCurrentActive(type);
    setError(false);
  };
  const [currentActive, setCurrentActive] = useState(
    formData.typeOfOwnershipType,
  );

  const descriptionStyles: CSSProperties = {
    textTransform: "capitalize",
    marginTop: "0.45rem",
  };

  useEffect(() => {
    if (!loadedStatus.current && formData?.loanId && formData?.loanPurpose) {
      const statusCode = "DTC007";
      updateLoanStatus({
        loanId: formData.loanId,
        status: statusCode,
        body: {
          loanPurpose: formData.loanPurpose,
        },
      });
      loadedStatus.current = true;
    }
  }, [updateLoanStatus, formData?.loanId, formData?.loanPurpose]);

  return (
    <Box className="get-started__type-of-ownership">
      <div className="get-started__wrapper">
        <Box width={"100%"}>
          <Typography
            variant="h4"
            fontFamily={"NunitoSans"}
            fontSize={sizes.subtitle}
            textAlign={"center"}
            fontWeight={700}
            marginBottom={"20px"}
            marginTop={"10px"}
          >
            What type of ownership do you have?
          </Typography>
        </Box>
      </div>
      <Box>
        <Box sx={{ marginBottom: "20px" }}>
          <Grid
            className="get-started__type-of-ownership__container"
            container
            spacing={2}
            sx={{ justifyContent: "center", textAlign: "-webkit-center" }}
          >
            <Grid item xs={12} sm={6}>
              <CHBButton
                onClick={() => handleSelect("Fee Simple")}
                className={`animated-button ${
                  currentActive === "Fee Simple"
                    ? "animated-button--active"
                    : ""
                }`}
                styleContainer={{
                  height: "100%",
                  width: "100%",
                }}
                style={{
                  border: error ? "2px solid red" : "2px solid #20A2D8",
                  animation: animation ? "shake 0.3s ease-in-out" : "none",
                  height: "100%",
                  padding: "20px",
                }}
              >
                <Typography
                  variant="body2"
                  fontFamily={"NunitoSans"}
                  fontSize={sizes.typography}
                  fontWeight={"bold"}
                  marginBottom={"10px"}
                  className="custom-typography text-black"
                  sx={descriptionStyles}
                >
                  Fee Simple
                </Typography>
              </CHBButton>
            </Grid>

            <Grid item xs={12} sm={6}>
              <CHBButton
                onClick={() => handleSelect("Trust")}
                className={`animated-button ${
                  currentActive === "Trust" ? "animated-button--active" : ""
                }`}
                styleContainer={{
                  height: "100%",
                  width: "100%",
                }}
                style={{
                  border: error ? "2px solid red" : "2px solid #20A2D8",
                  animation: animation ? "shake 0.3s ease-in-out" : "none",
                  height: "100%",
                  padding: "20px",
                }}
              >
                <Typography
                  variant="body2"
                  fontFamily={"NunitoSans"}
                  fontSize={sizes.typography}
                  fontWeight={"bold"}
                  marginBottom={"10px"}
                  className="custom-typography text-black"
                  sx={descriptionStyles}
                >
                  Trust
                </Typography>
              </CHBButton>
            </Grid>

            <Grid item xs={12} sm={6}>
              <CHBButton
                onClick={() => handleSelect("LLC")}
                className={`animated-button ${
                  currentActive === "LLC" ? "animated-button--active" : ""
                }`}
                styleContainer={{
                  height: "100%",
                  width: "100%",
                }}
                style={{
                  border: error ? "2px solid red" : "2px solid #20A2D8",
                  animation: animation ? "shake 0.3s ease-in-out" : "none",
                  height: "100%",
                  padding: "20px",
                }}
              >
                <Typography
                  variant="body2"
                  fontFamily={"NunitoSans"}
                  fontSize={sizes.typography}
                  fontWeight={"bold"}
                  marginBottom={"10px"}
                  className="custom-typography text-black"
                  sx={descriptionStyles}
                >
                  LLC
                </Typography>
              </CHBButton>
            </Grid>
          </Grid>
        </Box>

        <p
          className="get-started__note"
          style={{
            margin: "40px auto",
          }}
        >
          <strong className="strong-700">Not sure?</strong> If you don’t have a
          trust or LLC (limited liability corporation), you probably have a fee
          simple ownership.
        </p>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column-reverse",
              sm: "row",
            },
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            marginTop: "40px",
          }}
        >
          <CHBButton onClick={onPrev} secondaryArrow={true}>
            Go Back
          </CHBButton>
          <CHBButton onClick={handleNext}>Next</CHBButton>
        </Box>
      </Box>
      <HBSeparate className="step-separate" type="vertical" />
    </Box>
  );
};
