import { useCallback, useEffect, useRef, useState } from "react";
import { Ocuppancy } from "context/Invite/useInviteContext";
import Loan from "typedef/Loan";
import { LStoken, LStokenExp } from "CONST";
import DemographicsDataChecks from "typedef/DemographicsDataChecks";
import { StepTitle } from "./StepTitle";
import { useNavigate } from "react-router-dom";
export interface IncomeSources {
  salaryIncome?: number;
  selfEmploymentIncome?: number;
  socialIncome?: number;
  otherIncome?: number;
}

export type LoanPurposeType =
  | "Home Improvement"
  | "Debt Consolidation"
  | "Other";

export type TypeOfOwnershipType = "Fee Simple" | "Trust" | "LLC";
export type TypeOfMaritalStatus = "Unmarried" | "Married" | "Separated";
export interface FormDataNumbers {
  homeValue?: number;
  loanBalance?: number;
  helocBalance?: number;
  judmentsAndLiensBalance?: number;
  creditScore?: number;
  annualIncome?: number;
  requestedLoanAmount?: number;
  initialDrawAmount?: number;
}

export interface FormData extends FormDataNumbers {
  loanId?: string;
  occupancyType?: Ocuppancy;
  loanPurpose?: LoanPurposeType;
  typeOfOwnershipType?: TypeOfOwnershipType;
  street_line: string;
  secondary: string;
  city: string;
  state: string;
  zipcode: string;
  source: string;
  entries?: number;
  countyFips?: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  suffix?: string;
  maritalStatus: TypeOfMaritalStatus;
  email: string;
  phoneNumber: string;
  canSendSMS: boolean;
  incomeSources: IncomeSources;
  password?: string;
  dateOfBirth?: string;
  ssn?: string;
  PrivacyPolicyCheck: boolean;
  BorrowerConsentCheck: boolean;
  demographicsData: DemographicsDataChecks;
}

export interface HELOCOffer {
  amount: number;
  cltv: number;
  estPointsAndFees: number;
  estNetProceeds: number;
  estMonthlyPayment: number;
  estMonthlyCharge?: number;
  maxAmount: number;
  minAmount: number;
  rate: number;
  interestRate: number;
}
export interface OfferResponse {
  items: HELOCOffer[];
  occupancyType: string;
  minimumPercentageInitialDraw: number;
  maximumPercentageInitialDraw: number;
  loanPurpose?: string;
}

export const useGetStarted = (loan?: Loan) => {
  const token = localStorage.getItem(LStoken);
  const tokenEXP = localStorage.getItem(LStokenExp);
  const navigate = useNavigate();
  const [loadingPage, setLoadingPage] = useState(Boolean(token && tokenEXP));
  const [loadingTitle, setLoadingTitle] = useState(Boolean(token && tokenEXP));

  const [globalError, setGlobalError] = useState<string | null>(null);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState<FormData>({
    street_line: "",
    secondary: "",
    city: "",
    state: "",
    zipcode: "",
    entries: 0,
    source: "postal",
    firstName: "",
    lastName: "",
    middleName: "",
    suffix: "",
    maritalStatus: "Unmarried",
    email: "",
    phoneNumber: "",
    canSendSMS: false,
    incomeSources: {
      salaryIncome: undefined,
      selfEmploymentIncome: undefined,
      socialIncome: undefined,
      otherIncome: undefined,
    },
    dateOfBirth: "",
    ssn: "",
    PrivacyPolicyCheck: false,
    BorrowerConsentCheck: false,
    demographicsData: {
      ethnicity: {
        hispanicOrLatino: false,
        mexican: false,
        puertoRican: false,
        cuban: false,
        otherHispanicOrLatino: false,
        customEthnicity: "",
        notHispanicOrLatino: false,
        prefNoSay: false,
      },
      race: {
        americanIndianOrAlaskaNative: false,
        customAmericanIndianOrAlaskan: "",
        asian: false,
        asianIndian: false,
        chinese: false,
        filipino: false,
        japanese: false,
        korean: false,
        vietnamese: false,
        otherAsian: false,
        customOtherAsian: "",
        blackOrAfricanAmerican: false,
        nativeHawaiian: false,
        guamanianOrChamorro: false,
        samoan: false,
        otherPacificIslander: false,
        customOtherPacificIslander: "",
        nativeHawaiianOrOtherPacificIslander: false,
        white: false,
        prefNoSay: false,
      },
      sex: {
        male: false,
        female: false,
        prefNoSay: false,
      },
      citizenship: "",
    },
  });

  const nextStep = () => setStep((prev) => prev + 1);
  const prevStep = () => setStep((prev) => prev - 1);

  const toStep = useCallback((statusCode: string) => {
    const steps: { [key: string]: number } = {
      DTC004: 6,
      DTC006: 7,
      DTC007: 8,
      DTC008: 9,
      DTC009: 10,
      DTC010: 11,
      DTC011: 12,
    };
    setStep(steps[statusCode] ?? 6);
  }, []);

  const handleStepTitle = useCallback(
    (step: number) => {
      const titles: { [key: number]: React.ReactNode } = {
        // Property
        2: (
          <StepTitle
            text="Tell us about this property"
            boldText="hidden"
            boldVisible={false}
          />
        ),
        // Loan Request
        3: (
          <StepTitle
            text="We need a few numbers"
            boldText="hidden"
            boldVisible={false}
          />
        ),
        // Credit Score
        4: (
          <StepTitle
            text="What’s your credit score?"
            boldText="hidden"
            boldVisible={false}
          />
        ),
        // Verify Email
        5: (
          <StepTitle
            boldText="Congrats"
            normalText="— you’re"
            text="pre-qualified!"
          />
        ),
        // Offer
        6: (
          <StepTitle
            boldText={`${formData.firstName},`}
            normalText=""
            text={!globalError ? "choose your offer!" : ""}
          />
        ),
        // Loan Purpose
        7: (
          <StepTitle
            boldText={`${formData.firstName},`}
            normalText=""
            text="how will you use your HELOC?"
          />
        ),
        // TypeOfOwnership
        8: (
          <StepTitle
            text="Almost finished…"
            boldText="hidden"
            boldVisible={false}
          />
        ),
        // BorrowerPersonalData
        9: (
          <StepTitle
            text={formData.firstName}
            normalText="We just need a few more things,"
            boldText=""
            boldVisible={false}
          />
        ),
        // Incomes
        10: (
          <StepTitle
            text="Tell us about your income:"
            boldText="hidden"
            boldVisible={false}
          />
        ),
        // BorrowerDemographicInfo
        11: (
          <StepTitle
            text="We're required to gather this information"
            boldText="hidden"
            boldVisible={false}
          />
        ),
        // BorrowerIdentityData
        12: (
          <StepTitle
            text={`One more thing, ${formData.firstName}`}
            boldText="hidden"
            boldVisible={false}
          />
        ),
      };
      return titles[step];
    },
    [formData.firstName, globalError],
  );

  const handleStepProgressBar = useCallback((step: number) => {
    const titles: { [key: number]: number } = {
      2: 25,
      3: 55,
      4: 85,
      5: 100,
      6: 15,
      7: 30,
      8: 55,
      9: 70,
      10: 80,
      11: 90,
      12: 95,
    };
    return titles[step];
  }, []);

  const loadedRef = useRef(false);

  useEffect(() => {
    if (!loadedRef.current) {
      if (
        loan?.DTCApplication &&
        !loan?.DTCApplication?.socialSecurityNumber &&
        !loan?.DTCApplication?.dateOfBirth
      ) {
        setFormData({
          ...formData,
          loanId: loan.id,
          street_line: loan.DTCApplication?.address?.street_line as string,
          secondary: loan.DTCApplication?.address?.secondary as string,
          city: loan.DTCApplication?.address?.city as string,
          state: loan.DTCApplication?.address?.state as string,
          zipcode: loan.DTCApplication?.address?.zipcode as string,

          email: loan.DTCApplication.email as string,
          creditScore: loan.DTCApplication.creditScore,
          firstName: loan.DTCApplication?.firstName as string,
          lastName: loan.DTCApplication?.lastName as string,
          helocBalance: loan.DTCApplication.helocBalance,
          homeValue: loan.DTCApplication.homeValue,
          judmentsAndLiensBalance: loan.DTCApplication.judmentsAndLiensBalance,
          loanBalance: loan.DTCApplication.loanBalance,
          occupancyType: loan.DTCApplication.occupancyType,
          countyFips: loan.DTCApplication?.address?.countyFips,

          phoneNumber: loan.DTCApplication.phoneNumber ?? formData.phoneNumber,
          requestedLoanAmount:
            loan.DTCApplication.requestedLoanAmount ??
            formData.requestedLoanAmount,
          initialDrawAmount:
            loan.DTCApplication.initialDrawAmount ?? formData.initialDrawAmount,
          maritalStatus:
            loan.DTCApplication.maritalStatus ?? formData.maritalStatus,
          loanPurpose: loan.DTCApplication.loanPurpose ?? formData.loanPurpose,
          typeOfOwnershipType:
            loan.DTCApplication.typeOfOwnershipType ??
            formData.typeOfOwnershipType,
          middleName: loan.DTCApplication.middleName ?? formData.middleName,
          suffix: loan.DTCApplication.suffixName ?? formData.suffix,
          incomeSources:
            loan.DTCApplication.incomeSources ?? formData.incomeSources,
          demographicsData: loan?.borrowerSteps?.demographicsVerification
            ?.citizenship
            ? (loan?.borrowerSteps
                ?.demographicsVerification as DemographicsDataChecks)
            : formData.demographicsData,
        });

        if (step === 1) {
          toStep(loan.newLoanStatusCode ?? "DTC004");
        }

        loadedRef.current = true;
        setLoadingPage(false);
        setLoadingTitle(false);
      } else if (
        loan?.id &&
        loan?.DTCApplication?.socialSecurityNumber &&
        loan?.DTCApplication?.dateOfBirth
      ) {
        navigate(`/borrower-tracker?loanId=${loan?.id}`, { replace: true });
      }
    }
  }, [
    loan?.DTCApplication,
    loan?.newLoanStatusCode,
    step,
    formData,
    loan?.id,
    toStep,
    loan?.borrowerSteps?.demographicsVerification,
    navigate,
  ]);

  return {
    step,
    nextStep,
    prevStep,
    formData,
    setFormData,
    loadingPage,
    setLoadingPage,
    loadingTitle,
    setLoadingTitle,
    handleStepTitle,
    handleStepProgressBar,
    setGlobalError,
  };
};
