import { Box, Typography, Grid } from "@mui/material";
import { useHomebridgeSizes } from "utils/useHomebridgeSizes";
interface HFeatureItemProps {
  title: string;
  subtitle: string;
  description: string;
}

const FeatureItem = ({ title, subtitle, description }: HFeatureItemProps) => {
  const { sizes } = useHomebridgeSizes();
  return (
    <Grid item xs={12} md={4}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{
          width: "100%",
          padding: { xs: "0", md: "0 16px" },
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: "4.5rem", md: "4rem" },
            fontWeight: "bold",
            color: "#1a2b3b",
            mb: -1,
            lineHeight: 1.2,
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontSize: sizes.title,
            fontWeight: "700",
            color: "#1a2b3b",
            mb: 2,
            lineHeight: 1.2,
          }}
        >
          {subtitle}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: sizes.typographyLarge, md: sizes.typography },
            color: "#1a2b3b",
            maxWidth: "270px",
            textAlign: "center",
            lineHeight: 1.5,
            margin: "0 auto",
            width: {
              xs: "70%",
            },
          }}
          fontWeight={500}
        >
          {description}
        </Typography>
      </Box>
    </Grid>
  );
};

export default FeatureItem;
