export const PrimaryResidence = () => {
  return (
    <svg
      className="svg-icon primary-residence-icon"
      width="66"
      height="66"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12428_330)">
        <path
          d="M65.8262 26.419C66.1096 25.522 65.8119 24.5781 65.0594 23.9981L55.1528 16.3337V5.33483C55.1528 3.69581 53.8002 2.36205 52.138 2.36205H47.0061C45.3439 2.36205 43.9913 3.69581 43.9913 5.33483V7.69474L34.8587 0.631451C33.7632 -0.211542 32.2344 -0.211542 31.139 0.631451L0.943027 23.9957C0.188129 24.5781 -0.111925 25.522 0.173841 26.419L0 26.5529H0.219087C0.557243 27.4288 1.37882 27.99 2.33614 27.99H7.7514V60.4299C7.7514 62.13 9.15403 63.5107 10.8758 63.5107H21.3515C22.2135 63.5107 22.9137 62.8204 22.9137 61.9703C22.9137 61.1203 22.2135 60.4299 21.3515 60.4299H10.8734V26.4496C10.8734 25.5995 10.1733 24.9092 9.3112 24.9092H4.8223L32.9988 3.10877L44.589 12.0741C45.0581 12.4357 45.7035 12.5038 46.2369 12.2455C46.7703 11.9895 47.1156 11.4448 47.1156 10.8601V5.44285H52.0284V17.0804C52.0284 17.5571 52.2475 17.9985 52.6262 18.292L61.1753 24.9068H56.6864C55.8244 24.9068 55.1242 25.5972 55.1242 26.4472V38.2092C55.1242 39.0592 55.8244 39.7496 56.6864 39.7496C57.5485 39.7496 58.2486 39.0592 58.2486 38.2092V27.9876H63.6639C64.6212 27.9876 65.4428 27.4264 65.7809 26.5505H66L65.8262 26.4167V26.419Z"
          fill="#1E9FDA"
        />
        <path
          d="M52.6237 23.1953L33.9608 8.75871C33.3917 8.31961 32.5987 8.31961 32.0319 8.75871L23.9471 15.0119C23.2684 15.5355 23.1517 16.5053 23.6804 17.1746C23.9376 17.4986 24.3067 17.7052 24.721 17.7545C25.1354 17.8039 25.545 17.6911 25.8736 17.4375L32.994 11.9287L50.6924 25.6209C50.9663 25.8323 51.3092 25.9497 51.6569 25.9497C52.1403 25.9497 52.588 25.7336 52.8857 25.3603C53.1428 25.0362 53.2571 24.6323 53.2071 24.2261C53.1571 23.8175 52.9499 23.4536 52.6213 23.1976L52.6237 23.1953Z"
          fill="#1E9FDA"
        />
        <path
          d="M21.5418 17.4446C21.1275 17.3952 20.7179 17.508 20.3892 17.7616L13.3737 23.1905C13.045 23.4441 12.8379 23.8104 12.7879 24.219C12.7378 24.6276 12.8522 25.0315 13.1093 25.3532C13.407 25.7289 13.8547 25.9426 14.3405 25.9426C14.6882 25.9426 15.0311 25.8252 15.305 25.6138L22.3205 20.1849C22.6492 19.9313 22.8563 19.565 22.9064 19.1564C22.9564 18.7478 22.8421 18.3439 22.5849 18.0199C22.3277 17.6958 21.9586 17.4915 21.5442 17.4422L21.5418 17.4446Z"
          fill="#1E9FDA"
        />
        <path
          d="M36.8924 30.6483C39.4166 30.6483 41.4718 28.6242 41.4718 26.1328C41.4718 23.6414 39.419 21.6172 36.8924 21.6172C34.3657 21.6172 32.313 23.6414 32.313 26.1328C32.313 28.6242 34.3657 30.6483 36.8924 30.6483ZM34.711 26.1328C34.711 24.947 35.6898 23.9819 36.8924 23.9819C38.095 23.9819 39.0737 24.947 39.0737 26.1328C39.0737 27.3186 38.095 28.2837 36.8924 28.2837C35.6898 28.2837 34.711 27.3186 34.711 26.1328Z"
          fill="#1E9FDA"
        />
        <path
          d="M49.659 46.0265H47.1276V36.0984C47.1276 33.2712 44.7938 30.9724 41.9266 30.9724H31.8582C28.991 30.9724 26.6572 33.2712 26.6572 36.0984V46.5125C26.6572 47.4001 27.0382 48.2502 27.705 48.8466C28.3694 49.4407 29.2696 49.7342 30.1674 49.652C30.3341 49.6356 30.4984 49.6074 30.6603 49.5652V62.3345C30.6603 64.3539 32.3273 65.9976 34.3753 65.9976C35.3183 65.9976 36.2089 65.6501 36.8924 65.0279C37.5759 65.6501 38.4665 65.9976 39.4095 65.9976C41.4575 65.9976 43.1245 64.3539 43.1245 62.3345V56.6496H49.659C50.9949 56.6496 52.0809 55.5788 52.0809 54.2615V48.4099C52.0809 47.0925 50.9949 46.0218 49.659 46.0218V46.0265ZM33.056 46.428V39.2778C33.056 38.6274 32.5178 38.0967 31.8582 38.0967C31.1985 38.0967 30.6603 38.6274 30.6603 39.2778V46.428C30.6603 46.8718 30.3365 47.264 29.9412 47.3015C29.703 47.3227 29.4887 47.2522 29.3172 47.0996C29.1505 46.9493 29.0529 46.7356 29.0529 46.5125V36.0984C29.0529 34.5744 30.3103 33.3346 31.8582 33.3346H41.9266C43.4722 33.3346 44.7295 34.5744 44.7295 36.0984V46.0265H43.1221V39.2778C43.1221 38.6274 42.5839 38.0967 41.9243 38.0967C41.2646 38.0967 40.7264 38.6274 40.7264 39.2778V46.0265H38.1141C36.7781 46.0265 35.6922 47.0972 35.6922 48.4146V62.3369C35.6922 63.053 35.1016 63.6377 34.3729 63.6377C33.6442 63.6377 33.0536 63.0554 33.0536 62.3369V46.428H33.056ZM38.0902 56.6519C38.0902 56.6519 38.1069 56.6519 38.1141 56.6519H40.7264V62.3369C40.7264 63.053 40.1358 63.6377 39.4071 63.6377C38.6784 63.6377 38.0879 63.0554 38.0879 62.3369V56.6519H38.0902ZM38.0902 54.2662V48.4146C38.0902 48.4028 38.1021 48.3911 38.1141 48.3911H49.6566C49.6709 48.3911 49.6804 48.4028 49.6804 48.4146V54.2662C49.6804 54.2803 49.6685 54.2897 49.6566 54.2897H38.1141C38.1021 54.2897 38.0902 54.2779 38.0902 54.2662Z"
          fill="#1E9FDA"
        />
      </g>
      <defs>
        <clipPath id="clip0_12428_330">
          <rect width="66" height="66" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
