export const RemnLogo = () => {
  return (
    <svg
      width="60"
      height="67"
      viewBox="0 0 60 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_12003_845)">
        <path
          d="M38.72 10.3102C38.1813 10.3368 37.7429 10.0756 37.294 9.81437C32.9577 7.29814 28.7059 3.98226 24.3961 1.3594L21.6971 0V51.9666H38.72V10.3102Z"
          fill="#0F2939"
        />
        <path
          d="M17.4665 50.9164L17.4032 24.3147L17.0334 23.8296L1.41021 13.8233L0 13.3169V66.1471C2.05986 65.2195 3.62852 63.2417 5.31338 61.845C8.8838 58.8863 12.9824 55.9329 16.3891 52.8356C16.9912 52.2865 17.5775 51.828 17.4665 50.9164Z"
          fill="#0F2939"
        />
        <path
          d="M43.3944 23.8296L43.0247 24.3147C43.0458 33.5267 42.7395 42.8133 43.1831 51.9773L60 66.1418V13.3169L43.3944 23.8296Z"
          fill="#0F2939"
        />
        <path
          d="M49.5687 62.7139C46.8962 60.4323 44.3821 57.7614 41.4191 55.9116C34.6004 56.2528 26.9103 55.2079 20.1972 55.8209C18.1691 56.0075 15.8768 58.8223 14.2659 60.139C11.5828 62.3301 8.54583 64.2652 6.38562 67.0054H54.4701C53.2976 65.1502 51.1955 64.1053 49.5687 62.7193V62.7139Z"
          fill="#0F2939"
        />
      </g>
      <defs>
        <clipPath id="clip0_12003_845">
          <rect width="60" height="67" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
