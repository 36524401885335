interface CustomInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  refInput?: React.Ref<HTMLInputElement>;
}
export const CustomInput = (props: CustomInputProps) => {
  return <input ref={props.refInput} {...props} />;
};
