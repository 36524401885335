export const DebtConsolidation = () => {
  return (
    <svg
      width="54"
      height="60"
      viewBox="0 0 54 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="svg-icon debt-consolidation-icon"
    >
      <g clipPath="url(#clip0_12428_402)">
        <path
          d="M50.999 39.1266V34.3571C50.999 31.0121 48.2798 28.2925 44.9359 28.2925H10.5461C7.20435 28.2925 4.4873 31.0121 4.4873 34.3571V53.9311C4.4873 57.2782 7.20435 60 10.5461 60H44.9359C48.2798 60 50.999 57.2782 50.999 53.9311V51.9597C50.999 51.2135 50.3912 50.6051 49.6458 50.6051C48.9003 50.6051 48.2925 51.2135 48.2925 51.9597V53.9311C48.2925 55.7837 46.7868 57.293 44.9338 57.293H10.5461C8.69735 57.293 7.19165 55.7859 7.19165 53.9311V34.3571C7.19165 32.5066 8.69523 30.9994 10.5461 30.9994H44.9359C46.7868 30.9994 48.2947 32.5045 48.2947 34.3571V39.1012H44.0825C42.2083 39.1012 40.6857 40.6274 40.6857 42.5013V45.7869C40.6857 47.6629 42.2104 49.187 44.0825 49.187H50.603C52.4772 49.187 53.9998 47.6608 53.9998 45.7869V42.5013C53.9998 40.761 52.6868 39.3237 50.999 39.1266ZM43.39 42.5034C43.39 42.1218 43.7013 41.8102 44.0825 41.8102H50.603C50.9842 41.8102 51.2955 42.1218 51.2955 42.5034V45.789C51.2955 46.1706 50.9842 46.4822 50.603 46.4822H44.0825C43.7013 46.4822 43.39 46.1706 43.39 45.789V42.5034Z"
          fill="#20A2D8"
        />
        <path
          d="M14.2458 53.5815C13.5004 53.5815 12.8926 54.1899 12.8926 54.9361C12.8926 55.6822 13.5004 56.2906 14.2458 56.2906H18.4262C19.1716 56.2906 19.7794 55.6844 19.7794 54.9361C19.7794 54.1878 19.1738 53.5815 18.4262 53.5815H14.2458Z"
          fill="#20A2D8"
        />
        <path
          d="M21.8889 53.5815C21.1434 53.5815 20.5356 54.1899 20.5356 54.9361C20.5356 55.6822 21.1434 56.2906 21.8889 56.2906H24.1358C24.8812 56.2906 25.489 55.6844 25.489 54.9361C25.489 54.1878 24.8812 53.5815 24.1358 53.5815H21.8889Z"
          fill="#20A2D8"
        />
        <path
          d="M43.8034 13.4668L47.7677 14.2066C47.9456 14.2405 48.0939 14.3359 48.1976 14.4842C48.2993 14.6326 48.3353 14.8064 48.3035 14.9866L46.3129 25.6999C46.1773 26.4334 46.6623 27.1414 47.3908 27.277C47.4734 27.2919 47.5581 27.3004 47.6428 27.3004C48.2929 27.3004 48.852 26.8361 48.9727 26.1938L50.9634 15.4827C51.1307 14.5945 50.9401 13.6936 50.4276 12.9495C49.9151 12.2055 49.1464 11.7052 48.2612 11.5441L44.2989 10.8043C43.5662 10.6708 42.861 11.1562 42.7254 11.8875C42.5878 12.621 43.0749 13.329 43.8076 13.4668H43.8034Z"
          fill="#20A2D8"
        />
        <path
          d="M27.9455 4.13354C28.2145 4.37519 28.5639 4.49813 28.9154 4.47906L37.4817 4.05086C37.8586 4.04027 38.1763 4.32219 38.1953 4.68891L39.2373 25.5898C39.2733 26.3105 39.8662 26.8765 40.5884 26.8765C40.6138 26.8765 40.6392 26.8765 40.6561 26.8765C41.0161 26.8596 41.3486 26.7006 41.5922 26.4335C41.8357 26.1664 41.9585 25.8188 41.9395 25.4584L40.8976 4.55325C40.8023 2.69633 39.2118 1.25913 37.3567 1.34816L28.7799 1.77635C28.4199 1.79331 28.0874 1.95229 27.8439 2.21939C27.6003 2.48648 27.4775 2.83412 27.4966 3.19448C27.5156 3.55696 27.6744 3.89188 27.9455 4.13354Z"
          fill="#20A2D8"
        />
        <path
          d="M7.12796 27.5609C7.29102 27.5609 7.45197 27.5312 7.60656 27.4718C8.3033 27.2069 8.65484 26.4247 8.39224 25.7251L2.74849 10.8317C2.61719 10.484 2.79296 10.0961 3.13815 9.96468L22.1342 2.75111C22.3015 2.68752 22.4836 2.69388 22.6467 2.76807C22.8097 2.84226 22.9347 2.97369 22.9982 3.14115L31.6597 26.0028C31.7868 26.342 32.0388 26.6091 32.3692 26.7575C32.6996 26.9059 33.0659 26.9165 33.4026 26.7893C33.7415 26.6621 34.0083 26.4098 34.1565 26.0791C34.3048 25.7485 34.3154 25.3817 34.1883 25.0426L25.5289 2.18089C24.8703 0.438446 22.9178 -0.441257 21.1749 0.21799L2.1767 7.43368C0.438048 8.09292 -0.44081 10.0473 0.217804 11.7898L5.86367 26.6854C6.06274 27.2069 6.57099 27.5587 7.12796 27.5587V27.5609Z"
          fill="#20A2D8"
        />
        <path
          d="M6.51649 10.4547C6.34707 11.3896 5.94682 12.2841 5.35809 13.0387C5.13573 13.3228 5.03831 13.6789 5.08279 14.0371C5.12726 14.3954 5.30727 14.7155 5.59316 14.9381C5.83034 15.1225 6.12471 15.2263 6.42543 15.2263C6.84474 15.2263 7.2344 15.0356 7.49064 14.7049C8.34833 13.6068 8.93282 12.3053 9.18059 10.938C9.31401 10.2046 8.82481 9.49661 8.08784 9.36306C7.35723 9.23376 6.65202 9.7213 6.51861 10.4547H6.51649Z"
          fill="#20A2D8"
        />
        <path
          d="M22.0203 9.18683C22.1007 9.20167 22.1854 9.21015 22.268 9.21015C22.9203 9.21015 23.4794 8.7438 23.598 8.10151C23.6636 7.74539 23.5874 7.38503 23.382 7.08826C23.1765 6.78938 22.8695 6.59012 22.5137 6.52441C21.5734 6.35059 20.6818 5.94783 19.9343 5.36065C19.6505 5.13808 19.2947 5.03845 18.9368 5.08084C18.5789 5.12324 18.257 5.30342 18.0347 5.58959C17.573 6.17676 17.6768 7.02891 18.2634 7.49102C19.354 8.34741 20.6543 8.93458 22.0203 9.18895V9.18683Z"
          fill="#20A2D8"
        />
        <path
          d="M25.6881 26.832C26.4103 27.0185 27.1494 26.584 27.3378 25.8632C27.5178 25.1743 27.6089 24.4557 27.6089 23.7308C27.6089 21.4796 26.7406 19.3598 25.165 17.7636C23.5556 16.1208 21.3997 15.2178 19.0977 15.2178C17.7255 15.2178 16.4209 15.5336 15.2181 16.1568C15.1969 16.1653 15.1757 16.1759 15.1524 16.1886C12.3401 17.6661 10.5908 20.5553 10.5908 23.7329C10.5908 24.46 10.6819 25.1764 10.8619 25.8654C11.0165 26.461 11.5565 26.8765 12.1706 26.8765C12.285 26.8765 12.3994 26.8617 12.5137 26.832C13.2359 26.6433 13.67 25.9035 13.4815 25.1807C13.3608 24.7143 13.2994 24.2268 13.2994 23.7329C13.2994 21.5898 14.4705 19.6311 16.3574 18.6158C16.3786 18.6052 16.4019 18.5946 16.4209 18.584C17.2468 18.1473 18.149 17.9247 19.102 17.9247C20.6733 17.9247 22.143 18.5416 23.2421 19.6629C24.3158 20.7525 24.9088 22.1982 24.9088 23.7329C24.9088 24.2289 24.8474 24.7164 24.7246 25.1828C24.5361 25.9056 24.9702 26.6454 25.6924 26.8341L25.6881 26.832Z"
          fill="#20A2D8"
        />
      </g>
      <defs>
        <clipPath id="clip0_12428_402">
          <rect width="54" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
