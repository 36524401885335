import API from "utils/API";
import Broker from "typedef/Broker";
import LoanOfficer from "typedef/LoanOfficer";
import {
  useLocation,
  useParams,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import useLoan from "./useLoan";
import Address from "typedef/Address";

export type Ocuppancy =
  | "Primary Residence"
  | "Second Home"
  | "Investment Property";

export type IncomeSources = {
  otherIncome: number | undefined;
  salaryIncome: number | undefined;
  selfEmploymentIncome: number | undefined;
  socialIncome: number | undefined;
};

export type InviteContextType = {
  borrowerAddres?: Address;
  propertyAddress?: Address;
  useOfProceeds?: string;
  requestedLoanAmount?: string;
  legalFirstNameBorrower?: string;
  legalLastNameBorrower?: string;
  legalMiddleNameBorrower?: string;
  legalSuffixBorrower?: string;
  startDateBorrower?: string;
  totalAnnualIncomeBorrower?: string;
  loanId?: string;
  phoneBorrower?: string;
  confirmEmailAddressBorrower?: string;
  employerNameBorrower?: string;
  estimatedHomeValue?: string;
  code?: string;
  isMobile?: boolean;
  phone?: string;
  email?: string;
  broker?: Broker;
  lastName?: string;
  firstName?: string;
  middleName?: string;
  addressLine?: string;
  suffix?: string;
  loanOfficerId?: string;
  borrowerFullName?: string;
  loanOfficer?: LoanOfficer;
  address?: Address;
  creditScore?: string;
  occupancy?: Ocuppancy;
  employmentType?: string;
  maritalStatus?: string;
  homeValue?: string;
  requestedNewLoan?: string;
  annualIncome?: string;
  currentLoanBalance?: string;
  phoneNumber?: string;
  useProceeds?: string;
  inviteType?: string;
  employerNameCoborrower?: string;
  startDateCoborrower?: string;
  dateOfBirth?: string;
  socialSecurityNumber?: string;
  fastPass?: boolean;
  body?: {
    avmHomeValue?: string;
    estimatedHomeValue?: string;
    completedByBorrower?: boolean;
    ssnCoborrower?: string;
    dateOfBirthCoborrower?: string;
    incomeSourcesBorrower: IncomeSources;
    incomeSourcesCoborrower: IncomeSources;
    totalAnnualIncomeCoborrower?: string;
  };
  manualBorrowerAddress?: Address;
  customAddress?: {
    city: string;
    secondary?: string;
    state: string;
    street_name: string;
    street_number: string;
    zipcode: string;
    street_suffix: string;
  };
  customBorrowerAddress?: {
    city: string;
    secondary?: string;
    state: string;
    street_name: string;
    street_number: string;
    zipcode: string;
    street_suffix: string;
  };
  customCoborrowerAddress?: {
    city: string;
    secondary?: string;
    state: string;
    street_name: string;
    street_number: string;
    zipcode: string;
    street_suffix: string;
  };
  annualIncomeSources?: {
    salaryIncomeCoborrower?: string;
    selfEmploymentIncomeCoborrower?: string;
    socialIncomeCoborrower?: string;
    otherIncomeCoborrower?: string;
  };
};

export const InviteContext = React.createContext<InviteContextType | undefined>(
  {},
);

export const useInvite = () => {
  return useContext(InviteContext);
};

export const InviteCustomerContextProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const params = useParams();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [invite, setInvite] = useState<InviteContextType>();
  const loan = useLoan();
  const error = 400;
  const navigate = useNavigate();
  const brokerName = params["brokerName"];
  const loName = params["loName"];
  const mloid = searchParams.get("mloid");
  const wholesaleEntity = params["wholesaleEntity"];
  const correctEntity =
    wholesaleEntity === "remn" ||
    wholesaleEntity === "hb" ||
    wholesaleEntity === "invite"
      ? wholesaleEntity
      : undefined;
  const code = searchParams.get("code");
  const typeUser = location.pathname.includes("coborrower")
    ? "coborrower"
    : "borrower";

  useEffect(() => {
    const apiCallParams =
      !code && !brokerName && !loName && ((loan && loan?.loanOfficer) || mloid)
        ? `/get-invite/borrower?loanOfficerId=${
            loan?.loanOfficer?.id ? loan?.loanOfficer?.id : mloid
          }`
        : !code && brokerName && loName && !loan?.loanOfficer && wholesaleEntity
          ? `/get-invite/borrower?brokerName=${brokerName}&loName=${loName}&wholesaleEntity=${correctEntity}`
          : code && typeUser
            ? `/get-invite/${typeUser}?code=${code}`
            : undefined;

    const isCMG = loan?.brokerId === "1d50068a-e574-4548-b0aa-5a04f976ded9";

    if (apiCallParams)
      API.get<InviteContextType>(apiCallParams).then((res) => {
        if ("error" in res) {
          console.error(res);
          if (!loan?.coborrowerInviteCode && res.status === error && !isCMG) {
            navigate("/");
          }
        } else {
          setInvite(res.data);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brokerName, code, loName, loan, typeUser, navigate, error]);

  return (
    <InviteContext.Provider value={invite}>{children}</InviteContext.Provider>
  );
};

export default useInvite;
