import { Fragment } from "react";
import CHeader from "components/CHeader";
import CFooter from "components/CFooter";
import { Outlet, useLocation } from "react-router-dom";
import { HBHeader } from "components/HBHeader";
import { HBFooter } from "components/HBFooter";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";
import "./styles.scss";
import { useIsHomebridge } from "utils/useIsHomebridge";
import { Box } from "@mui/material";

const Layout = () => {
  const { isHomeBridge } = useIsHomebridge();
  const location = useLocation();
  const pathName = location.pathname;
  const isGetStarted = pathName.includes("/get-started");
  const isFaqOrResources =
    pathName.includes("/faq") || pathName.includes("/resources");
  const { primaryBackground, primaryText, secondaryText } =
    useHomeBridgeColors();
  return (
    <Fragment>
      {isHomeBridge ? (
        <Box
          className={`homebridge-layout ${
            pathName !== "/" ? pathName.replace("/", "homebridge-layout__") : ""
          }`}
          style={{
            display: "grid",
            minHeight: "100dvh",
            gridTemplateRows: "auto 1fr auto",
            fontFamily: "NunitoSans",
            backgroundColor: isFaqOrResources ? primaryText : secondaryText,
          }}
        >
          <HBHeader />
          <Box
            className="homebridge-layout__content"
            style={{
              backgroundColor: primaryBackground,
              color: primaryText,
              width: "100%",
            }}
          >
            <Outlet />
          </Box>
          {!isGetStarted && <HBFooter />}
        </Box>
      ) : (
        <>
          <CHeader />
          <Outlet />
          <CFooter />
        </>
      )}
    </Fragment>
  );
};

export default Layout;
