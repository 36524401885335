import moment from "moment";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import { Box, Pagination, Typography, useTheme } from "@mui/material";

export interface Doc {
  id: string;
  key: string;
  category: string;
  uploaderId: string;
  uploadDate: string;
  viewPermissions: string[];
  borrowerPermissions: boolean;
  url: string;
  status?: string;
}

export interface CTableProps {
  documents: {
    page: number;
    pageItems: Doc[];
    pageLimit: number;
    loading: boolean;
  };
  handleChange: (event: React.ChangeEvent<unknown>, value: number) => void;
  currentPage: number;
}

export default function ArchivedDocuments({
  documents,
  handleChange,
  currentPage,
}: CTableProps) {
  const theme = useTheme();

  const getDocumentName = (key: string) => {
    let fileName = key.split("/")[3];

    fileName = fileName.replace(/^\d+_/, "");
    fileName = fileName.replace(/^B[1-2]_/, "");
    fileName = fileName.replace(/_\d{4}/, "");

    const dashIndex = fileName.indexOf(" - ");

    if (dashIndex !== -1) {
      fileName = fileName.substring(0, dashIndex);
    }

    return fileName;
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ "&:first-child th": { border: 0 } }}>
              <TableCell align="center" sx={{ padding: 0 }}>
                <Typography
                  variant="subtitle1"
                  color={theme.palette.text.secondary}
                  whiteSpace={"nowrap"}
                >
                  Date Uploaded
                </Typography>
              </TableCell>
              <TableCell align="center" sx={{ px: 0, py: 1 }}>
                <Typography
                  variant="subtitle1"
                  color={theme.palette.text.secondary}
                  whiteSpace={"nowrap"}
                >
                  Status
                </Typography>
              </TableCell>
              <TableCell align="center" sx={{ px: 0, py: 1 }}>
                <Typography
                  variant="subtitle1"
                  color={theme.palette.text.secondary}
                  whiteSpace={"nowrap"}
                >
                  Document Name
                </Typography>
              </TableCell>
              <TableCell align="center" sx={{ px: 0, py: 1 }}>
                <Typography
                  variant="subtitle1"
                  color={theme.palette.text.secondary}
                  whiteSpace={"nowrap"}
                >
                  Document Type
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documents?.pageItems?.map((doc) => (
              <TableRow key={doc.key}>
                <TableCell align="center" sx={{ px: 0, py: 1 }} width={"15%"}>
                  {moment(doc?.uploadDate).format("MM/DD/YYYY")}
                </TableCell>
                <TableCell align="center" sx={{ p: 0, py: 1 }} width={"15%"}>
                  {doc?.status}
                </TableCell>
                <TableCell align="center" sx={{ p: 0, py: 1 }} width={"50%"}>
                  <Typography color={theme.palette.primary.main}>
                    <a
                      data-cy="documentName"
                      target={"_blank"}
                      rel="noreferrer"
                      href={doc?.url}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      {getDocumentName(doc.key)}
                    </a>
                  </Typography>
                </TableCell>
                <TableCell align="center" sx={{ p: 0, py: 1 }} width={"20%"}>
                  {doc.category}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box justifyContent={"flex-end"} display={"flex"} pt={2}>
        <Pagination
          count={documents.pageLimit}
          color="primary"
          onChange={handleChange}
          page={currentPage}
        />
      </Box>
    </>
  );
}
