import useUser from "context/UserCustomer/useUserCustomerContext";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Loan from "typedef/Loan";
import API from "utils/API";

const useLoan = () => {
  const [loan, setLoan] = useState<Loan>();
  const location = useLocation();
  const isAdmin = location.pathname.includes("impersonate");
  const { user, logout, selectedLoanId } = useUser();
  const params = useParams();
  const currentAdminLoanId = params?.loanId;

  useEffect(() => {
    if (user && selectedLoanId) {
      isAdmin && currentAdminLoanId
        ? API.get<Loan>(
            `/admin-impersonate/get/loan/${currentAdminLoanId}`,
          ).then((response) => {
            if ("error" in response) {
              console.error(response);
            } else {
              setLoan(response.data);
            }
          })
        : API.get<Loan>(`/get/my-loan?id=${selectedLoanId}`).then(
            (response) => {
              if ("error" in response) {
                console.error(response);
              } else {
                setLoan(response.data);
              }
            },
          );
    }
  }, [user, logout, selectedLoanId, isAdmin, currentAdminLoanId]);

  return loan;
};

export default useLoan;
