export const LSisborrower = "isBorrowerNFTYDoor";
export const LStoken = "tokenNFTYDoor";
export const LSAdminToken = "adminTokenNFTYDoor";
export const LStokenExp = "tokenNFTYDoorExp";
export const LSAdminTokenExp = "adminTokenNFTYDoorExp";
export const LSLoanOfficerToken = "loanOfficerTokenNFTYDoor";
export const LSLoanOfficerTokenExp = "loanOfficerTokenNFTYDoorExp";
export const passwordMsgHelper =
  "Password must be at least 9 characters and include both upper and lower case characters, at least 1 symbol character, and at least 1 number.";
export const regexPassword =
  //eslint-disable-next-line
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`])\S{8,99}$/;
