import { Box, Typography } from "@mui/material";

interface HBIconAndTextProps {
  IconComponent: React.ComponentType;
  text: string;
}

const HBIconAndText = ({ IconComponent, text }: HBIconAndTextProps) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      justifyContent="center"
      alignItems="center"
      sx={{
        width: "auto",
        textAlign: "center",
        margin: {
          xs: "0",
          md: "0px 20px 0px 20px",
        },
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: "100px",
          height: "100px",
          "& svg": {
            width: "90%",
            height: "90%",
          },
        }}
      >
        <IconComponent />
      </Box>
      <Typography
        variant="body2"
        fontWeight="bold"
        mt={2}
        sx={{
          fontSize: "19px",
          lineHeight: "100%",
          color: "#FFFFFF",
          letterSpacing: "0.09em",
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default HBIconAndText;
