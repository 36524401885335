interface StepTitleProps {
  text: string;
  boldText: string;
  normalText?: string;
  boldVisible?: boolean;
}

export const StepTitle = ({
  text,
  boldText,
  normalText,
  boldVisible = true,
}: StepTitleProps) => {
  return (
    <>
      <strong
        className="strong-900"
        style={{
          color: "#20A2D8",
          visibility: boldVisible ? "visible" : "hidden",
        }}
      >
        {boldText}
      </strong>{" "}
      {normalText} <br />
      {text}
    </>
  );
};
