import React from "react";
import { Box, Typography } from "@mui/material";

interface CheckboxWithLabelProps {
  name: string;
  label: string;
  state: boolean | undefined;
  sizesLabel?: string;
  onChange?: () => void;
  alignStart?: boolean;
  hasError?: boolean;
  animation?: boolean;
}

const CheckboxInput: React.FC<CheckboxWithLabelProps> = ({
  name,
  label,
  state,
  sizesLabel,
  onChange,
  alignStart = false,
  hasError = false,
  animation = false,
}) => {
  const handleCheckboxChange = () => {
    if (onChange) {
      onChange();
    }
  };

  return (
    <Box
      display="flex"
      alignItems={alignStart ? "flex-start" : "center"}
      sx={{
        gap: "10px",
        cursor: "pointer",
        animation: animation && hasError ? "shake 0.3s ease-in-out" : "none", // Animación de error
      }}
      onClick={handleCheckboxChange}
    >
      <input
        type="checkbox"
        name={name}
        checked={state}
        onChange={handleCheckboxChange}
        className="get-started__checkbox"
        style={{
          border: hasError ? "2px solid red" : "1px solid #ccc", // Borde rojo en caso de error
        }}
      />
      <Typography fontSize={sizesLabel}>{label}</Typography>
    </Box>
  );
};

export default CheckboxInput;
