import { Box, Typography } from "@mui/material";
import { CHBButton } from "components/CHBButton";
import { HBSeparate } from "components/HBSeparate";
import { CSSProperties, useEffect, useRef, useState } from "react";
import { FormData, LoanPurposeType } from "../../useGetStarted";
import { HomeImprovement } from "components/icons/HomeImprovement";
import { Other } from "components/icons/Other";
import { DebtConsolidation } from "components/icons/DebtConsolidation";
import { useHomebridgeSizes } from "utils/useHomebridgeSizes";
import { useUpdateLoanStatus } from "../../useUpdateLoanStatus";

export interface LoanPurposeProps {
  onNext: () => void;
  onPrev: () => void;
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}

export const LoanPurpose = ({
  onNext,
  onPrev,
  formData,
  setFormData,
}: LoanPurposeProps) => {
  const { sizes } = useHomebridgeSizes();
  const { updateLoanStatus } = useUpdateLoanStatus();

  const loadedStatus = useRef(false);
  const [error, setError] = useState(false);
  const [animation, setAnimation] = useState(false);
  const [currentActive, setCurrentActive] = useState(formData.loanPurpose);

  const handleNext = () => {
    if (!formData.loanPurpose) {
      setAnimation(true);
      setError(true);
      setTimeout(() => {
        setAnimation(false);
      }, 300);
    } else {
      setError(false);
      onNext();
    }
  };

  const handleSelect = (type: LoanPurposeType) => {
    setFormData({ ...formData, loanPurpose: type });
    setCurrentActive(type);
    setError(false);
  };

  const descriptionStyles: CSSProperties = {
    textTransform: "capitalize",
    marginTop: "0.45rem",
  };

  useEffect(() => {
    if (
      !loadedStatus.current &&
      formData?.loanId &&
      formData?.requestedLoanAmount &&
      formData?.initialDrawAmount
    ) {
      const statusCode = "DTC006";
      updateLoanStatus({
        loanId: formData.loanId,
        status: statusCode,
        body: {
          requestedLoanAmount: formData.requestedLoanAmount,
          initialDrawAmount: formData.initialDrawAmount,
        },
      });
      loadedStatus.current = true;
    }
  }, [
    updateLoanStatus,
    formData?.initialDrawAmount,
    formData?.loanId,
    formData?.requestedLoanAmount,
  ]);

  return (
    <Box>
      <Box>
        <Box
          sx={{
            display: "grid",
            justifyItems: "center",
            gridTemplateColumns: {
              xs: "",
              md: "repeat(3, 1fr)",
            },
            justifyContent: {
              xs: "",
              md: "center",
            },
            gap: "20px",
            marginTop: "40px",
          }}
        >
          <CHBButton
            classNameContainer="full-width"
            onClick={() => handleSelect("Home Improvement")}
            className={`animated-button ${
              currentActive === "Home Improvement"
                ? "animated-button--active"
                : ""
            }`}
            styleContainer={{
              height: "100%",
              width: "100%",
            }}
            style={{
              border: error ? "2px solid red" : "2px solid #20A2D8",
              animation: animation ? "shake 0.3s ease-in-out" : "none",
              height: "100%",
              padding: "20px",
            }}
          >
            <HomeImprovement />
            <Typography
              variant="body2"
              fontFamily={"NunitoSans"}
              fontSize={sizes.typography}
              fontWeight={"bold"}
              marginBottom={"10px"}
              className="custom-typography text-black"
              style={descriptionStyles}
            >
              Home <br />
              Improvements
            </Typography>
          </CHBButton>
          <CHBButton
            classNameContainer="full-width"
            onClick={() => handleSelect("Debt Consolidation")}
            className={`animated-button ${
              currentActive === "Debt Consolidation"
                ? "animated-button--active"
                : ""
            }`}
            styleContainer={{
              height: "100%",
              width: "100%",
            }}
            style={{
              border: error ? "2px solid red" : "2px solid #20A2D8",
              animation: animation ? "shake 0.3s ease-in-out" : "none",
              height: "100%",
              padding: "20px",
            }}
          >
            <DebtConsolidation />
            <Typography
              variant="body2"
              fontFamily={"NunitoSans"}
              fontSize={sizes.typography}
              fontWeight={"bold"}
              marginBottom={"10px"}
              className="custom-typography text-black"
              style={descriptionStyles}
            >
              Debt <br />
              Consolidation
            </Typography>
          </CHBButton>
          <CHBButton
            classNameContainer="full-width"
            onClick={() => handleSelect("Other")}
            className={`animated-button ${
              currentActive === "Other" ? "animated-button--active" : ""
            }`}
            styleContainer={{
              height: "100%",
              width: "100%",
            }}
            style={{
              border: error ? "2px solid red" : "2px solid #20A2D8",
              animation: animation ? "shake 0.3s ease-in-out" : "none",
              height: "100%",
              padding: "20px",
            }}
          >
            <Other />
            <Typography
              variant="body2"
              fontFamily={"NunitoSans"}
              fontSize={sizes.typography}
              fontWeight={"bold"}
              marginBottom={"10px"}
              className="custom-typography text-black"
              style={descriptionStyles}
            >
              Other
            </Typography>
          </CHBButton>
        </Box>
        <p
          className="get-started__note"
          style={{ marginTop: "40px", marginBottom: "30px" }}
        >
          <strong className="strong-700">Something else?</strong> If you plan to
          use your HELOC for something other than home improvements or debt
          consolidation, just choose “other.”
        </p>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column-reverse",
              sm: "row",
            },
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            marginTop: "40px",
          }}
        >
          <CHBButton onClick={onPrev} secondaryArrow={true}>
            Go Back
          </CHBButton>
          <CHBButton onClick={handleNext}>Next</CHBButton>
        </Box>
      </Box>
      <HBSeparate className="step-separate" type="vertical" />
    </Box>
  );
};
