export const CircleNumberOne = () => {
  return (
    <span style={{ width: "50px", height: "50px" }}>
      <svg
        width="50"
        height="50"
        viewBox="0 0 50 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="25" cy="27.5505" r="25" fill="#102939" />
        <path
          d="M19.2759 36.5505V33.3705H24.0459V18.6405H25.9059L20.3559 22.0005L18.8559 19.1805L25.1559 15.4005H27.8859V33.3705H32.3559V36.5505H19.2759Z"
          fill="white"
        />
      </svg>
    </span>
  );
};
