import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { Theme, useTheme } from "@mui/material/styles";
import { FormData } from "../../useGetStarted";
import { useCallback, useEffect, useState } from "react";

interface DrawAmountProps {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  disabled?: boolean;
}

export const DrawAmount = ({
  formData,
  setFormData,
  disabled = false,
}: DrawAmountProps) => {
  const theme: Theme = useTheme();
  const defaultValue = 100;
  const [currentValue, setCurrentValue] = useState<number>(defaultValue);

  const marks = [
    {
      value: 75,
      label: "75%",
    },
    {
      value: 80,
      label: "80%",
    },
    {
      value: 85,
      label: "85%",
    },
    {
      value: 90,
      label: "90%",
    },
    {
      value: 95,
      label: "95%",
    },
    {
      value: 100,
      label: "100%",
    },
  ];

  const handleInitialDrawAmount = useCallback(
    (value: number, requestedLoanAmount?: number) => {
      if (!requestedLoanAmount) return;
      setFormData((prev) => ({
        ...prev,
        initialDrawAmount: (requestedLoanAmount * value) / 100,
      }));
    },
    [setFormData],
  );

  useEffect(() => {
    handleInitialDrawAmount(currentValue, formData?.requestedLoanAmount);
  }, [currentValue, formData.requestedLoanAmount, handleInitialDrawAmount]);

  return (
    <Box
      width={{
        xs: "200px",
        sm: "300px",
      }}
      sx={{ margin: "0 auto" }}
    >
      <Slider
        disabled={disabled}
        aria-label="Restricted values"
        defaultValue={100}
        getAriaValueText={valuetext}
        step={5}
        valueLabelDisplay="auto"
        marks={marks}
        min={75}
        max={100}
        sx={{
          color: "black",
          "& .MuiSlider-thumb": {
            color: theme.palette.primary.main,
            border: "3px solid black",
          },
          "& .MuiSlider-markLabel": {
            color: "black",
          },
          "& .MuiSlider-track, .MuiSlider-rail": {
            color: "black",
          },
          "& .MuiSlider-mark": {
            width: 8,
            height: 8,
            borderRadius: "50%",
            backgroundColor: "black",
          },
        }}
        onChange={(e, value) => {
          if (typeof value === "number" && formData?.requestedLoanAmount) {
            setCurrentValue(value);
          }
        }}
      />
    </Box>
  );
};

function valuetext(value: number) {
  return `${value}%`;
}
