import {
  Box,
  Radio,
  IconButton,
  RadioGroup,
  Typography,
  DialogProps,
  FormControlLabel,
  useTheme,
  Checkbox,
  InputAdornment,
} from "@mui/material";
import { FC, useMemo, useState } from "react";
import CButton from "components/CButton";
import CDialog from "components/CDialog";
import usePersonaForm from "./usePersonaForm";
import Close from "@mui/icons-material/Close";
import CTextField from "components/CTextField";
import CNumberField from "components/CNumberField";
import useTracker from "components/CTracker/useTrackerContext";
import moment from "moment";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import { RemoveRedEye, VisibilityOff } from "@mui/icons-material";

const PersonaDialogForm: FC<DialogProps> = ({ open, onClose, ...props }) => {
  const { loan, type } = useTracker();
  const [consent, setConsent] = useState(false);
  const isBorrower = type === "borrower";
  const form = usePersonaForm(onClose, consent);
  const theme = useTheme();
  const { privateLabel } = usePrivateLabel();
  const lenderName = privateLabel?.isNFTYDoor
    ? "Homebridge Financial Services, Inc."
    : privateLabel?.legalLenderName;

  const handleConsent = () => {
    setConsent((prev: boolean) => !prev);
  };

  const showAddCoborrower = useMemo(() => {
    if (loan?.coborrowerSteps && loan?.coborrowerId) return false;
    else return true;
  }, [loan]);

  const styles = {
    icon: {
      fontSize: "1.5rem",
    },
    containerButton: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: "1.2rem",
    },
    buttonStyles: {
      mt: 2,
      width: { md: "auto", xs: "100%" },
    },
  };
  const [showSocialSecurityNumber, setShowSocialSecurityNumber] =
    useState(true);
  const toggleShowSocialSecurityNumber = () => {
    setShowSocialSecurityNumber(!showSocialSecurityNumber);
  };

  return (
    <CDialog open={open} onClose={onClose} maxWidth="xs" {...props}>
      <form onSubmit={form.onSubmit}>
        <Box display="flex" alignItems="center">
          <Typography
            mr={10}
            variant="h5"
            fontSize={30}
            textAlign="left"
            fontFamily={"Pro Display Semibold"}
          >
            Add SSN and DOB
          </Typography>
          <IconButton
            onClick={() => {
              form.clearErrors();
              setConsent(false);
              onClose?.({}, "backdropClick");
            }}
          >
            <Close fontSize="large" htmlColor={theme.palette.text.disabled} />
          </IconButton>
        </Box>
        <CNumberField
          fullWidth
          label="Social Security Number"
          type={showSocialSecurityNumber ? "text" : "password"}
          sx={{ my: 4 }}
          format="###-##-####"
          placeholder="xxx-xx-xxxx"
          error={form.formState.errors.ssn?.message}
          {...form.register("ssn", {
            required: "This field is required",
            validate: (newValue) =>
              newValue.replace(/ /g, "").length < 11
                ? "This field is required"
                : undefined,
          })}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={toggleShowSocialSecurityNumber}>
                  {showSocialSecurityNumber ? (
                    <RemoveRedEye sx={styles.icon} />
                  ) : (
                    <VisibilityOff sx={styles.icon} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <CTextField
          fullWidth
          type="date"
          InputProps={{
            inputProps: {
              min: "1908-02-05",
              max: moment().subtract(18, "year").format("YYYY-MM-DD"),
            },
          }}
          format="YYYY-MM-DD"
          error={form.formState.errors.dob?.message}
          label="Date of Birth"
          {...form.register("dob", { required: "This field is required" })}
        />
        <Box
          mt={3}
          display={"flex"}
          alignItems="flex-start"
          flexDirection="row"
          justifyContent={{ xs: "center", md: "left" }}
          maxWidth={360}
        >
          <Checkbox onClick={() => handleConsent()} />
          <Typography
            mt={1}
            variant="body2"
            color={theme.palette.text.primary}
            textAlign={"justify"}
          >
            By clicking <strong style={{ fontWeight: "500" }}>"Save"</strong>, I
            am providing ‘written instructions’ to
            <strong style={{ fontWeight: "500" }}> {lenderName}</strong> under
            the Fair Credit Reporting Act authorizing
            <strong style={{ fontWeight: "500" }}> {lenderName}</strong> to
            obtain information from my personal credit profile or other
            information related to my employment and income information.
          </Typography>
        </Box>
        {!loan?.borrowerFlags?.coborrowerInvited &&
          isBorrower &&
          showAddCoborrower && (
            <>
              <Typography
                mt={3}
                mb={1}
                width="100%"
                textAlign="left"
                fontFamily="Pro Display Semibold"
              >
                Are you applying with a co-borrower?
              </Typography>

              <RadioGroup
                value={form.watch("hasCoborrower")}
                sx={{ mb: 3 }}
                onChange={({ currentTarget }) =>
                  form.setValue("hasCoborrower", currentTarget.value === "true")
                }
              >
                <FormControlLabel
                  sx={{ mr: 0 }}
                  value={"true"}
                  label="Yes"
                  control={<Radio />}
                />
                <FormControlLabel
                  sx={{ mr: 0 }}
                  value={"false"}
                  label="No"
                  control={<Radio />}
                />
              </RadioGroup>
            </>
          )}
        {form.watch("hasCoborrower") && (
          <CTextField
            fullWidth
            error={form.formState.errors.coborrowerFirstName?.message}
            label="Co-borrower's First Name"
            sx={{ marginBottom: 3 }}
            {...form.register("coborrowerFirstName", {
              required: {
                value: form.watch("hasCoborrower"),
                message: "This field is required",
              },
            })}
          />
        )}
        {form.watch("hasCoborrower") && (
          <CTextField
            fullWidth
            error={form.formState.errors.lastName?.message}
            label="Co-borrower's Last Name"
            sx={{ marginBottom: 3 }}
            {...form.register("lastName", {
              required: {
                value: form.watch("hasCoborrower"),
                message: "This field is required",
              },
            })}
          />
        )}
        {form.watch("hasCoborrower") && (
          <CTextField
            fullWidth
            error={form.formState.errors.coborrowerEmail?.message}
            label="Co-borrower's Email"
            {...form.register("coborrowerEmail", {
              required: {
                value: form.watch("hasCoborrower"),
                message: "This field is required",
              },
              pattern: {
                value:
                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i,
                message: "The Email format is incorrect.",
              },
              validate: (value) => {
                const trimmedValue = value?.trim().toLowerCase();
                if (
                  loan?.borrowerEmail?.toLowerCase().trim() === trimmedValue
                ) {
                  return "Email already in use.";
                }
                return true;
              },
            })}
          />
        )}
        <Box sx={styles.containerButton}>
          <CButton
            type="button"
            loading={form.formState.isSubmitting}
            variant="contained"
            sx={{ mt: 4 }}
            onClick={() => {
              form.clearErrors();
              setConsent(false);
              onClose?.({}, "backdropClick");
            }}
            name={"discard-confirm-ssn-dob"}
            aria-label={"discard-confirm-ssn-dob"}
          >
            Discard
          </CButton>
          <CButton
            type="submit"
            sx={{ mt: 4 }}
            variant="contained"
            disabled={consent === false}
            loading={form.formState.isSubmitting}
            name="personaDialog-save"
          >
            Save
          </CButton>
        </Box>
      </form>
    </CDialog>
  );
};

export default PersonaDialogForm;
