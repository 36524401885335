import { useState } from "react";
import { FormData } from "../../useGetStarted";

export type Errors = {
  field: keyof FormData;
  message: string;
  success?: boolean;
  regex?: RegExp;
};

const usePersonalDataValidation = (
  formData: FormData,
  setFormData: React.Dispatch<React.SetStateAction<FormData>>,
  onNext: () => void,
) => {
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState<Errors[]>([]);
  const [animation, setAnimation] = useState(false);

  const inputsToValidate: { field: keyof FormData; value: string }[] = [
    { field: "firstName", value: formData.firstName ?? "" },
    { field: "lastName", value: formData.lastName ?? "" },
    { field: "middleName", value: formData.middleName ?? "" },
    { field: "suffix", value: formData.suffix ?? "" },
    { field: "maritalStatus", value: formData.maritalStatus ?? "" },
    { field: "email", value: formData.email ?? "" },
    { field: "phoneNumber", value: formData.phoneNumber ?? "" },
  ];

  const handleNext = () => {
    const inputErrors = validateInputs(inputsToValidate);
    if (inputErrors.length > 0) {
      setAnimation(true);
      setError(true);
      setTimeout(() => {
        setAnimation(false);
      }, 300);
    } else {
      setError(false);
      onNext();
    }
  };

  const onValueChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { id, value } = event.target;
    const field = id as keyof FormData;
    const newFormData = { ...formData };
    const trimmedValue = field === "phoneNumber" ? value.trim() : value;
    (newFormData[field as unknown as keyof FormData] as unknown) =
      trimmedValue as string;
    setFormData(newFormData);
    validateInputs({ field, value });
  };

  const validateInputs = (
    inputs:
      | { field: keyof FormData; value: string }[]
      | { field: keyof FormData; value: string },
  ) => {
    const checks: Errors[] = [
      {
        regex: /^[a-zA-Z]+$/,
        message: "Must contain only letters.",
        success: false,
        field: "firstName",
      },
      {
        regex: /^[a-zA-Z]+$/,
        message: "Must contain only letters.",
        success: false,
        field: "lastName",
      },
      {
        regex: /^$|^[a-zA-Z]+$/,
        message: "Must contain only letters.",
        success: false,
        field: "middleName",
      },
      {
        regex: /^$|^[a-zA-Z]+$/,
        message: "Must contain only letters.",
        success: false,
        field: "suffix",
      },
      {
        regex: /^(Unmarried|Married|Separated)$/,
        message: "Invalid marital status.",
        success: false,
        field: "maritalStatus",
      },
      {
        regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: "Email address must be valid.",
        success: false,
        field: "email",
      },
      {
        regex: /^[+\d]{1}[\d\s]{11,15}$/,
        message: "Invalid mobile phone number format.",
        success: false,
        field: "phoneNumber",
      },
      {
        regex: /.{9,}/,
        message: "Password must be at least 9 characters.",
        success: false,
        field: "password",
      },
      {
        regex: /\d/,
        message: "Password must contain at least one number.",
        success: false,
        field: "password",
      },
      {
        regex: /[^a-zA-Z0-9]/,
        message: "Password must contain a special character.",
        success: false,
        field: "password",
      },
      {
        regex: /[A-Z]/,
        message: "Password must contain an uppercase letter.",
        success: false,
        field: "password",
      },
      {
        regex: /[a-z]/,
        message: "Password must contain a lowercase letter.",
        success: false,
        field: "password",
      },
    ];

    const validationErrors: Errors[] = [];

    const isArray = Array.isArray(inputs);

    if (isArray) {
      inputs.forEach(({ field, value }) => {
        const check = checks.find((check) => check.field === field);
        const trimmedValue = field === "phoneNumber" ? value.trim() : value;
        if (check && !check.regex?.test(trimmedValue)) {
          validationErrors.push({
            field,
            message: check.message,
            success: false,
          });
        }
      });
    } else {
      const currentErrors = [...errors];

      const filteredCurrentErrors = currentErrors.filter(
        (error) => error.field !== inputs.field,
      );

      const check = checks.filter((check) => check.field === inputs.field);
      check.forEach((check) => {
        const trimmedValue =
          check.field === "phoneNumber" ? inputs.value.trim() : inputs.value;
        if (!check.regex?.test(trimmedValue)) {
          filteredCurrentErrors.push({
            field: inputs.field,
            message: check.message,
            success: false,
          });
        }
      });
      validationErrors.push(...filteredCurrentErrors);
    }

    setErrors(validationErrors);
    return validationErrors;
  };

  return {
    error,
    errors,
    animation,
    handleNext,
    onValueChange,
    validateInputs,
  };
};

export default usePersonalDataValidation;
