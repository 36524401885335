import { Box, Grid, Typography, Link } from "@mui/material";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";

type svgGridProps = {
  images: string[];
  direction: "row" | "column";
};

export const HBsvgGrid = ({ images, direction }: svgGridProps) => {
  const { secondaryText } = useHomeBridgeColors();
  return (
    <Box>
      <Grid
        container
        spacing={2}
        paddingTop={direction === "row" ? 2 : 0}
        direction={direction === "row" ? "row" : "column"}
      >
        {images.map((image, index) => (
          <Grid
            item
            xs={direction === "row" ? 4 : 12}
            md={2}
            key={index}
            display="flex"
            justifyContent="center"
            alignItems="end"
          >
            <Box
              component="img"
              src={image}
              alt={`SVG ${index + 1}`}
              sx={{
                width: "100%",
                height: {
                  xs: direction === "row" ? "auto" : "60px",
                  md: direction === "row" ? "auto" : "auto",
                },
                objectFit: "fill",
                padding: {
                  xs: direction === "row" ? "auto" : "0",
                },
              }}
            />
          </Grid>
        ))}
      </Grid>
      {direction === "row" && (
        <Box mt={2} textAlign="start">
          <Typography variant="body2">
            Source:{" "}
            <Link
              color={secondaryText}
              href="https://fred.stlouisfed.org/"
              target="_blank"
              rel="noopener"
            >
              fred.stlouisfed.org
            </Link>
          </Typography>
        </Box>
      )}
    </Box>
  );
};
