export const useMetaTags = () => {
  const updateFaviconAndMeta = (favicon: string) => {
    const existingIcons = document?.querySelectorAll(
      "link[rel='icon'], link[rel='shortcut icon']",
    );
    existingIcons?.forEach((icon) => icon?.parentNode?.removeChild(icon));

    const existingMetaTag = document?.querySelector(
      "meta[name='msapplication-TileImage']",
    );
    if (existingMetaTag) {
      existingMetaTag?.parentNode?.removeChild(existingMetaTag);
    }

    const favicons = [
      { rel: "icon", href: favicon },
      { rel: "shortcut icon", href: favicon },
    ];

    favicons.forEach(({ rel, href }) => {
      const link = document?.createElement("link");
      link.rel = rel;
      link.href = href;
      document.head.appendChild(link);
    });

    const meta = document?.createElement("meta");
    meta.name = "msapplication-TileImage";
    meta.content = favicon;
    document?.head?.appendChild(meta);
  };

  const updateMetaTags = (title: string) => {
    document.title = title;

    const metaTagsToUpdate = [
      { name: "og:title", content: title },
      { name: "twitter:title", content: title },
    ];

    metaTagsToUpdate?.forEach(({ name, content }) => {
      const metaElement =
        document?.querySelector(`meta[property='${name}']`) ||
        document?.querySelector(`meta[name='${name}']`);
      if (metaElement) {
        metaElement?.setAttribute("content", content);
      }
    });
  };
  return {
    updateFaviconAndMeta,
    updateMetaTags,
  };
};
