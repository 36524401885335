export const InvestmentProperty = () => {
  return (
    <svg
      width="65"
      height="66"
      viewBox="0 0 65 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="svg-icon home-improvement-icon"
    >
      <g clipPath="url(#clip0_12428_48)">
        <path
          d="M64.8288 26.3543C65.1079 25.4595 64.8147 24.5179 64.0736 23.9393L54.3172 16.2937V5.32177C54.3172 3.68676 52.985 2.35627 51.348 2.35627H46.2939C44.6569 2.35627 43.3247 3.68676 43.3247 5.32177V7.6759L34.3305 0.629903C33.2517 -0.211026 31.746 -0.211026 30.6671 0.629903L0.928739 23.937C0.185279 24.5179 -0.110229 25.4595 0.171207 26.3543L0 26.4879H0.215768C0.5488 27.3616 1.35793 27.9214 2.30074 27.9214H7.63395V60.2819C7.63395 61.9779 9.01533 63.3552 10.711 63.3552H21.028C21.877 63.3552 22.5665 62.6665 22.5665 61.8186C22.5665 60.9706 21.877 60.2819 21.028 60.2819H10.7086V26.3848C10.7086 25.5368 10.0191 24.8482 9.17012 24.8482H4.74923L32.4988 3.10115L43.9134 12.0445C44.3754 12.4052 45.011 12.4732 45.5364 12.2155C46.0617 11.9602 46.4018 11.4167 46.4018 10.8335V5.42952H51.2401V17.0385C51.2401 17.5141 51.4559 17.9544 51.8288 18.2472L60.2484 24.8458H55.8275C54.9785 24.8458 54.289 25.5345 54.289 26.3825V38.1156C54.289 38.9636 54.9785 39.6523 55.8275 39.6523C56.6765 39.6523 57.3661 38.9636 57.3661 38.1156V27.9191H62.6993C63.6421 27.9191 64.4512 27.3592 64.7842 26.4855H65L64.8288 26.352V26.3543Z"
          fill="#1E9FDA"
        />
        <path
          d="M51.8266 23.1384L33.4465 8.73722C32.886 8.29918 32.105 8.29918 31.5468 8.73722L23.5845 14.9751C22.9161 15.4974 22.8012 16.4649 23.3219 17.1324C23.5752 17.4557 23.9387 17.6618 24.3468 17.711C24.7548 17.7602 25.1582 17.6478 25.4819 17.3948L32.4943 11.8995L49.9246 25.5581C50.1943 25.7689 50.532 25.8861 50.8744 25.8861C51.3505 25.8861 51.7915 25.6706 52.0846 25.2981C52.3379 24.9749 52.4505 24.572 52.4012 24.1667C52.352 23.7591 52.1479 23.3961 51.8243 23.1408L51.8266 23.1384Z"
          fill="#1E9FDA"
        />
        <path
          d="M21.2156 17.4018C20.8076 17.3526 20.4042 17.4651 20.0805 17.718L13.1713 23.1337C12.8476 23.3867 12.6436 23.7521 12.5943 24.1597C12.5451 24.5673 12.6576 24.9702 12.9109 25.2911C13.2041 25.6659 13.645 25.879 14.1235 25.879C14.4659 25.879 14.8036 25.7619 15.0733 25.5511L21.9826 20.1354C22.3062 19.8824 22.5103 19.517 22.5595 19.1094C22.6088 18.7018 22.4962 18.299 22.2429 17.9757C21.9896 17.6524 21.6261 17.4487 21.218 17.3995L21.2156 17.4018Z"
          fill="#1E9FDA"
        />
        <path
          d="M37.7103 30.5709C27.9304 30.5709 19.9751 38.5164 19.9751 48.2843C19.9751 58.0522 27.9304 65.9977 37.7103 65.9977C47.4902 65.9977 55.4454 58.0522 55.4454 48.2843C55.4454 38.5164 47.4902 30.5709 37.7103 30.5709ZM52.9618 48.2843C52.9618 56.6842 46.1205 63.5171 37.7103 63.5171C29.3 63.5171 22.4588 56.6842 22.4588 48.2843C22.4588 39.8844 29.3 33.0515 37.7103 33.0515C46.1205 33.0515 52.9618 39.8844 52.9618 48.2843Z"
          fill="#1E9FDA"
        />
        <path
          d="M37.79 42.974C39.2746 42.974 40.5317 43.8384 40.5317 44.862C40.5317 45.6748 41.1954 46.3377 42.0092 46.3377C42.823 46.3377 43.4867 45.6748 43.4867 44.862C43.4867 42.6273 41.6949 40.7417 39.2675 40.1865V39.711C39.2675 38.8982 38.6038 38.2353 37.79 38.2353C36.9762 38.2353 36.3125 38.8982 36.3125 39.711V40.1865C33.8851 40.7417 32.0933 42.625 32.0933 44.862C32.0933 47.53 34.6496 49.7014 37.79 49.7014C39.2746 49.7014 40.5317 50.5658 40.5317 51.5894C40.5317 52.6131 39.2769 53.4774 37.79 53.4774C36.3031 53.4774 35.0483 52.6131 35.0483 51.5894C35.0483 50.7766 34.3846 50.1137 33.5708 50.1137C32.757 50.1137 32.0933 50.7766 32.0933 51.5894C32.0933 53.8241 33.8851 55.7097 36.3125 56.2649V56.7404C36.3125 57.5532 36.9762 58.2161 37.79 58.2161C38.6038 58.2161 39.2675 57.5532 39.2675 56.7404V56.2649C41.6949 55.7097 43.4867 53.8264 43.4867 51.5894C43.4867 48.9214 40.9304 46.75 37.79 46.75C36.3054 46.75 35.0483 45.8856 35.0483 44.862C35.0483 43.8384 36.3031 42.974 37.79 42.974Z"
          fill="#1E9FDA"
        />
      </g>
      <defs>
        <clipPath id="clip0_12428_48">
          <rect width="65" height="66" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
