import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import CDialog from "components/CDialog";
import CButton from "components/CButton";
import CNumberField from "components/CNumberField";
import parseMoney from "utils/parseMoney";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import useLoan from "context/Invite/useLoan";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { handleOcuppancy } from "screens/BorrowerRegisterForm/useRegisterForm";
import API from "utils/API";
import PricingEngine from "typedef/PricingEngine";
import * as yup from "yup";
import dollarFormatter from "utils/FormatterDollar";
import useTypeOfCreditLine from "screens/TypeOfCreditLine/useTypeOfCreditLine";

type ChangeLoanAmountModalProps = {
  open: boolean;
  onClose: () => void;
};

const ChangeLoanAmountModal: React.FC<ChangeLoanAmountModalProps> = ({
  open,
  onClose,
}) => {
  const [loading, setLoading] = useState(false);
  const { privateLabel } = usePrivateLabel();
  const loan = useLoan();
  const loanId = loan?.id;
  const [pricingEngine, setPricingEngine] = useState<PricingEngine>();
  const typeOfCreditLine = useTypeOfCreditLine(false);
  const currentLoanAmount = loan?.initialOffer?.amount;
  const currentInitialDraw = loan?.initialOffer?.initialDrawAmount;
  const isFirstLien =
    typeOfCreditLine?.loan?.initialOffer.currentLoanBalance === 0 &&
    typeOfCreditLine?.loan.occupancy === "Primary Residence";
  const tnMaxOffer =
    typeOfCreditLine?.loan?.property?.address?.components.state_abbreviation ===
    "TN"
      ? Math.floor(typeOfCreditLine?.loan?.initialOffer?.tnMaxOffer ?? 0)
      : undefined;

  useEffect(() => {
    const privateLabelId = privateLabel?.id;
    const helocType = handleOcuppancy(loan?.occupancy ?? "Primary Residence");
    const pricingEngineId = `${privateLabelId}#${helocType}`;
    const encodePricingEngineId = encodeURIComponent(pricingEngineId);

    API.get<PricingEngine>(
      `/get-heloc-pricing-engine?id=${encodePricingEngineId}`,
    ).then((result) => {
      if ("error" in result) {
        return;
      } else {
        setPricingEngine(result.data);
      }
    });
  }, [loan?.occupancy, privateLabel]);
  const schema = yup.object().shape({
    newLoanAmount: yup
      .string()
      .required("Request loan amount is required.")
      .test(
        "betweenAmount",
        isFirstLien
          ? `Loan Amount should be between $25,000 - ${
              tnMaxOffer
                ? `$${tnMaxOffer?.toLocaleString("en-US")}`
                : dollarFormatter?.format?.(
                    pricingEngine?.loanMaxFirstLien ?? 400000,
                  ) //temporary TN validation
            }`
          : `Loan Amount should be between $25,000
             - ${
               tnMaxOffer
                 ? `$${tnMaxOffer?.toLocaleString("en-US")}`
                 : dollarFormatter?.format?.(pricingEngine?.loanMax ?? 400000) //temporary TN validation
             }`,
        (value) => {
          if (!value) return true;
          const amount = parseMoney(value);
          if (
            isFirstLien &&
            (amount < 25000 ||
              amount >
                (tnMaxOffer
                  ? tnMaxOffer
                  : pricingEngine?.loanMaxFirstLien ?? 400000)) //temporary TN validation
          ) {
            return false;
          }
          if (
            !isFirstLien &&
            (amount < 25000 ||
              amount >
                (tnMaxOffer ? tnMaxOffer : pricingEngine?.loanMax ?? 400000)) //temporary TN validation
          ) {
            return false;
          }
          return true;
        },
      ),
    newInitialDrawAmount: yup
      .string()
      .required("Request initial draw amount is required.")
      .test(
        "betweenInitialDrawAmount",
        "Initial draw amount must be between 75% and 100% of the loan amount",
        (value) => {
          if (!value) return true;
          const initialDrawAmount = parseMoney(value);
          const loanAmount = parseMoney(form.watch("newLoanAmount"));
          return (
            initialDrawAmount >= loanAmount * 0.75 &&
            initialDrawAmount <= loanAmount
          );
        },
      ),
  });

  const form = useForm<{ newLoanAmount: string; newInitialDrawAmount: string }>(
    {
      resolver: yupResolver(schema),
      mode: "onChange",
    },
  );

  const onSubmit = form.handleSubmit(async (data) => {
    setLoading(true);
    try {
      await API.post({
        url: "/change-offer-amount/initial",
        method: "PUT",
        data: {
          amount: parseMoney(data.newLoanAmount),
          loanId,
          drawAmount: parseMoney(data.newInitialDrawAmount),
        },
      });
      setLoading(false);
      onClose();
    } catch (error) {
      setLoading(false);
    }
  });

  const control = form.control;
  const { errors } = form.formState;
  const disableSubmit =
    !form.watch("newLoanAmount") || !form.watch("newInitialDrawAmount");

  return (
    <CDialog
      title={"Change Loan Amount"}
      open={open}
      onClose={onClose}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Box component="form" sx={{ mt: 2, m: 1 }}>
        <Controller
          name="newLoanAmount"
          control={control}
          render={({ field }) => (
            <CNumberField
              fullWidth
              label="$ Requested Loan Amount"
              placeholder={`$${currentLoanAmount?.toLocaleString() ?? " "}`}
              isNumericString
              thousandSeparator
              allowNegative={false}
              allowEmptyFormatting={false}
              prefix="$"
              sx={{ mt: 2, mb: 2 }}
              {...field}
              error={errors?.newLoanAmount?.message}
              value={parseMoney(field.value)}
              onChange={field.onChange}
            />
          )}
        />
        <Controller
          name="newInitialDrawAmount"
          control={control}
          render={({ field }) => (
            <CNumberField
              fullWidth
              label="$ Requested Draw Amount (75%-100% draw required)"
              placeholder={
                form.watch("newLoanAmount")
                  ? form.watch("newLoanAmount")
                  : `$${currentInitialDraw?.toLocaleString() ?? ""}`
              }
              isNumericString
              thousandSeparator
              allowNegative={false}
              allowEmptyFormatting={false}
              prefix="$"
              sx={{ mt: 2, mb: 2 }}
              {...field}
              error={errors?.newInitialDrawAmount?.message}
              value={parseMoney(field.value)}
            />
          )}
        />
      </Box>
      <Box
        flexDirection={"column"}
        display={"flex"}
        gap={2}
        marginTop={4}
        alignItems={"center"}
      >
        <CButton
          data-cy="confirm&Submit"
          fullWidth
          onClick={onSubmit}
          variant="contained"
          loading={loading}
          disabled={disableSubmit}
        >
          Confirm & Submit
        </CButton>
        <CButton
          data-cy="cancelLoanAmount"
          type="reset"
          fullWidth
          variant="outlined"
          disabled={loading}
          onClick={onClose}
        >
          Cancel
        </CButton>
      </Box>
    </CDialog>
  );
};

export default ChangeLoanAmountModal;
