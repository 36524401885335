import { Box, Typography, useTheme } from "@mui/material";
import { CHBButton } from "components/CHBButton";
import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { useHomebridgeSizes } from "utils/useHomebridgeSizes";

interface BlogTemplateProps {
  title: string;
  subtitle?: string;
  children: ReactNode;
}

export const BlogTemplate: React.FC<BlogTemplateProps> = ({
  title,
  subtitle,
  children,
}) => {
  const theme = useTheme();
  const { sizes } = useHomebridgeSizes();

  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: theme.palette.common.white,
        padding: { xs: "20px 0", md: "68px 0" },
      }}
    >
      <Box
        sx={{
          width: { xs: "90%", sm: "80%", md: "70%", lg: "45%" },
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          height: "auto",
          gap: "32px",
          margin: "0 auto",
          color: "black",
          fontSize: sizes.typography,
        }}
      >
        <Typography variant={"h4"} fontWeight={"bold"} textAlign={"left"}>
          {title}
        </Typography>

        <Typography
          variant={"subtitle1"}
          fontSize={sizes.typography}
          fontStyle="italic"
        >
          {subtitle}
        </Typography>
        <Box>
          {React.Children.map(children, (child) => {
            if (!React.isValidElement(child)) return child;
            if (child.type === "h2") {
              return (
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  sx={{ marginTop: "20px", marginBottom: "10px" }}
                >
                  {child.props.children}
                  <br />
                </Typography>
              );
            }
            if (child.type === "p") {
              return (
                <Typography
                  variant="subtitle1"
                  fontSize={sizes.typography}
                  marginTop={1}
                  color={`${theme.palette.background.paper} !important`}
                >
                  {child.props.children}
                  <br />
                </Typography>
              );
            }
            if (child.type === "ul" || child.type === "ol") {
              return (
                <Box
                  component={child.type}
                  sx={{
                    gap: "15px",
                    marginTop: "15px",
                  }}
                >
                  {child.props.children}
                  <br />
                </Box>
              );
            }
            if (child.type === "li") {
              return (
                <Typography
                  variant="subtitle1"
                  fontSize={sizes.typography}
                  marginTop={1}
                  color={`${theme.palette.background.paper} !important`}
                >
                  {child.props.children}
                  <br />
                </Typography>
              );
            }
            return child;
          })}
          <Typography
            variant={"h6"}
            color={theme.palette.background.paper}
            fontWeight={"bold"}
            width={"90%"}
            fontSize={sizes.typography}
            paddingTop={"40px"}
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: { sx: "30px", md: "0" },
              alignItems: "center",
              justifyContent: "space-between",
              margin: "0 auto",
              textAlign: "center",
              whiteSpace: "nowrap",
            }}
          >
            Check your rate and choose your amount risk-free!
            <CHBButton
              onClick={() => {
                navigate("/get-started");
              }}
            >
              Get started
            </CHBButton>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
