import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
  useTheme,
} from "@mui/material";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import "./styles.scss";
import { useHomebridgeSizes } from "utils/useHomebridgeSizes";

type AccordionItemProps = {
  panelKey: string;
  isExpanded: boolean;
  onToggle: () => void;
  accordion: {
    title: string;
    content: JSX.Element;
  };
};

const AccordionItem = ({
  panelKey,
  isExpanded,
  onToggle,
  accordion,
}: AccordionItemProps) => {
  const theme = useTheme();
  const { sizes } = useHomebridgeSizes();

  return (
    <Accordion
      key={panelKey}
      expanded={isExpanded}
      className={`accordion ${
        isExpanded ? "accordion--expanded" : "accordion--collapsed"
      }`}
      sx={{
        color: theme.palette.common.white,
        transition:
          "background-color 0.2s ease-in-out, color 0.3s ease-in-out, border 0.3s ease-in-out, height 0.9s ease-in-out",
        borderRadius: "8px",
        "&.Mui-expanded": {
          margin: "0",
          "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: "0",
          },
        },
        "&::before": {
          display: "none",
        },
        "&:first-of-type": {
          borderRadius: "8px",
        },
        "&:last-of-type": {
          borderRadius: "8px",
        },
      }}
      disableGutters
      onClick={onToggle}
    >
      <AccordionSummary
        className="accordion__summary"
        expandIcon={
          <ExpandCircleDownOutlinedIcon className="accordion__icon" />
        }
        aria-controls={`${panelKey}-content`}
        id={`${panelKey}-header`}
        sx={{
          padding: { xs: "18px", md: "16px" },
          alignItems: "center",
          "& .MuiAccordionSummary-content": {
            margin: "0",
            minHeight: "0",
          },
          fontStyle: "bold",
        }}
      >
        <Typography variant={"h6"} fontSize={sizes.typography}>
          {accordion.title}
        </Typography>
      </AccordionSummary>
      <Divider
        sx={{
          width: "96.5%",
          height: "0px",
          border: "1px solid #20A2D8",
          margin: "0 auto",
        }}
      />
      <AccordionDetails sx={{ fontSize: sizes.typography, fontWeight: 400 }}>
        {accordion.content}
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(AccordionItem, (prevProps, nextProps) => {
  return prevProps.isExpanded === nextProps.isExpanded;
});
