import { FormData } from "../../useGetStarted";
import {
  Box,
  Typography,
  IconButton,
  Collapse,
  useMediaQuery,
  Theme,
} from "@mui/material";
import { CHBButton } from "components/CHBButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useState, useCallback, useEffect, useRef } from "react";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";
import { DemographicSteps } from "./DemographicSteps";
import { validateSexAndCitizenship } from "./DemographicCheckboxesSteps/HBSexOptions";
import { useHomebridgeSizes } from "utils/useHomebridgeSizes";
import API from "utils/API";
import { useUpdateLoanStatus } from "screens/GetStarted/useUpdateLoanStatus";

type BorrowerDemographicInfoProps = {
  onNext: () => void;
  onPrev: () => void;
  formData: FormData;
  setFormData: (data: FormData) => void;
};

export const BorrowerDemographicInfo = ({
  onNext,
  onPrev,
  formData,
  setFormData,
}: BorrowerDemographicInfoProps) => {
  const [error, setError] = useState(false);
  const [animation, setAnimation] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const { primaryText, secondaryText, tertiaryText } = useHomeBridgeColors();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const { sizes } = useHomebridgeSizes();
  const { updateLoanStatus } = useUpdateLoanStatus();
  const loadedStatus = useRef(false);

  const generateInitialOffer = useCallback(async (formDataValue: FormData) => {
    const { loanId } = formDataValue;
    await API.post({
      url: `/save-to-loan/generate-initial-offer?loanId=${loanId}`,
      data: {
        ...formDataValue,
      },
    });
  }, []);

  const handleNext = () => {
    if (!validateSexAndCitizenship(formData)) {
      setError(true);
      setAnimation(true);
      setTimeout(() => {
        setAnimation(false);
      }, 300);
    } else {
      setError(false);
      generateInitialOffer(formData);
      onNext();
    }
  };

  useEffect(() => {
    setIsExpanded(isDesktop);
  }, [isDesktop]);

  const handleExpandClick = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  useEffect(() => {
    if (!loadedStatus.current && formData?.loanId) {
      const statusCode = "DTC010";
      updateLoanStatus({
        loanId: formData.loanId,
        status: statusCode,
        body: {
          incomeSources: formData?.incomeSources,
        },
      });
      loadedStatus.current = true;
    }
  }, [updateLoanStatus, formData?.loanId, formData?.incomeSources]);

  return (
    <Box className="get-started__borrower-demographic-info">
      <div className="get-started__wrapper">
        <Box
          width={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
          display={"flex"}
          flexDirection={"column"}
          gap={4}
        >
          <Box
            width={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
            display={"flex"}
            flexDirection={"column"}
            gap={{
              xs: 4,
              md: 4,
            }}
          >
            <Box
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
              display={"flex"}
              flexDirection={"column"}
            >
              <Box
                width={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
                display={"flex"}
                flexDirection={"column"}
                gap={1}
              >
                <Typography
                  variant="h3"
                  fontFamily={"NunitoSans"}
                  fontSize={sizes.subtitle}
                  textAlign={"left"}
                  fontWeight={"bold"}
                  width={{
                    xs: "100%",
                    xl: "80%",
                    lg: "100% !important",
                  }}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  Demographic Information
                  <IconButton
                    onClick={handleExpandClick}
                    sx={{
                      padding: 0,
                      borderRadius: "50%",
                      backgroundColor: tertiaryText,
                      "&:hover": {
                        backgroundColor: primaryText,
                        color: secondaryText,
                      },
                      color: secondaryText,
                      marginLeft: 3,
                    }}
                  >
                    {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </Typography>
                <Typography
                  variant="h4"
                  fontFamily={"NunitoSans"}
                  fontSize={sizes.typography}
                  textAlign={"left"}
                  width={{
                    xs: "100%",
                    xl: "80%",
                    lg: "100% !important",
                  }}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    cursor: "pointer",
                    paddingLeft: "2px",
                  }}
                  className="get-started__note"
                  onClick={handleExpandClick}
                >
                  Why do we need it?
                </Typography>
              </Box>

              <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                <Typography
                  variant="subtitle1"
                  fontSize={sizes.typography}
                  marginTop={2}
                  fontWeight={400}
                >
                  <div style={{ marginTop: "20px", fontWeight: 400 }}>
                    The purpose of collecting this information is to help ensure
                    that all applicants are treated fairly and that the housing
                    needs of communities and neighborhoods are being fulfilled.
                    For residential mortgage lending, federal law requires that
                    we ask applicants for their demographic information
                    (ethnicity, sex, or race) in order to monitor our compliance
                    with equal credit opportunity, fair housing, and home
                    mortgage disclosure laws. You are not required to provide
                    this information, but are encouraged to do so. You may
                    select one or more designations for "Ethnicity" and one or
                    more designations for "Race".
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    The law provides that we may not discriminate on the basis
                    of this information, or on whether you choose to provide it.
                    If you do not wish to provide some or all of this
                    information, please check below.
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    However, if you choose not to provide the information and
                    you have made this application in person, Federal
                    regulations require us to note your ethnicity, race and sex
                    on the basis of visual observation or surname.
                  </div>
                </Typography>
              </Collapse>
            </Box>
            <DemographicSteps formData={formData} setFormData={setFormData} />
            {error && (
              <Typography
                color="error"
                align="center"
                sx={{
                  fontSize: { xs: "16px", md: "18px" },
                  mt: 1,
                  fontFamily: "NunitoSans",
                  animation: animation ? "shake 0.3s ease-in-out" : "none",
                }}
              >
                Please complete all demographic steps to continue
              </Typography>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column-reverse",
                sm: "row",
              },
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <CHBButton onClick={onPrev} secondaryArrow={true}>
              Go Back
            </CHBButton>
            {validateSexAndCitizenship(formData) && (
              <CHBButton onClick={handleNext} className="animated-arrow-button">
                Next
              </CHBButton>
            )}
          </Box>
        </Box>
      </div>
    </Box>
  );
};
