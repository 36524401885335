import API from "utils/API";
import Loan from "typedef/Loan";
import { useCallback, useEffect, useState } from "react";
import useUser from "context/UserCustomer/useUserCustomerContext";
import { useLocation, useParams } from "react-router-dom";

const useLoan = () => {
  const [loan, setLoan] = useState<Loan>();
  const { user, selectedLoanId } = useUser();
  const params = useParams();
  const currentAdminLoanId = params?.loanId;
  const location = useLocation();
  const isAdmin = location.pathname.includes("impersonate");

  const getAndSetLoan = useCallback(async () => {
    if (user && selectedLoanId) {
      const response =
        isAdmin && currentAdminLoanId
          ? await API.get<Loan>(
              `/admin-impersonate/get/loan/${currentAdminLoanId}`,
            )
          : await API.get<Loan>(`/get/my-loan?id=${selectedLoanId}`);
      if ("error" in response) {
        console.error(response.error);
      } else {
        setLoan(response.data);
      }
    }
  }, [user, selectedLoanId, isAdmin, currentAdminLoanId]);

  useEffect(() => {
    getAndSetLoan();
  }, [getAndSetLoan]);

  return loan;
};

export default useLoan;
