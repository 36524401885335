import { Box, Typography, Collapse, IconButton } from "@mui/material";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";
import React, { useState, useCallback } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { FormData } from "screens/GetStarted/useGetStarted";
import { useHomebridgeSizes } from "utils/useHomebridgeSizes";
import CheckboxInput from "components/CheckboxInput";

type HBethnicityOptionsProps = {
  formData: FormData;
  setFormData: (data: FormData) => void;
};

export const validateEthnicity = (formData: FormData) => {
  const ethnicity = formData.demographicsData?.ethnicity;
  return (
    ethnicity?.hispanicOrLatino ||
    ethnicity?.notHispanicOrLatino ||
    ethnicity?.prefNoSay
  );
};

export const HBEthnicityOptions = ({
  formData,
  setFormData,
}: HBethnicityOptionsProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { primaryText, secondaryText, tertiaryText } = useHomeBridgeColors();
  const { sizes } = useHomebridgeSizes();
  const [currentEthnicityForm, setcurrentEthnicity] = useState({
    hispanicOrLatino: formData.demographicsData.ethnicity.hispanicOrLatino,
    mexican: formData.demographicsData.ethnicity.mexican,
    puertoRican: formData.demographicsData.ethnicity.puertoRican,
    cuban: formData.demographicsData.ethnicity.cuban,
    otherHispanicOrLatino:
      formData.demographicsData.ethnicity.otherHispanicOrLatino,
    notHispanicOrLatino:
      formData.demographicsData.ethnicity.notHispanicOrLatino,
    prefNoSay: formData.demographicsData.ethnicity.prefNoSay,
    customEthnicity: formData.demographicsData.ethnicity.customEthnicity,
  });

  const handleExpandClick = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  const handleCheckboxChange = (name: string, checked: boolean) => {
    let currentEthnicity = { ...currentEthnicityForm, [name]: checked };

    if (name === "hispanicOrLatino") {
      currentEthnicity = {
        hispanicOrLatino: checked,
        mexican: checked ? currentEthnicity.mexican : false,
        puertoRican: checked ? currentEthnicity.puertoRican : false,
        cuban: checked ? currentEthnicity.cuban : false,
        otherHispanicOrLatino: checked
          ? currentEthnicity.otherHispanicOrLatino
          : false,
        notHispanicOrLatino: false,
        prefNoSay: false,
        customEthnicity: checked ? currentEthnicity.customEthnicity : "",
      };
    } else if (
      ["mexican", "puertoRican", "cuban", "otherHispanicOrLatino"].includes(
        name,
      )
    ) {
      const hasOtherChildrenChecked = [
        "mexican",
        "puertoRican",
        "cuban",
        "otherHispanicOrLatino",
      ]
        .filter((child) => child !== name)
        .some(
          (child) => currentEthnicity[child as keyof typeof currentEthnicity],
        );

      currentEthnicity = {
        ...currentEthnicity,
        [name]: checked,
        hispanicOrLatino: checked || hasOtherChildrenChecked,
        notHispanicOrLatino: false,
        prefNoSay: false,
      };

      if (name === "otherHispanicOrLatino" && !checked) {
        currentEthnicity.customEthnicity = "";
      }
    } else if (["notHispanicOrLatino", "prefNoSay"].includes(name)) {
      currentEthnicity = {
        hispanicOrLatino: false,
        mexican: false,
        puertoRican: false,
        cuban: false,
        otherHispanicOrLatino: false,
        customEthnicity: "",
        notHispanicOrLatino: name === "notHispanicOrLatino" && checked,
        prefNoSay: name === "prefNoSay" && checked,
      };
    }

    setcurrentEthnicity(currentEthnicity);
    setFormData({
      ...formData,
      demographicsData: {
        ...formData.demographicsData,
        ethnicity: currentEthnicity,
      },
    });
  };

  const handleCustomEthnicityChange = (value: string) => {
    const updatedEthnicity = {
      ...currentEthnicityForm,
      customEthnicity: value,
    };

    setcurrentEthnicity(updatedEthnicity);
    setFormData({
      ...formData,
      demographicsData: {
        ...formData.demographicsData,
        ethnicity: updatedEthnicity,
      },
    });
  };

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"start"}
        justifyContent={"flex-start"}
        width={"100%"}
        textAlign={"left"}
        sx={{ gap: "10px" }}
      >
        <Typography
          variant="h6"
          color={primaryText}
          sx={{
            fontSize: sizes.typography,
          }}
        >
          What is your ethnicity?
        </Typography>
        <Box
          display="flex"
          alignItems={{ xs: "start", md: "center" }}
          sx={{ gap: "10px" }}
        >
          <div onClick={handleExpandClick}>
            <CheckboxInput
              name="hispanicOrLatino"
              label="Hispanic or Latino"
              state={currentEthnicityForm.hispanicOrLatino}
              sizesLabel={sizes.typography.toString()}
              onChange={() =>
                handleCheckboxChange(
                  "hispanicOrLatino",
                  !currentEthnicityForm.hispanicOrLatino,
                )
              }
            />
          </div>
          <IconButton
            onClick={handleExpandClick}
            sx={{
              padding: 0,
              borderRadius: "50%",
              backgroundColor: tertiaryText,
              "&:hover": {
                backgroundColor: primaryText,
                color: secondaryText,
              },
              color: secondaryText,
              marginLeft: 3,
              alignSelf: "center",
            }}
          >
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          <Box
            pl={3}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"start"}
            justifyContent={"flex-start"}
            textAlign={"left"}
            sx={{ gap: "10px" }}
          >
            <Box
              display="flex"
              alignItems={{ xs: "start", md: "center" }}
              sx={{ gap: "10px" }}
            >
              <CheckboxInput
                label={"Mexican"}
                state={currentEthnicityForm.mexican}
                name={"mexican"}
                onChange={() =>
                  handleCheckboxChange("mexican", !currentEthnicityForm.mexican)
                }
              />
            </Box>
            <Box
              display="flex"
              alignItems={{ xs: "start", md: "center" }}
              sx={{ gap: "10px" }}
            >
              <CheckboxInput
                label={"Puerto Rican"}
                state={currentEthnicityForm.puertoRican}
                name={"puertoRican"}
                onChange={() =>
                  handleCheckboxChange(
                    "puertoRican",
                    !currentEthnicityForm.puertoRican,
                  )
                }
              />
            </Box>
            <Box
              display="flex"
              alignItems={{ xs: "start", md: "center" }}
              sx={{ gap: "10px" }}
            >
              <CheckboxInput
                label={"Cuban"}
                state={currentEthnicityForm.cuban}
                name={"cuban"}
                onChange={() =>
                  handleCheckboxChange("cuban", !currentEthnicityForm.cuban)
                }
              />
            </Box>
            <Box
              display="flex"
              alignItems={{ xs: "start", md: "center" }}
              sx={{ gap: "10px" }}
            >
              <CheckboxInput
                label={"Other Hispanic or Latino"}
                state={currentEthnicityForm.otherHispanicOrLatino}
                name={"otherHispanicOrLatino"}
                onChange={() =>
                  handleCheckboxChange(
                    "otherHispanicOrLatino",
                    !currentEthnicityForm.otherHispanicOrLatino,
                  )
                }
              />
            </Box>
            <Collapse
              in={formData.demographicsData?.ethnicity.otherHispanicOrLatino}
            >
              <Box pl={4} fontSize={sizes.typography}>
                <input
                  type="text"
                  placeholder="Please specify"
                  value={currentEthnicityForm.customEthnicity}
                  onChange={(e) => handleCustomEthnicityChange(e.target.value)}
                  style={{ marginLeft: "8px" }}
                  required
                />
              </Box>
            </Collapse>
          </Box>
        </Collapse>
        <Box
          display="flex"
          alignItems={{ xs: "start", md: "center" }}
          sx={{ gap: "10px" }}
          onClick={() => (isExpanded ? setIsExpanded(false) : null)}
        >
          <CheckboxInput
            label={"Not Hispanic or Latino"}
            state={currentEthnicityForm.notHispanicOrLatino}
            name={"notHispanicOrLatino"}
            onChange={() =>
              handleCheckboxChange(
                "notHispanicOrLatino",
                !currentEthnicityForm.notHispanicOrLatino,
              )
            }
          />
        </Box>
        <Box
          display="flex"
          alignItems={{ xs: "start", md: "center" }}
          sx={{ gap: "10px" }}
          onClick={() => (isExpanded ? setIsExpanded(false) : null)}
        >
          <CheckboxInput
            label={"I do not wish to provide this information"}
            state={currentEthnicityForm.prefNoSay}
            name={"prefNoSay"}
            onChange={() =>
              handleCheckboxChange("prefNoSay", !currentEthnicityForm.prefNoSay)
            }
          />
        </Box>
      </Box>
    </>
  );
};
